import * as siteConfig from '../siteconfig';
import { oktaAuth } from '../services/okta-service';
import { appInsights } from '../appInsights.js';

export const fetchPreviousQuotes = async () => {
  const accessToken = (await oktaAuth.tokenManager.get('accessToken'))
    ?.accessToken;
  const idToken = (await oktaAuth.tokenManager.get('idToken'))?.idToken;

  try {
    const response = await fetch(
      `${siteConfig.searchApiUrl}/quotes/allprevious`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-OktaIdToken': idToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Failed to fetch previous quotes /${response.status}/`);
    }
    return response.json();
  } catch (error) {
    appInsights.trackException({ error });
    throw error;
  }
};
