import {BufferResource} from "./bufferresource.js";

/**
 * This class is used to contain indices into a vertex buffer to be used for rendering a draw call.
 */
class IndexBuffer extends BufferResource {
    constructor() {
        super();
        
        /**
         * @type {IndexBuffer.Type}
         */
        this.indexType = IndexBuffer.Type.UNKNOWN;

        /**
         * @type {ArrayBuffer}
         */
        this.data = null;

        /**
         * Offset in bytes to the ___.
         * @type {Number}
         */
        this.binaryOffset = 0;
    }

    get indexSize() {
        let bytes = -1;

        switch (this.indexType) {
            case IndexBuffer.Type.UINT: {
                bytes = 4;  // sizeof(uint)
                break;
            }

            case IndexBuffer.Type.USHORT: {
                bytes = 2;  // sizeof(ushort)
                break;
            }

            default: {
                throw new Error(`Getting the indexSize of: ${this.indexType}, is not currently supported.`);
            }
        }

        return bytes;
    }
    
    _writeXmlAttributes(xmlDoc) {
        const attributes = super._writeXmlAttributes(xmlDoc);
        
        let type = "";
        
        switch (this.indexType) {
            case IndexBuffer.Type.UNKNOWN: {
                type = "Unknown";
                break;
            }
            
            case IndexBuffer.Type.USHORT: {
                type = "UShort";
                break;
            }
            
            case IndexBuffer.Type.UINT: {
                type = "UInt";
                break;
            }
            
            default: {
                console.warn(`trying to convert an invalid index type: '${this.indexType}' to a string.`);
                break;
            }
        }
        
        attributes.set("Type", type);
        
        return attributes;
    }
}

/**
 * The data type to use for the indices.
 * @enum {Number}
 */
IndexBuffer.Type = Object.freeze({
    /**
     * An unknown type. This usually indicates some sort of error or uninitialized data.
     */
    UNKNOWN: -1,

    /**
     * Used to indicate an index type of unsigned short.
     */
    USHORT: 1,

    /**
     * Used to indicate an index type of unsigned int.
     */
    UINT: 2
});

export { IndexBuffer };
