export const vert = /* glsl */ `

varying vec2 vUv;

void main() {
    vUv = uv;
    gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.0);
}
`;

export const frag = /* glsl */ `

#include <packing>

varying vec2 vUv;
uniform int renderDepth;
uniform float cameraNear;
uniform float cameraFar;
uniform sampler2D tSrcSceneTex;
uniform sampler2D tSrcDepthTex;

float transformDepth(float fragCoordZ) {
    float viewZ = perspectiveDepthToViewZ( fragCoordZ, cameraNear, cameraFar );
    return viewZToOrthographicDepth( viewZ, cameraNear, cameraFar );
}

void main() {
    if(renderDepth == 0) {
        vec4 src = texture2D(tSrcSceneTex, vUv);
        gl_FragColor = vec4(src.rgb, src.a);
    }
    else {
        float srcZ = texture2D(tSrcDepthTex, vUv).x;
        float srcDepth = transformDepth(srcZ);

        gl_FragColor = vec4(vec3(1.0 - srcDepth), 1.0);
    }

	#include <colorspace_fragment>
}
`;
