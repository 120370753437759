import { Matrix4 } from "./affinetransform";
import Vector4 from "./vector4";

class Vector3 {
    /**
     * Creates a new Vector3
     * @param {Number} x X-Axis value
     * @param {Number} y y-Axis value
     * @param {Number} z Z-Axis value
     */
    constructor(x = 0, y = 0, z = 0) {
        /**
         * @type {Number}
         */
        this.x = x;

        /**
         * @type {Number}
         */
        this.y = y;

        /**
         * @type {Number}
         */
        this.z = z;
    }

    /**
     * Returns the magnitude of the vector.
     * @returns {Number} Magnitude.
     */
    get magnitude() {
        const result = Math.sqrt((this.x * this.x) + (this.y * this.y) + (this.z * this.z));
        return result;
    }

    /**
     * Returns the vector as unit vector (normalized).
     * @returns {Vector3} Unit vector.
     */
    get unitVector() {
        const m = this.magnitude;
        const result =  new Vector3(this.x / m, this.y / m, this.z / m);
        return result;
    }

    /**
     * Sets this vector's values based on another vectors.
     * @param {Vector3} other Vector3 whose values will be copied to this vector.
     */
    setFrom(other) {
        this.x = other.x;
        this.y = other.y;
        this.z = other.z;
    }

    /**
     * Returns whether this vector is equal to another.
     * @param {Vector3} other Another Vector3 to compare against.
     * @returns {Boolean} True if equal, false otherwise.
     */
    isEqualTo(other) {
        const result = this.x == other.x && this.y == other.y && this.z == other.z;
        return result;
    }
    
    /**
     * Adds another Vector to this Vector.
     * @param {Vector3} other Vector to add to this one.
     * @returns {Vector3} Result Vector from adding the other vector to this one.
     */
    add(other) {
        const result = new Vector3(this.x + other.x, this.y + other.y, this.z + other.z);
        return result;
    }
    
    /**
     * Subtracts another Vector from this Vector.
     * @param {Vector3} other Vector to subtract from this one.
     * @returns {Vector3} Result Vector from subtracting the other vector from this one.
     */
    subtract(other) {
        const result = new Vector3(this.x - other.x, this.y - other.y, this.z - other.z);
        return result;
    }
    
    /**
     * Multiplies the vector by a scalar value.
     * @param {Number} scalar Scalar value.
     */
    multiply(scalar) {
        const result = new Vector3(this.x * scalar, this.y * scalar, this.z * scalar);
        return result;
    }
    
    /**
     * Returns the dot product of this vector and another.
     * @param {Vector3} other Another Vector.
     * @returns {Number} Dot product of both vectors.
     */
    dot(other) {
        const result = (this.x * other.x) + (this.y * other.y) + (this.z * other.z);
        return result;
    }
    
    /**
     * Returns the cross product of this vector and another.
     * @param {Vector3} other Another Vector.
     */
    cross(other) {
        // REF: https://en.wikipedia.org/wiki/Cross_product
        const s1 = this.y * other.z - this.z * other.y;
        const s2 = this.z * other.x - this.x * other.z;
        const s3 = this.x * other.y - this.y * other.x;
        
        const result = new Vector3(s1, s2, s3);
        return result;
    }
    
    /**
     * Returns the angle between this vector and another.
     * @param {Vector3} other Another vector.
     * @returns {Number} Angle between both vectors.
     */
    angleBetween(other) {
        const dotProduct = this.dot(other);
        const magnitudes = this.magnitude * other.magnitude;
        
        const result = Math.acos(dotProduct / magnitudes);
        return result;
    }
    
    toString() {
        const result = `${this.x}, ${this.y}, ${this.y}`;
        return result;
    }
}

/**
 * Creates a new vector with values from another vector.
 * @param {Vector3} other Vector to copy values from.
 * @returns {Vector3} New vector.
 */
Vector3.createFrom = function(other) {
    return new Vector3(other.x, other.y, other.z);
};

/**
 * @param {Vector3} vecA 
 * @param {Vector3} vecB 
 * @returns {number} 
 */
Vector3.dot = function(vecA, vecB) {
    return vecA.dot(vecB);
}

/**
 * @param {Vector3} vec 
 * @param {Matrix4} mat
 * @returns {Vector3} 
 */
Vector3.transform = function(vec, mat) {
    const vector = new Vector4(vec.x, vec.y, vec.z, 1);
    const transformed = Vector4.transform(vector, mat);
    return new Vector3(transformed.x, transformed.y, transformed.x).multiply(1.0 / transformed.w);
}

Vector3.zero = Object.freeze(new Vector3());

export { Vector3 };
