import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router';
import { oktaAuth } from '../services/okta-service';

export default () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogout = async () => {
      const params = new URLSearchParams(window.location.search);
      const stateParam = params.get('state');
      localStorage.removeItem('signingOut');
      localStorage.removeItem('fetchedClasses');
      if (stateParam) {
        navigate(stateParam);
      } else {
        navigate('/');
      }

      try {
      } catch (error) {
        console.error(error);
        navigate('/');
      }
    };
    handleLogout();
  });
};
