import * as THREE from 'three';

class CutGeometry extends THREE.BufferGeometry {
  constructor(toolWidth, toolHeight) {
    super();

    this.type = 'CutGeometry';

    this.parameters = {
      toolWidth,
      toolHeight,
    };

    // buffers

    this.indices = [];
    this.vertices = [];
    this.normals = [];
    this.uvs = [];

    // helper variables

    this.numberOfVertices = 0;
    this.groupStart = 0;
  }

  buildGeometry() {
    this.setIndex(this.indices);
    // prettier-ignore
    this.setAttribute('position', new THREE.Float32BufferAttribute(this.vertices, 3));
    // prettier-ignore
    this.setAttribute('normal', new THREE.Float32BufferAttribute(this.normals, 3));
    // prettier-ignore
    this.setAttribute('uv', new THREE.Float32BufferAttribute(this.uvs, 2));
  }
}

export class QuarterCut extends CutGeometry {
  constructor(toolWidth, toolHeight) {
    super(toolWidth, toolHeight);

    this.type = 'QuarterCut';

    const w = toolWidth / 2;
    const d = toolWidth / 2;
    const h = toolHeight;

    // prettier-ignore
    buildPlane(this, 'z', 'y', 'x',  1, -1, d, h, -w,  d/2, 0, w/2, 1, 1, 0); // nx
    // prettier-ignore
    buildPlane(this, 'x', 'y', 'z', -1, -1, w, h, -d, -w/2, 0, d/2, 1, 1, 1); // nz

    this.buildGeometry();
  }

  copy(source) {
    super.copy(source);

    this.parameters = Object.assign({}, source.parameters);

    return this;
  }

  static fromJSON(data) {
    return new QuarterCut(data.toolWidth, data.toolHeight);
  }
}

export class HalfCut extends CutGeometry {
  constructor(toolWidth, toolHeight) {
    super(toolWidth, toolHeight);

    this.type = 'HalfCut';

    const w = toolWidth;
    const d = toolWidth / 2;
    const h = toolHeight;

    // prettier-ignore
    buildPlane(this, 'x', 'y', 'z', -1, -1, w, h, -d, 0, 0, d/2, 1, 1, 0); // nz

    this.buildGeometry();
  }

  copy(source) {
    super.copy(source);

    this.parameters = Object.assign({}, source.parameters);

    return this;
  }

  static fromJSON(data) {
    return new HalfCut(data.toolWidth, data.toolHeight);
  }
}

export class ThreeQuarterCut extends CutGeometry {
  constructor(toolWidth, toolHeight) {
    super(toolWidth, toolHeight);

    this.type = 'ThreeQuarterCut';

    const w = toolWidth / 2;
    const d = toolWidth / 2;
    const h = toolHeight;

    // prettier-ignore
    buildPlane(this, 'z', 'y', 'x', -1, -1, d, h,  w,  d/2, 0, -w/2, 1, 1, 0); // px
    // prettier-ignore
    buildPlane(this, 'x', 'y', 'z', -1, -1, w, h, -d, -w/2, 0,  d/2, 1, 1, 1); // nz

    this.buildGeometry();
  }

  copy(source) {
    super.copy(source);

    this.parameters = Object.assign({}, source.parameters);

    return this;
  }

  static fromJSON(data) {
    return new ThreeQuarterCut(data.toolWidth, data.toolHeight);
  }
}

export class QuarterSymmetricCut extends CutGeometry {
  constructor(toolWidth, toolHeight) {
    super(toolWidth, toolHeight);

    this.type = 'QuarterSymmetricCut';

    const w = toolWidth / 2;
    const d = toolWidth / 2;
    const h = toolHeight;

    // prettier-ignore
    buildPlane(this, 'z', 'y', 'x', -1, -1, d, h,  w,  d/2, 0, -w/2, 1, 1, 0); // px
    // prettier-ignore
    buildPlane(this, 'z', 'y', 'x',  1, -1, d, h, -w,  d/2, 0,  w/2, 1, 1, 1); // nx
    // prettier-ignore
    buildPlane(this, 'x', 'y', 'z',  1, -1, w, h,  d, -w/2, 0, -d/2, 1, 1, 2); // pz
    // prettier-ignore
    buildPlane(this, 'x', 'y', 'z', -1, -1, w, h, -d, -w/2, 0,  d/2, 1, 1, 3); // nz

    this.buildGeometry();
  }

  copy(source) {
    super.copy(source);

    this.parameters = Object.assign({}, source.parameters);

    return this;
  }

  static fromJSON(data) {
    return new QuarterCut(data.toolWidth, data.toolHeight);
  }
}

/**
 * https://github.com/mrdoob/three.js/blob/dev/src/geometries/BoxGeometry.js
 *
 * @param {CutGeometry} ref
 * @param {String} u
 * @param {String} v
 * @param {String} w
 * @param {Number} udir
 * @param {Number} vdir
 * @param {Number} width
 * @param {Number} height
 * @param {Number} depth
 * @param {Number} uoff
 * @param {Number} voff
 * @param {Number} woff
 * @param {Number} gridX
 * @param {Number} gridY
 * @param {Number} materialIndex
 */
// prettier-ignore
function buildPlane(
  ref,
  u, v, w,
  udir, vdir,
  width, height, depth,
  uoff = 0, voff = 0, woff = 0,
  gridX = 1, gridY = 1,
  materialIndex = 0,
) {
  // building variables

  const segmentWidth = width / gridX;
  const segmentHeight = height / gridY;

  const widthHalf = width / 2;
  const heightHalf = height / 2;
  const depthHalf = depth / 2;

  const gridX1 = gridX + 1;
  const gridY1 = gridY + 1;

  let vertexCounter = 0;
  let groupCount = 0;

  const vector = new THREE.Vector3();

  // generate vertices, normals and uvs

  for (let iy = 0; iy < gridY1; iy++) {
    const y = iy * segmentHeight - heightHalf;

    for (let ix = 0; ix < gridX1; ix++) {
      const x = ix * segmentWidth - widthHalf;

      // set values to correct vector component

      vector[u] = (x + uoff) * udir;
      vector[v] = (y + voff) * vdir;
      vector[w] = woff + depthHalf;

      // now apply vector to vertex buffer

      ref.vertices.push(vector.x, vector.y, vector.z);

      // set values to correct vector component

      vector[u] = 0;
      vector[v] = 0;
      vector[w] = depth > 0 ? 1 : -1;

      // now apply vector to normal buffer

      ref.normals.push(vector.x, vector.y, vector.z);

      // uvs

      ref.uvs.push(ix / gridX);
      ref.uvs.push(1 - iy / gridY);

      // counters

      vertexCounter += 1;
    }
  }

  // indices

  // 1. you need three indices to draw a single face
  // 2. a single segment consists of two faces
  // 3. so we need to generate six (2*3) indices per segment

  for (let iy = 0; iy < gridY; iy++) {
    for (let ix = 0; ix < gridX; ix++) {
      const a = ref.numberOfVertices + ix + gridX1 * iy;
      const b = ref.numberOfVertices + ix + gridX1 * (iy + 1);
      const c = ref.numberOfVertices + (ix + 1) + gridX1 * (iy + 1);
      const d = ref.numberOfVertices + (ix + 1) + gridX1 * iy;

      // faces

      ref.indices.push(a, b, d);
      ref.indices.push(b, c, d);

      // increase counter

      groupCount += 6;
    }
  }

  // add a group to the geometry. this will ensure multi material support

  ref.addGroup(ref.groupStart, groupCount, materialIndex);

  // calculate new start value for groups

  ref.groupStart += groupCount;

  // update total number of vertices

  ref.numberOfVertices += vertexCounter;
}
