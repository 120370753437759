import {TreeNode} from "./../../base/treenode.js";

class Namespace {
    /**
     * Creates a new namespace reflection
     * @param {Object} ns Object defining a namespace.
     * @param {Array<string>} Keys to ignore in the namespace at the root level.
     */
    constructor(ns, ignore = []) {
        /**
         * @type {Map<string, Array<string>>}
         */
        this.definitions = new Map();
        
        this.dependenciesRoot = new TreeNode();
        
        this._parseNamespace(ns, ignore, this.definitions, this.dependenciesRoot);
    }
    
    /**
     * Returns a collection of serial value attributes for the specified type.
     * @param {ObjectConstructor} type Type to get serial value attributes for.
     * @returns {Array<SerialValueAttribute>} Serial value attributes for the type.
     */
    getSerialValueAttributesForType(type) {
        const typeName = Object.getPrototypeOf(type).constructor.name;
        const pattern = new RegExp(`(\\w[^\\s]+\\.)*(${typeName})$`);
        
        let attributes = [];
        
        const typeKey = Array.from(this.definitions.keys()).find(k => pattern.test(k));
        if (typeKey) {
            const typeNode = this.dependenciesRoot.find(value => value == typeKey);
            if (typeNode) {
                const ancestors = Array.from(typeNode.ancestors()).filter(node => node != this.dependenciesRoot);
                const dependencyStackKeys = [typeNode, ...ancestors];
                
                attributes = dependencyStackKeys.reduce((collection, node) => {
                    const keyAttributes = this.definitions.get(node.value);
                    if (keyAttributes) {
                        collection.push(...keyAttributes);
                    }
                    
                    return collection;
                }, []);
            }
        }
        
        return attributes;
    }
    
    /**
     * Returns the full type name for the type.
     * @param {ObjectConstructor} type Type to find type name for.
     * @returns {string} Full type name if found or type name from constructor.
     */
    fullNameForType(type) {
        const typeName = Object.getPrototypeOf(type).constructor.name;
        const pattern = new RegExp(`(\\w[^\\s]+\\.)*(${typeName})$`);
        
        const typeKey = Array.from(this.definitions.keys()).find(k => pattern.test(k));
        
        const fullName = typeKey ? typeKey : typeName;
        return fullName;
    }
    
    _parseNamespace(namespace, ignore = [], definitions, dependenciesRoot) {
        const ignoreSet = new Set(ignore);
        const namespaceKeys = Object.keys(namespace).filter(n => !ignoreSet.has(n));
        
        const iterationStack = [{target: namespace, keys: namespaceKeys, index: 0}];
        
        while (iterationStack.length > 0) {
            const step = iterationStack[iterationStack.length - 1];
            
            if (step.index < step.keys.length) {
                const curKey = step.keys[step.index];
                step.index += 1;
                
                const curTarget = step.target[curKey];
                
                switch (typeof curTarget) {
                    case "object": {
                        if (!Object.isFrozen(curTarget)) {
                            // namespace
                            iterationStack.push({target: curTarget, keys: Object.keys(curTarget), index: 0});
                        }
                        break;    
                    }
                    
                    case "function": {
                        if (curTarget.prototype && !definitions.has(curTarget.prototype.constructor.name)) {
                            const nameStack = [];
                            this._parseClass(curTarget, nameStack, definitions, dependenciesRoot);
                        }
                        break;
                    }
                    
                    default: {
                        console.warn(`unsupported target type of: '${typeof curTarget}', for key: '${curKey}'.`);
                        break;
                    }
                }
            }
            else {
                iterationStack.pop();
                
                while (iterationStack.length > 0) {
                    const lastStep = iterationStack[iterationStack.length - 1];
                    if (lastStep.index < lastStep.keys.length) {
                        break;   
                    }
                    else {
                        iterationStack.pop();
                    }
                }
            }
        }
    }
    
    _parseClass(target, nameStack, definitions, dependenciesRoot) {
        // first check for any static declarations, we only want to deal with named
        // objects, if they're anonymous we can't catalog them.
        const staticKeys = Object.keys(target);
        staticKeys.filter(key => target[key].prototype && target[key].prototype.constructor.name).forEach(key => {
            const keyTarget = target[key];
            this._parseClass(keyTarget, [...nameStack, target.prototype.constructor.name], definitions, dependenciesRoot);
        });
        
        const namePath = nameStack.join(".");
        const className = namePath != "" ? `${namePath}.${target.prototype.constructor.name}` : target.prototype.constructor.name;
        
        definitions.set(className, null);
        
        let targetNode = dependenciesRoot.find(name => name == className);
        if (!targetNode) {
            targetNode = new TreeNode(className);
            // dependenciesRoot.addChild(targetNode);
        }
        
        if (target.prototype.hasOwnProperty("getSerialValuesAttributes")) {
            const serialValueAttributes = target.prototype.getSerialValuesAttributes();
            definitions.set(className, serialValueAttributes);
        }
        
        let base = Object.getPrototypeOf(target);
        if (base && base.prototype) {
            const baseName = /*namePath != "" ? `${namePath}.${base.prototype.constructor.name}` :*/ base.prototype.constructor.name;
            // BUG: might be subclassed from a different namespace so we don't want to use this namepath. We might need to store the name as a placeholder until we get a more final namespace later.
            let baseNode = dependenciesRoot.find(name => name == baseName);
            if (!baseNode) {
                baseNode = new TreeNode(baseName);
                dependenciesRoot.addChild(baseNode);
                
                baseNode.addChild(targetNode);
                
                this._parseClass(base, [], definitions, dependenciesRoot);
            }
            else {
                baseNode.addChild(targetNode);
            }
        }
        else if (!targetNode.parent) {
            dependenciesRoot.addChild(targetNode);
        }
    }
}

export {Namespace};
