import React, { useEffect } from 'react';
import { appInsights } from '../../appInsights.js';

import styled from 'styled-components';

import BrowsePanel from './BrowsePanel/index.jsx';

const MainContainer = styled.div`
  font-family: Univers;
  min-height: 100vh;
  display: flex;
`;

const BrowsePage = () => {
  useEffect(() => {
    const trackViewChange = () => {
      appInsights.trackPageView({ name: location.pathname });
    };
    trackViewChange();
  }, [location.pathname]);

  useEffect(() => {
    document.title = 'Browse';
    window.scrollTo(0, 0);

    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'Halliburton hierarchical browsing page';

    document.head.appendChild(metaDescription);

    return () => {
      document.title = '';
      document.head.removeChild(metaDescription);
    };
  }, []);

  return (
    <MainContainer>
      <BrowsePanel />
    </MainContainer>
  );
};

export default BrowsePage;
