class BinaryStream {
    constructor() {
        /**
         * Backing storage for the stream.
         * @type {ArrayBuffer}
         */
        this._buffer = new ArrayBuffer();
        
        /**
         * Offset from the start of the buffer to write to.
         * @type {Number}
         */
        this._offset = 0;
        
        /**
         * Number of bytes that have been written.
         * @type {Number}
         */
        this._writtenLength = 0;
    }
    
    /**
     * Returns the current offset.
     * @returns {Number} Number of bytes from the start of the stream the next write will take place at.
     */
    get offset() {
        return this._offset;
    }
    
    /**
     * Returns the written portion of the stream.
     * (This is only valid until the next write.)
     * @returns {Uint8Buffer} Bytes written to stream.
     */
    get bytes() {
        this._validateOffset();
        const contents = new Uint8Array(this._buffer, 0, this._writtenLength);
        return contents;
    }
    
    /**
     * Writes the bytes to the stream at the current offset.
     * @type {ArrayBuffer|Uint8Buffer|DataView} bytes Bytes to copied.
     */
    write(bytes) {
        this._validateOffset(bytes.byteLength);
        
        const dst = new Uint8Array(this._buffer);
        const src = new Uint8Array(bytes instanceof ArrayBuffer ? bytes : bytes.buffer, bytes.byteOffset, bytes.byteLength);
        
        dst.set(src, this._offset);
        
        const endOffset = this._offset + bytes.byteLength;
        if (endOffset > this._writtenLength) {
            this._writtenLength += endOffset - this._writtenLength;
        }
        
        this._offset = endOffset;
    }
    
    /**
     * Moves the offset.
     * @param {BinaryStream.SeekOrigin} origin Origin of the seek.
     * @param {Number} length Number of bytes to move the offset.
     */
    seek(origin, length) {
        let seekOffset;
        
        switch (origin) {
            case BinaryStream.SeekOrigin.BEGIN: {
                seekOffset = 0;
                break;
            }
            
            case BinaryStream.SeekOrigin.CURRENT: {
                seekOffset = this._offset;
                break;
            }
            
            default: {
                console.error(`invalid seek origin specified: '${origin}'.`);
                break;
            }
        }
        
        this._offset = seekOffset + length;
    }
    
    /**
     * Validate that the offset is valid by insuring that there is enough buffer
     * to write to the current offset + the specified length.
     * @param {Number} length Additional bytes that are required.
     */
    _validateOffset(length = 0) {
        const requiredLength = this._offset + length;
        
        // just a quick failsafe so that we're not allocating crazy memory, free free
        // to disable or increase in the future (Billy 11-16-2020).
        
        if (requiredLength > 1024 * 1024 * 100) {
            throw new Error(`attempted to allocate more (${requiredLength - this._buffer.byteLength}) that the failsafe: ${1024 * 1024 * 100}.`);
        }
        
        if (requiredLength > this._buffer.byteLength) {
            // we don't have enough room, so create a new buffer and copy the previous
            // contents.
            
            const newBufferLength = this._buffer.byteLength + (requiredLength - this._buffer.byteLength);
            const newBuffer = new ArrayBuffer(newBufferLength);
            
            const dst = new Uint8Array(newBuffer);
            const src = new Uint8Array(this._buffer, this._buffer.byteOffset, this._writtenLength);
            
            dst.set(src);
            
            this._buffer = newBuffer;
        }
    }
}

/**
 * @enum {Number}
 */
BinaryStream.SeekOrigin = Object.freeze({
    BEGIN: 0,
    CURRENT: 1
});

export {BinaryStream};
