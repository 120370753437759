const ignoreKeys = new Set(["constructor"]);

/**
 * Checks if the object's methods conform to the specified type.
 * @param {Object} obj Object to check conformance of.
 * @param {ObjectConstructor} type Type to check conformance to.
 @ @param {Boolean} recurse True if conformance should be checked recursively through the prototype chain, false otherwise.
 * @returns {Boolean} True if the object conforms to the type or false otherwise.
 */
function conformsTo(obj, type, recurse = false) {
    const names = propertyNames(type.prototype, recurse);
    
    const objNames = new Set([...Object.getOwnPropertyNames(obj), ...Object.getOwnPropertyNames(Object.getPrototypeOf(obj))]);
    
    let result = true;
    
    for (const name of names) {
        if (!objNames.has(name)) {
            result = false;
            break;
        }
    }
    
    return result;
}

/**
 * Returns the property names contained in the specified object.
 * @param {Object|ObjectConstructor} obj Object or Type to get names for.
 * @param {Boolean} recurse True if the prototype chain should be recursed, false otherwise.
 */
function propertyNames(obj, recurse = true) {
    /**
     * @type {Array<string>}
     */
    const keys = Object.keys(obj);
    
    // let curPrototype = Object.getPrototypeOf(obj);
    // while (curPrototype) {
    //     const names = Object.getOwnPropertyNames(curPrototype).filter(name => !ignoreKeys.has(name));
    //     keys.push(...names);
    //     
    //     curPrototype = recurse ? Object.getPrototypeOf(curPrototype) : null;
    // }
    
    let current = obj;
    
    do {
        const names = Object.getOwnPropertyNames(current).filter(name => !ignoreKeys.has(name));
        keys.push(...names);
        
        current = recurse ? Object.getPrototypeOf(current) : null;
    } while (current && current.prototype);
    
    return keys;
}

export {conformsTo};
