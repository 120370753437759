import React from 'react';
import styled from 'styled-components';

const PlaceholderContainer = styled.div`
  width: 330px;
  @media (max-width: 502px) {
    max-width: 330px;
    width: 90%;
  }
`;

const PlaceholderTitle = styled.div`
  font-family: Univers;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color-primary);
`;

const PlaceholderMessage = styled.div`
  font-family: Univers;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #293a4f;
`;

const DataFetchErrorPlaceholder = () => {
  return (
    <PlaceholderContainer>
      <PlaceholderTitle>Looks like something went wrong</PlaceholderTitle>
      <br />
      <PlaceholderMessage>
        Please check your connection and try again.
        <br />
        If the error persists please contact support.
      </PlaceholderMessage>
    </PlaceholderContainer>
  );
};

export default DataFetchErrorPlaceholder;
