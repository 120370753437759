import {AABB} from "./../aabb.js";
import {DrawCall} from "./drawcall.js";
import {IMesh} from "./../renderer/mesh.js";
import {SerialObject} from "./../serialization/serialobject.js";

/**
 * A static mesh is comprised of multiple draw calls used to render a single body.
 */
class StaticMesh extends IMesh(SerialObject) {
    constructor() {
        super();

        /**
         * The draw calls that make up this mesh.
         * @type {string}
         */
        this.name = "";

        /**
         * The draw calls that make up this mesh.
         * @type {Array<DrawCall>}
         */
        this._drawCalls = [];

        /**
         * The local space bounds for this mesh.
         * @type {AABB}
         */
        this.bounds = new AABB();
        
        /**
         * not supported
         */
        this._bones = [];
    }

    get drawCalls() {
        return this._drawCalls;
    }

    get bones() {
        return this._bones;
    }

    /**
     * Whether the mesh has bones or not.
     * @returns {Boolean} True if it has bones, false otherwise.
     */
    get isSkeletal() {
        return this._bones.length > 0;
    }
    
    _writeXmlAttributes(xmlDoc) {
        const attributes = super._writeXmlAttributes(xmlDoc);
        
        attributes.set("Name", this.name);
        
        const bounds = `${this.bounds.min.x}, ${this.bounds.min.y}, ${this.bounds.min.z}, ${this.bounds.max.x}, ${this.bounds.max.y}, ${this.bounds.max.y}`;
        
        attributes.set("Bounds", bounds);
        
        return attributes;
    }
    
    _writeXmlElements(xmlDoc) {
        const elements = super._writeXmlElements(xmlDoc);
        
        this.drawCalls.forEach(drawCall => {
            const element = this._writeObjectXml(drawCall, xmlDoc);
            elements.push(element);
        });
        
        this._bones.forEach(bone => {
            const element = this._writeObjectXml(bone, xmlDoc);
            elements.push(element);
        });
        
        return elements;
    }
}

export {StaticMesh};
