import {SerialNode} from "./../serialization/serialnode.js";
import * as SerialValue from "./../serialization/serialvalueattribute.js";

/**
 * The base class for platform specific shader uniform information.
 */
class UniformInfo {
    constructor() {
        /**
         * Indicates that the uniform is referenced in the currently bound shader program.
         * @type {Boolean}
         */
        this.isActive = false;

        /**
         * Indicates that the uniform has been set for the currently bound shader program.
         * @type {Boolean}
         */
        this.isSet = false;
    }
}

/**
 * This class is used to reference a shader uniform by name and assign a value to it.
 */
class Uniform extends SerialNode {
    constructor() {
        super();
        
        /**
         * The platform specific information about the shader uniform.
         */
        this.info = new UniformInfo();

        /**
         * The value to which the shader uniform should be set.
         * @type {Array<Number>}
         */
        this.value = [];
    }
    
    getSerialValuesAttributes() {
        const attributes = [new SerialValue.Attribute("value")];
        return attributes;
    }
}

const IUniformSource = Base => class IUniformSource extends Base {
    /**
     * @returns {Array<Uniform>}
     */
    get uniforms() {}

    /**
     * @returns {Array<Uniform>}
     */
    get renderUniforms() {}
};

class UniformContainer {
    constructor() {
        /**
         * @type {Set<Uniform>}
         */
        this.uniforms = new Set();
    }

    addUniform(uniform) {
        this.uniforms.add(uniform);
    }

    removeUniform(uniform) {
        this.uniforms.delete(uniform);
    }
}

export { Uniform, UniformContainer, IUniformSource };
