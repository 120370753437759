import * as siteConfig from '../siteconfig';
import { oktaAuth } from '../services/okta-service';
import { appInsights } from '../appInsights.js';

// Tool details page calls

export const fetchTool = async (id) => {
  const accessToken = (await oktaAuth.tokenManager.get('accessToken'))
    ?.accessToken;

  try {
    if (accessToken) {
      const response = await fetch(`${siteConfig.searchApiUrl}/tools/${id}`, {
        headers: {
          accept: 'text/plain',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch tool /${response.status}/`);
      }
      return response.json();
    } else {
      const response = await fetch(`${siteConfig.searchApiUrl}/tools/${id}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch anonymous tool /${response.status}/`);
      }
      return response.json();
    }
  } catch (error) {
    appInsights.trackException({ error });
    throw error;
  }
};
