import {ITypedAssetProxy} from "./../assets/assetproxy.js";
import {MaterialDefinition} from "./shaders/materialdefinition.js";
import {SerialAssetInfo} from "./../assets/serialassetinfo.js";
import {SerialNode} from "./../serialization/serialnode.js";
import * as SerialValue from "./../serialization/serialvalueattribute.js";
import {Uniform, UniformContainer} from "./uniform.js";

/**
 * @enum {Number}
 */
const LightingModel = Object.freeze({
    DEFAULT: 0,
    SUBSURFACE: 1
});

/**
 * @enum {Number}
 */
const Blending = Object.freeze({
    UNKNOWN: -1,
    OPAQUE: 1,
    TRANSLUCENT: 2,
    REFRACTING: 3
});

// This an the associated class is because we don't have an implementation of
// AssetFactory, but we need similar functionality from result of loading an
// asset (Billy 11-24-2020).
class _PlaceholderMaterialDefinitionAssetProxy {}

class PlaceholderMaterialDefinitionAssetProxy extends ITypedAssetProxy(_PlaceholderMaterialDefinitionAssetProxy) {
    constructor(asset = null) {
        super();
        
        this._asset = asset;
    }
    
    get asset() {
        return this._asset;
    }
}

/**
 * This class provides a serializable set of shader uniforms to be used when rendering geometry.
 */
class Material extends SerialNode {
    constructor(name = "") {
        super();

        this.name = name;
        
        /**
         * @type {MaterialDefinition}
         */
        this._materialDefinitionInfo = null;
        
        /**
         * @type {MaterialDefinition}
         */
        this._materialDefinition = null;
        
        /**
         * @type {Array<Sampler>}
         */
        this._samplers = [];
        
        this._uniforms = new UniformContainer();
        
        this._isDisposed = false;
    }
    
    /**
     * @returns {ShaderCodeBase}
     */
    get materialShaderCode() {
        const result = this._materialDefinition && this._materialDefinition.asset ? this._materialDefinition.asset.materialShaderCode : null;
        
        return result;
    }
    
    /**
     * @returns {LightingModel}
     */
    get lightingModel() {
        const result = this._materialDefinition && this._materialDefinition.asset ? this._materialDefinition.asset.lightingModel : LightingModel.DEFAULT;
        
        return result;
    }
    
    /**
     * @returns {Blending}
     */
    get blendMode() {
        const result = this._materialDefinition && this._materialDefinition.asset ? this._materialDefinition.asset.blendMode : Blending.OPAQUE;
        
        return result;
    }
    
    /**
     * @returns {Boolean}
     */
    get isTwoSided() {
        const result = this._materialDefinition && this._materialDefinition.asset ? this._materialDefinition.asset.isTwoSided : false;
        
        return result;
    }
    
    /**
     * @returns {Boolean}
     */
    get useInlineMaterialProperties() {
        const result = this._materialDefinition && this._materialDefinition.asset ? this._materialDefinition.asset.useInlineMaterialProperties : false;
        
        return result;
    }
    
    /**
     * @returns {IAssetInfo}
     */
    get materialDefinitionInfo() {
        return this._materialDefinitionInfo;
    }
    
    set materialDefinitionInfo(value) {
        if (this._materialDefinitionInfo != value) {
            this._releaseMaterialDefinition();
            
            this._materialDefinitionInfo = value;
            
            this._loadMaterialDefinition();
        }
    }
    
    /**
     * @returns {MaterialDefinition}
     */
    get materialDefinition() {
        const asset = this._materialDefinition ? this._materialDefinition.asset : null;
        return asset;
    }

    /**
     * @returns {Array<Uniform>}
     */
    get uniforms() {
        return this._uniforms.uniforms;
    }

    /**
     * @returns {Array<Uniform>}
     */
    get renderUniforms() {
        return this._uniforms.renderUniforms;
    }
    
    /**
     * @returns {Array<Sampler>}
     */
    get samplers() {
        return this._samplers;
    }
    
    getSerialValuesAttributes() {
        const attributes = [
            new SerialValue.Attribute("assetId"),
            new SerialValue.Attribute("propertyName"),
            new SerialValue.Attribute("materialDefinitionInfo", true),
        ];
        
        return attributes;
    }
    
    /**
     * @param {Stream} data
     * @returns {Boolean}
     */
    validateDataFormat(data) {
        throw new Error("Not implemented.");
    }
    
    dispose() {
        this._releaseMaterialDefinition();
        
        // release our samplers
        this._samplers.forEach(s => s.releaseTexture());
        
        this._isDisposed = true;
    }
    
    /**
     * @param {Uniform} uniform Uniform to add.
     */
    addUniform(uniform) {
        this.addChild(uniform);
    }
    
    /**
     * @param {Sampler} sampler Sampler to add.
     */
    addSampler(sampler) {
        this.addChild(sampler);
    }
    
    resolveUniforms(renderer) {
        throw new Error("Not implemented from original.");
    }
    
    save(stream) {
        throw new Error("Not implemented from original.");
    }

    sync() {
        this._uniforms.sync();
    }
    
    load(stream) {
        throw new Error("Not implemented from original.");
    }

    _onNewChild(child, after) {
        super._onNewChild(child, after);

        if (child instanceof Uniform) {
            /**
             * @type {Uniform}
             */
            const uniform = child;

            for (const existing of this._uniforms.uniforms) {
                if (existing.name == uniform.name) {
                    this._uniforms.removeUniform(existing);
                    break;
                }
            }

            this._uniforms.addUniform(uniform);
        }
        else if (child instanceof Sampler) {
            /**
             * @type {Sampler}
             */
            const sampler = child;

            for (const existing of this._samplers) {
                if (existing.name == sampler.name) {
                    this._samplers.removeSampler(existing);
                    break;
                }
            }

            this._samplers.addSampler(sampler);
        }
    }
    
    /**
     * @param {SerialNode} child
     */
    _onRemoveChild(child) {
        throw new Error("Not implemented from original.");
    }
    
    _addMaterialDefinitionData() {
        if (this.materialDefinition) {
            this.materialDefinition.uniforms.forEach(uniform => {
                let found = false;
                
                for (const existing of this._uniforms.uniforms) {
                    if (existing.name == uniform.name) {
                        found = true;
                        break;
                    }
                }
                
                if (!found) {
                    this._uniforms.push(uniform);
                }
            });
            
            this.materialDefinition.samplers.forEach(sampler => {
                let found = false;
                
                for (const existing of this._samplers) {
                    if (existing.name == sampler.name) {
                        found = true;
                        break;
                    }
                }
                
                if (!found) {
                    this._samplers.push(sampler);
                }
            });
        }
    }
    
    _removeMaterialDefinitions() {
        const removeUniforms = this._uniforms.uniforms.filter(uniform => uniform.parent != this);
        
        const removeSamplers = this._samplers.filter(sampler => sampler.parent != this);
        
        removeUniforms.forEach(uniform => {
            const index = this._uniforms.indexOf(uniform);
            if (index != -1) {
                this._uniforms.splice(index, 1);
            }
        });
        
        removeSamplers.forEach(sampler => {
            const index = this._samplers.indexOf(sampler);
            if (index != -1) {
                this._samplers.splice(index, 1);
            }
        });
    }
    
    _loadMaterialDefinition() {
        if (!this._materialDefinition) {
            if (this.materialDefinitionInfo) {
                console.warn("Not performing original code functionality because AssetFactory is not implemented, work-around performed instead (Billy 11-24-2020).");
                // this._materialDefinition = AssetFactory.loadAsset(this._materialDefinitionInfo);
                this._materialDefinition = new PlaceholderMaterialDefinitionAssetProxy(new MaterialDefinition());
                if (this._materialDefinition) {
                    console.warn("TODO: don't support events like c# so add if we need to (Billy 11-19-2020)");
                    
                    this._addMaterialDefinitionData();
                }
            }
        }
        else {
            throw new Error("Not implemented.");
        }
    }
    
    _releaseMaterialDefinition() {
        if (this._materialDefinition) {
            this._removeMaterialDefinitionData();
            
            console.warn("TODO: remove event if we wind up ever adding it [line 334] (Billy 11-19-2020)").
            
            AssetFactory.releaseAsset(this._materialDefinition);
            this._materialDefinition = null;
        }
    }
}

Material.MaterialAssetInfo = class MaterialAssetInfo extends SerialAssetInfo {
    constructor() {
        super(Material);
        
        this._assetId = null;
    }
    
    get assetId() {
        return this._assetId;
    }
    
    set assetId(value) {
        this._assetId = value;
    }
    
    get assetIds() {
        return [this._assetId];
    }
    
    get lastModifiedUtc() {
        return this._getAssetIdsLastModifiedUtc();
    }
    
    getSerialValuesAttributes() {
        const attributes = [
            new SerialValue.Attribute("assetId")
        ];
        
        return attributes;
    }
    
    load() {
        throw new Error("not implemented from original.");
    }
    
    /**
     * @param {Material.MaterialAssetInfo} other Another MaterialAssetInfo to compare equality against.
     * @returns {Boolean} True if this MaterialAssetInfo is equal to the other or false otherwise.
     */
    equals(other) {
        let result;
        
        if (!other) {
            result = false;
        }
        else {
            result = this._assetId == other.assetId;
        }
        
        return result;
    }
};

Material.MaterialResourceInfo = class MaterialResourceInfo extends SerialAssetInfo {
    constructor() {
        super(Material);
        
        this.resourceName = "";
    }
    
    get description() {
        return this.resourceName;
    }
    
    get assetIds() {
        return [];
    }
    
    get lastModifiedUtc() {
        const modifiedDate = SerialAssetInfo._getResourceLastModifiedUtc(this.resourceName);
        return modifiedDate;
    }
    
    getSerialValuesAttributes() {
        const attributes = [
            new SerialValue.Attribute("resourceName")
        ];
        
        return attributes;
    }
    
    load() {
        throw new Error("Not implemented from original.");
    }
    
    equals(other) {
        const result = other ? this.resourceName == other.resourceName : null;
        return result;
    }
};

/**
 * @param {Guid} assetId
 */
Material.createAssetInfoForId = function(assetId) {
    const info = new Material.MaterialAssetInfo();
    info.assetId = assetId;
    
    return info;
};

/**
 * @param {string} resourceName
 */
Material.createAssetInfoForResource = function(resourceName) {
    const info = new Material.MaterialResourceInfo();
    info.resourceName = resourceName ;
    
    return info;
};

export { Blending, LightingModel, Material };
