import {BinaryWriter} from "../../../base/binarywriter.js";
import {BufferData} from "./../renderer/bufferresource.js";

class StagingBufferData {
    /**
     * Creates a new StagingBufferData.
     * @param {Number} itemSize Number of bytes of a single element contained in the buffers.
     */
    constructor(itemSize) {
        /**
         * @type {Array<BufferData>}
         */
        this._buffers = [];

        /**
         * @type {Number}
         */
        this._totalItemCount = 0;

        /**
         * @type {Number}
         */
        this.totalLength = 0;

        /**
         * @type {Number}
         */
        this._itemSize = itemSize;

        this.resource = null;

        /**
         * @type {string}
         */
        this.name = "";
    }

    get itemCount() {
        return this._totalItemCount;
    }

    /**
     * Adds the buffer to the collection of buffers.
     * 
     * The buffer must be a multiple of the the item size.
     * @param {BufferData} buffer Buffer to add to collection.
     */
    addResourceBuffer(buffer) {
        if (buffer.data.byteLength % this._itemSize == 0) {
            this._buffers.push(buffer);
            this._totalItemCount += buffer.data.byteLength / this._itemSize;
            this.totalLength += buffer.data.byteLength;
        }
        else {
            throw new Error("The buffer must of a multiple of the item size.");
        }
    }

    /**
     * Merges the buffers in the collection into a single buffer.
     * @returns {BufferData} Merged data.
     */
    mergeBuffers() {
        const merged = new ArrayBuffer(this.totalLength);
        const writer = new BinaryWriter(merged);

        this._buffers.forEach(buffer => writer.copyBytes(new Uint8Array(buffer.data)));

        return new BufferData(merged);
    }
}

export { StagingBufferData };
