import { CutModelNode } from './cutmodelnode'
import { Model } from './model';

// Halliburton.eCompletions.PartCatalog.Common.Helper.CutToolHelper.cs

class CutToolHelper {
    /**
     * @param {boolean} isToolCutBottomRight 
     * @param {boolean} isToolCutBottomLeft 
     * @param {boolean} isToolCutTopLeft 
     * @param {boolean} isToolCutTopRight 
     * @returns {Object}
     */
    static CalculateCutAngle(
        isToolCutBottomRight,
        isToolCutBottomLeft,
        isToolCutTopLeft,
        isToolCutTopRight
    ) {
        let startAngle = 0
        let cutAngle = 0
        let isSymmetry = false

        let startPositionToCut = 0;
        let beginPartSelectedPosition = 0;
        let endPartSelectedPosition = 0;

        const listOfPartTool = []

        listOfPartTool.push(isToolCutBottomRight ? '1' : '0')
        listOfPartTool.push(isToolCutBottomLeft ? '1' : '0')
        listOfPartTool.push(isToolCutTopLeft ? '1' : '0')
        listOfPartTool.push(isToolCutTopRight ? '1' : '0')

        const numSelected = listOfPartTool.filter((value) => value === '1').length
        switch(numSelected) {
            case 1:
                cutAngle = 90;
                startPositionToCut = listOfPartTool.indexOf('1');
                break

            case 2:
                cutAngle = 180;

                beginPartSelectedPosition = listOfPartTool.indexOf('1');
                endPartSelectedPosition = listOfPartTool.lastIndexOf('1');

                if(endPartSelectedPosition - beginPartSelectedPosition === 1) {
                    startPositionToCut = beginPartSelectedPosition;
                }
                else {
                    if(endPartSelectedPosition === 3 && beginPartSelectedPosition === 0) {
                        startPositionToCut = endPartSelectedPosition;
                    }
                    else {
                        startPositionToCut = beginPartSelectedPosition;
                        isSymmetry = true;
                        cutAngle = 90;
                    }
                }
                break

            case 3:
                cutAngle = 270;
                startPositionToCut = listOfPartTool.indexOf('0') + 1;
                if(startPositionToCut === 4) {
                    startPositionToCut = 0;
                }
                break
        }

        switch(startPositionToCut) {
            case 0:
                startAngle = 0;
                break
            case 1:
                startAngle = 90;
                break
            case 2:
                startAngle = 180;
                break
            case 3:
                startAngle = 270;
                break
        }

        if(!isToolCutBottomRight && !isToolCutBottomLeft && !isToolCutTopLeft && !isToolCutTopRight) {
            cutAngle = 0;
            startAngle = 0;
        }

        return {
            startAngle,
            cutAngle,
            isSymmetry
        }
    }
}

// Halliburton.eCompletions.PartCatalog.Common.Entities.CutModelNodeDecorator.cs

class CutModelNodeDecorator {
    /**
     * @param {CutModelNode.AxialCut} value
     */
    set axialCut(value) {
        this._axialCut = value;
        this.updateToolCutModel();
    }

    get isToolCutBottomRight() {
        if(this._axialCut === null) {
            return false;
        }

        const n = this._axialCut.cutAngle / 90;
        let endAngle = 0;
        let startAngle = this._axialCut.startAngle;
        let currentAngle = 0;
        for(let i = 1; i <= n; i++) {
            endAngle = startAngle + 90;
            if(startAngle <= currentAngle && currentAngle < endAngle) {
                return true;
            }

            startAngle = endAngle;
            if(startAngle >= 360) {
                startAngle = 0;
            }
        }

        return false;
    }

    get isToolCutBottomLeft() {
        if(this._axialCut === null) {
            return false;
        }

        const n = this._axialCut.cutAngle / 90;
        let endAngle = 0;
        let startAngle = this._axialCut.startAngle;
        let currentAngle = 90;
        for(let i = 1; i <= n; i++) {
            endAngle = startAngle + 90;
            if(startAngle <= currentAngle && currentAngle < endAngle) {
                return true;
            }

            startAngle = endAngle;
            if(startAngle >= 360) {
                startAngle = 0;
            }
        }

        return false;
    }
    
    get isToolCutTopLeft() {
        if(this._axialCut === null) {
            return false;
        }

        const endAngle = this._axialCut.startAngle + this._axialCut.cutAngle;
        const currentAngle = 180;
        if(this._axialCut.startAngle <= currentAngle && currentAngle < endAngle) {
            return true;
        }

        return this.isToolCutBottomRight && this.isSymmetry;
    }
    
    get isToolCutTopRight() {
        if(this._axialCut === null) {
            return false;
        }

        const endAngle = this._axialCut.startAngle + this._axialCut.cutAngle;
        const currentAngle = 270;
        if(this._axialCut.startAngle <= currentAngle && currentAngle < endAngle) {
            return true;
        }

        return this.isToolCutBottomLeft && this.isSymmetry;
    }

    constructor() {
        /**
         * @type {CutModelNode}
         */
        this.node = null;
        
        this.isSymmetry = false;
        /**
         * @type {CutModelNode.AxialCut}
         */
        this._axialCut = null
    }

    /**
     * @param {Model} model 
     */
    setOriginalModel(model) {
        if(model !== null) {
            this.node = new CutModelNode(model);
        }
        else {
            this.node = null;
        }
    }

    updateToolCutModel() {
        // const cutNode = this.supportCutNode.cutNode;
        const cutNode = this.node;
        if(cutNode === null) {
            return;
        }

        const axialCut = this._axialCut;

        const oldAxialCuts = cutNode.getNodes(CutModelNode.AxialCut);
        if(axialCut === null) {
            if(oldAxialCuts.length > 0) {
                oldAxialCuts.forEach((currentAxialCut) => {
                    cutNode.removeModification(currentAxialCut)
                })
            }
            return;
        }

        const newAxialCuts = [];
        newAxialCuts.push(axialCut);

        if(this.isSymmetry) {
            const startAngleCutSymmetry = (axialCut.startAngle + 180) % 360;
            const axialCutSymmetry = new CutModelNode.AxialCut(
                CutModelNode.AxialCut.Axis.XAxis,
                startAngleCutSymmetry,
                90
            );

            newAxialCuts.push(axialCutSymmetry);
        }

        if(oldAxialCuts.length > 0) {
            for(let i = oldAxialCuts.length - 1; i >= 0; i--) {
                const currentAxialCut = oldAxialCuts[i];

                let newAxialCutIdx = newAxialCuts.findIndex((t) => 
                    t.startAngle === currentAxialCut.startAngle
                    && t.cutAngle === currentAxialCut.cutAngle);

                if(newAxialCutIdx >= 0) {
                    newAxialCuts.splice(newAxialCutIdx, 1);
                }
                else {
                    cutNode.removeModification(currentAxialCut);
                    oldAxialCuts.splice(i, 1);
                }
            }
        }

        newAxialCuts.forEach((currentAxialCut) => {
            cutNode.addModification(currentAxialCut);
        })

        oldAxialCuts.length = 0;
        newAxialCuts.length = 0;
        // this.supportCutNode.cutNode.update(0);
        // this.node.syncWithParent();
        // this.node.update(0);
    }

    /**
     * @param {boolean} isSymmetry 
     * @param {number} cutAngle 
     * @param {number} startAngle 
     * @param {number} offsetAngle 
     */
    applyCut(isSymmetry, cutAngle, startAngle, offsetAngle) {
        let axialCut = null;
        if(cutAngle !== 0) {
            axialCut = new CutModelNode.AxialCut(
                CutModelNode.AxialCut.Axis.XAxis,
                startAngle - offsetAngle,
                cutAngle
            );
        }
        this.isSymmetry = isSymmetry;
        this.axialCut = axialCut;
    }
}

export {
    CutToolHelper,
    CutModelNodeDecorator
}