class NodeIterationState {
    constructor(node) {
        this.node = node;
        this.visitIndex = -1;
    }
}

class TreeNode {
    /**
     * Creates a new TreeNode.
     * @param {Any} value Value to store in node.
     * @param {String} key Identifier for node.
     */
    constructor(value, key=null) {
        this.key = key;
        this.value = value;

        this._parent = null;
        this._children = new Array();
    }

    /**
     * Returns the parent node.
     * @return {TreeNode} Parent node.
     */
    get parent() {
        return this._parent;
    }

    /**
     * Returns the child nodes.
     * @return {Array<TreeNode>} Child nodes.
     */
    get children() {
        return this._children;
    }

    /**
     * Returns the first child node, if it exists.
     * @return {TreeNode} First child node.
     */
    get firstChild() {
        const node = this._children.length > 0 ? this._children[0] : null;
        return node;
    }

    /**
     * Returns the last child node, if it exists.
     * @return {TreeNode} Last child node.
     */
    get lastChild() {
        const node = this._children.length > 0 ? this._children[this._children.length - 1] : null;
        return node;
    }

    /**
     * Adds the specified node as a child to this node.
     * @param {TreeNode} node Node to add as a child.
     */
    addChild(node) {
        if (node.parent) {
            throw new Error("Attempted to add a node that's already a child to another node.");
        }

        this._children.push(node);
        node._parent = this;
    }

    /**
     * Inserts the specified node before the child node.
     * @param {TreeNode} node Node to as a child.
     * @param {TreeNode} childNode Child node to add before.
     */
    insertChildBefore(node, childNode) {
        if (childNode.parent != this) {
            throw new Error("Attempted to insert a node before a node that is not a child.");
        }

        const index = this._children.indexOf(childNode);
        if (index != -1) {
            this._children.splice(index, 0, node);
        }
    }

    /**
     * Removes the specified child node.
     * @param {TreeNode} node Child node to be removed.
     */
    removeChild(node) {
        const index = this._children.indexOf(node);
        if (index != -1) {
            this._children.splice(index, 1);

            node.parent = null;
        }
    }

    /**
     * Removes this node from it's parent's children.
     */
    remove() {
        if (this._parent) {
            this._parent.removeChild(this);
        }
    }

    /**
     * Returns the first node with the specified identifier.
     * 
     * Order is not guarunteed and should not be assumed.
     * 
     * @param {String} key Node identifier.
     */
    getNode(key) {
        let childNode = null;

        const iter = this.enumerate();

        let result = iter.next();

        while (!result.done) {
            if (result.value.key == key) {
                childNode = result.value;
                break;
            }
            else {
                result = iter.next();
            }
        }

        return childNode;
    }

    /**
     * Returns all nodes with the specified identifier.
     * @param {String} key Node identifier.
     */
    getAllNodes(key) {
        const nodes = [];

        for (node of this.enumerate()) {
            if (node.key == key) {
                nodes.push(node);
            }
        }

        return nodes;
    }

    * enumerate() {
        const iterationStack = [new NodeIterationState(this)];
        
        while (iterationStack.length > 0) {
            const iterationState = iterationStack[iterationStack.length - 1];

            // visit the current node

            const node = iterationState.visitIndex < 0 ? iterationState.node : iterationState.node.children[visitIndex];
            yield node;

            // advance the state and see if we're done

            iterationState.visitIndex += 1;
            if (iterationState.visitIndex >= iterationState.node.children.length) {
                do {
                    iterationStack.pop();
                    const previousIterationState = iterationStack[iterationStack.length - 1];

                    previousIterationState.visitIndex += 1;
                    if (previousIterationState.visitIndex < previousIterationState.node.children.length) {
                        // stop our unwinding because we have a child node to process.
                        break;
                    }
                } while (iterationState.length > 0)
            }
            else {
                const nextChild = iterationState.node.children[iterationState.visitIndex];
                iterationStack.push( new NodeIterationState(nextChild) );
            }
        }
    }
}

export { TreeNode }