import * as THREE from 'three';
import {BinaryReader} from "../binaryreader.js";
import * as H3DModel from "./model.js";
import { Vector3 } from '../../base/math.js';
import {TreeNode} from "../treenode.js";
import { oktaAuth } from './../../../services/okta-service.js'
import pako from 'pako';
import { AABB } from './aabb.js';

// REF: H3dFormat.cs

const FIRST_COMPRESSION_VERSION = 2;
const FORMAT_VERSION = 2;
const FORMAT_BYTE_ORDER_MARK = 1; 

let Loader = function(manager) {
    new THREE.Loader(manager);
};

Loader.prototype = Object.assign( Object.create(THREE.Loader.prototype), {
    constructor: Loader,

    load: function (url, onLoad, onProgress, onError) {
        var scope = this;

        var loader = new THREE.FileLoader( scope.manager );
        loader.setPath( scope.path );
        loader.setResponseType( "arraybuffer" );

        loader.load(url, function(buffer) {
            onLoad( scope.parse(buffer) );
        }, onProgress, onError);
    },

    authLoad: function (url, onLoad, onProgress, onError) {
        var scope = this;
        oktaAuth.tokenManager.get('accessToken').then(tokenResponse => {
            var oReq = new XMLHttpRequest();
            oReq.open("GET", url, true);
            oReq.responseType = "arraybuffer";
            oReq.setRequestHeader('Authorization', 'Bearer ' + tokenResponse.accessToken);
            oReq.onload = function (oEvent) {
                var arrayBuffer = oReq.response;
                onLoad(scope.parse(arrayBuffer));
            };
            oReq.onprogress = onProgress;
            oReq.onerror = onError;
            oReq.send();
        });
    },

    parse: (function() {
        /**
         * @enum {String}
         */
        const AttributeName = Object.freeze({
            NAME: "Name",
            
            USAGE: "Usage",
            USAGE_INDEX: "UsageIndex",
            TYPE: "Type",
            COUNT: "Count",
            OFFSET: "Offset",
            STRIDE: "Stride"
        });

        /**
         * @enum {String}
         */
        const DataType = Object.freeze({
            FLOAT: "float",
            UINT: "uint"
        });

        const UsingVertexBufferAttribute = function(attribute, handler) {
            const keys = [
                AttributeName.USAGE, 
                AttributeName.USAGE_INDEX, 
                AttributeName.TYPE, 
                AttributeName.COUNT, 
                AttributeName.OFFSET, 
                AttributeName.STRIDE];

            const numericKeys = new Set([
                AttributeName.USAGE_INDEX, 
                AttributeName.COUNT, 
                AttributeName.OFFSET, 
                AttributeName.STRIDE]);

            const attributes = keys.reduce((store, key) => {
                const value = attribute.getAttribute(key);
                if (value) {
                    if (!numericKeys.has(key)) {
                        store.set(key, value);
                    }
                    else {
                        const numericValue = parseInt(value);
                        store.set(key, numericValue);
                    }
                }
                else {
                    console.warn(`Could not find attribute named: '${key}'.`);
                }

                return store;
            }, new Map());

            if (attributes.size == keys.length) {
                handler(attributes);
                return true;
            }
            else {
                return false;
            }
        };

        /**
         * Returns a conversion to a DataType for a string.
         * @param {String} string String to be converted to a DataType.
         * @return {DataType} DataType for string.
         */
        const DataTypeFromString = function(string) {
            let dataType;

            switch (string.toLowerCase()) {
                case "float": {
                    dataType = DataType.FLOAT;
                    break;
                }

                case "uint": {
                    dataType = DataType.UINT;
                    break;
                }

                default: {
                    console.warn(`Unknown string: '${string}' encountered trying to convert to DataType. Assuming Float.`);
                    dataType = DataType.FLOAT;
                    break;
                }
            }

            return dataType;
        };

        /**
         * Returns a buffer of the specified type for the values read from the data.
         * @param {BinaryReader} reader BinaryReader for the data.
         * @param {DataType} bufferType Type of the data to be read.
         * @param {Number} countPerStride Number of values to read per stride.
         * @param {Number} offsetInStride Offset in bytes each stride before reading the first value.
         * @param {Number} stride Number of bytes separating each set of data.
         */
        const GetBufferFromData = function(reader, bufferType, countPerStride, offsetInStride, stride) {
            let valueCount = reader.byteLength / stride;

            let buffer;
            let nextValueFunc;

            switch (bufferType) {
                case DataType.UINT: {
                    buffer = new Uint32Array(valueCount * countPerStride);
                    nextValueFunc = () => reader.readUint32();
                    break;
                }

                case DataType.FLOAT:
                default: {
                    buffer = new Float32Array(valueCount * countPerStride);
                    nextValueFunc = () => reader.readFloat32();
                    break;
                }
            }

            let strideStart = reader.offset;

            for (var i = 0, z = 0; i < valueCount; ++i) {
                reader.seek(strideStart + offsetInStride, BinaryReader.SeekOrigin.BEGIN);

                for(var j = 0; j < countPerStride; ++j, ++z) {
                    const value = nextValueFunc();
                    buffer[z] = value;
                }

                strideStart += stride;
            }

            return buffer;
        };

        const ReadValuesForAttribute = function(attribute, reader, model, bufferName) {
            let success = UsingVertexBufferAttribute(attribute, (attributes) => {
                const typeString = attributes.get(AttributeName.TYPE);
                const count = attributes.get(AttributeName.COUNT);
                const offset = attributes.get(AttributeName.OFFSET);
                const stride = attributes.get(AttributeName.STRIDE);

                const vertices = GetBufferFromData(reader, DataTypeFromString(typeString), count, offset, stride);
                model.setVertexBufferAttribute(bufferName, attributes.get(AttributeName.USAGE), vertices, count);
            });

            return success;
        };

        const Vector3FromString = function(string, defaultValues) {
            let values = [];
            
            if (string) {
                values = string.split(", ").map(value => parseFloat(value));
            } else if (typeof defaultValues != "undefined" && defaultValues.length > 0) {
                values = defaultValues;
            }
            
            const vector = new THREE.Vector3(values[0], values[1], values[2]);
            return vector;
        };
        
        /**
         * Parses the specified number of values from the string.
         * @param {string} test String to parse values from.
         * @param {Number} count Number of values to be parsed.
         * @returns {Float32Array} Parsed values.
         */
        const ParseValuesFromText = function(text, count) {
            const pattern = /\s*([^,\s]+)\s*(,|$)/g;
            
            const values = new Float32Array(count);
            
            let index = 0;
            let match;
            
            while (match = pattern.exec(text)) {
                values[index] = parseFloat(match[1]);
                index += 1;
            }
            
            return values;
        };

        return function(buffer) {
            try {
                let compressed = false;   // TOOD: figure out what this is for
                const reader = new BinaryReader(buffer);
                reader.useLittleEndian = true;
    
                const tag = reader.readBytes(4);
    
                if (tag.getUint8(0) != 0
                    || tag.getUint8(1) != "H".charCodeAt()
                    || tag.getUint8(2) != "3".charCodeAt()
                    || tag.getUint8(3) != "D".charCodeAt()
                ) {
                    return new Loader.Result(null, "Could not load model because the tag is not valid for a H3D file.");
                }
    
                // read BOM and version
                const bom = reader.readUint32();
                const version = reader.readUint32();
    
                // check to see if we need byte swapping
                if (bom != FORMAT_BYTE_ORDER_MARK) {
                    return new Loader.Result(null, "The model requires byte swapping which has not been implemented yet.");
                }
    
                // check the version
                if (version > FORMAT_VERSION) {
                    return new Loader.Result(`The model's version (${version}) is newer than the reader's (${1}).`);
                }
    
                // read in the rest of the header.
                const dataOffset = reader.readUint32();
                const xmlLength = reader.readUint32();
                const binaryLength = reader.readUint32();
    
                // check to see if we should read in a compression flag
                if (version >= FIRST_COMPRESSION_VERSION) {
                    // TODO: verify that we should be reading 4 bytes here for the boolean.
                    compressed = reader.readUint32() != 0 ? true : false;
                }
    
                // seek to the data offset
                reader.seek(dataOffset, BinaryReader.SeekOrigin.BEGIN); 
    
                const xmlView = reader.readBytes(xmlLength);
                const binaryView = reader.readBytes(binaryLength);
    
                let xmlData;
                let binaryData;
    
                if (compressed) {
                    const rawXmlData = new Uint8Array(xmlView.buffer, xmlView.byteOffset, xmlView.byteLength);
                    xmlData = pako.ungzip(rawXmlData);
    
                    const rawBinaryData = new Uint8Array(binaryView.buffer, binaryView.byteOffset, binaryView.byteLength);

                    // we need to convert the data into a format that we're going to use
                    const convert = pako.ungzip(rawBinaryData);
                    binaryData = convert.buffer.slice(convert.byteOffset, convert.byteLength);
                }
                else {
                    xmlData = new Uint8Array(xmlView.buffer);
                    binaryData = xmlView.buffer;
                }
    
                const decoder = new TextDecoder();
                const modelXml = decoder.decode(xmlData);

                // console.log(modelXml);
    
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(modelXml, "text/xml");
                
                // do a quick document error check
                if (xmlDoc.querySelector("parsererror")) {
                    const error = xmlDoc.querySelector("parsererror");
                    console.error(error.innerHTML);
                }

                const model = new H3DModel.Model();
    
                const vertexBuffers = xmlDoc.querySelectorAll("VertexBuffers > VertexBuffer");
                Array.from(vertexBuffers).forEach(vertexBuffer => {
                    const name = vertexBuffer.getAttribute("Name");

                    const dataOffset = parseInt(vertexBuffer.getAttribute("BinaryOffset"));
                    const dataLength = parseInt(vertexBuffer.getAttribute("BinaryLength"));
    
                    const attributes = vertexBuffer.querySelectorAll("Attribute");
                    Array.from(attributes).forEach(attribute => {
                        ReadValuesForAttribute(
                            attribute, 
                            new BinaryReader(binaryData, dataOffset, dataLength, reader.useLittleEndian), 
                            model,
                            name);
                    });
                });

                const indexBuffers = xmlDoc.querySelectorAll("IndexBuffers > IndexBuffer");
                Array.from(indexBuffers).forEach(indexBuffer => {
                    const dataType = indexBuffer.getAttribute(AttributeName.TYPE);

                    const dataOffset = parseInt(indexBuffer.getAttribute("BinaryOffset"));
                    const dataLength = parseInt(indexBuffer.getAttribute("BinaryLength"));

                    const indices = GetBufferFromData(
                        new BinaryReader(binaryData, dataOffset, dataLength, reader.useLittleEndian),
                        DataTypeFromString(dataType),
                        1,
                        0,
                        4);

                    model.setIndexBuffer(indexBuffer.getAttribute("Name"), indices);
                });

                const materials = xmlDoc.querySelectorAll("Materials > Material");
                Array.from(materials).forEach(material => {
                    const modelMaterial = new H3DModel.Material();

                    const materialName = material.getAttribute(AttributeName.NAME);

                    const materialDefinition = material.querySelector("MaterialDefinitionInfo");
                    Array.from(materialDefinition.children).forEach(info => {
                        let itemName;

                        const index = info.nodeName.lastIndexOf(".");
                        if (index != -1) {
                            itemName = info.nodeName.slice(index + 1);
                        }
                        else {
                            itemName = info.nodeName;
                        }

                        switch (itemName) {
                            case "MaterialDefinitionResourceInfo": {
                                const resourceName = decodeURIComponent( info.getAttribute("ResourceName") );
                                // TODO: determine if we need to do anything with this.
                                break;
                            }

                            default: {
                                console.warn(`Unsported MaterialInformationInfo encountered: '${info.nodeName}'.`);
                                break;
                            }
                        }
                    });

                    const uniforms = material.querySelectorAll("Uniform");
                    Array.from(uniforms).forEach(uniform => {
                        const uniformValues = ParseValuesFromText(uniform.getAttribute("Value"), 4);
                        const uniformName = uniform.getAttribute(AttributeName.NAME);

                        modelMaterial.uniforms.set(uniformName, uniformValues);
                    });

                    model.setMaterial(materialName, modelMaterial);
                });

                const meshes = xmlDoc.querySelectorAll("Meshes > Mesh");
                Array.from(meshes).forEach(mesh => {
                    const name = mesh.getAttribute(AttributeName.NAME);
                    
                    const boundsArray = mesh.getAttribute("Bounds").split(", ").map(value => parseFloat(value));
                    const boundsMin = new Vector3(boundsArray[0], boundsArray[1], boundsArray[2])
                    const boundsMax = new Vector3(boundsArray[3], boundsArray[4], boundsArray[5])

                    const meshDrawCalls = [];

                    const drawCalls = mesh.querySelectorAll("DrawCall");
                    Array.from(drawCalls).forEach(drawCall => {
                        const meshDrawCall = new H3DModel.Mesh.DrawCall();

                        meshDrawCall.vertexBuffer = drawCall.getAttribute("VertexBuffer");
                        meshDrawCall.indexBuffer = drawCall.getAttribute("IndexBuffer");
                        meshDrawCall.primitive = drawCall.getAttribute("Primitive");
                        // turns out this is an offset in bytes so, lets convert it to and an offset into the index count
                        meshDrawCall.indexOffset = parseInt( drawCall.getAttribute("IndexOffset") ) / 4;
                        meshDrawCall.indexCount = parseInt( drawCall.getAttribute("IndexCount") );

                        meshDrawCalls.push(meshDrawCall);
                    });

                    const modelMesh = new H3DModel.Mesh();
                    modelMesh.bounds = new AABB(boundsMin, boundsMax);
                    modelMesh.drawCalls = meshDrawCalls;

                    model.setMesh(name, modelMesh);
                });

                const sceneNodes = xmlDoc.querySelector("SceneNodes");
                if (sceneNodes && sceneNodes.children.length > 0) {
                    let sceneRoot = new TreeNode(null, "root");

                    Array.from(sceneNodes.children).forEach(node => {
                        switch (node.nodeName) {
                            case "MeshNode": {
                                const nodeData = new H3DModel.MeshNodeData();

                                nodeData.position = Vector3FromString( node.getAttribute("Position"), [0, 0, 0] );
                                nodeData.rotation = Vector3FromString( node.getAttribute("Rotation"), [0, 0, 0] );
                                nodeData.scale = Vector3FromString( node.getAttribute("Scale"), [1, 1, 1] );
                                nodeData.name = node.getAttribute("Name");
                                nodeData.mesh = node.getAttribute("Mesh");

                                const animationMaps = node.querySelectorAll("AnimationMap > AnimationMap");
                                nodeData.animations = Array.from(animationMaps).map(animationMap => {
                                    // TODO: finish
                                });

                                const renderCalls = node.querySelectorAll("RenderCall");
                                Array.from(renderCalls).forEach(renderCall => {
                                    const materialName = renderCall.getAttribute("Material");
                                    nodeData.renderMaterials.push(materialName);
                                });

                                const meshNode = new TreeNode(nodeData, "mesh");
                                sceneRoot.addChild(meshNode);
                                break;
                            }

                            default: {
                                console.warn(`Encountered unimplemented or unknown node: '${node.name}' while iterating SceneNodes.`);
                                break;
                            }
                        }
                    });

                    model.scene = sceneRoot;
                }

                return new Loader.Result(model);
            }
            catch (e) {
                return new Loader.Result(null, e);
            }
        };
    })()
} );

Loader.Result = class {
    /**
     * Creates a new Loader.Result
     * @param {H3DModel} model Successfully loaded model.
     * @param {Error} error Error when model could not be loaded.
     */
    constructor(model, error = null) {
        this.model = model;
        this.error = error;
    }
};

export { Loader };
