import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

import { appInsightsInstrumentation } from './siteconfig';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: appInsightsInstrumentation,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
});

appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((telemetryItem) => {
  if (telemetryItem.name.endsWith('Pageview')) {
    const telemetryData = telemetryItem.baseData;
    const paramStr = telemetryData.uri.split('?')[1];

    let queryParams;
    if (paramStr) {
      queryParams = new URLSearchParams(paramStr);
    }

    if (queryParams?.has('term')) {
      telemetryData.name = 'Search';
      telemetryData.properties['searchTerm'] = queryParams.get('term');
      telemetryData.properties['resultsPerPage'] =
        queryParams.get('resultsPerPage');
      telemetryData.properties['paginatedRange'] =
        queryParams.get('paginatedRange');
      telemetryData.properties['selectedClass'] =
        queryParams.get('selectedClass');
      telemetryData.properties['sizeMinIdValue'] =
        queryParams.get('sizeMinIdValue');
      telemetryData.properties['sizeMaxIdValue'] =
        queryParams.get('sizeMaxIdValue');
      telemetryData.properties['sizeMinOdValue'] =
        queryParams.get('sizeMinOdValue');
      telemetryData.properties['sizeMaxOdValue'] =
        queryParams.get('sizeMaxOdValue');
      telemetryData.properties['sizeMinLengthValue'] =
        queryParams.get('sizeMinLengthValue');
      telemetryData.properties['sizeMaxLengthValue'] =
        queryParams.get('sizeMaxLengthValue');
      telemetryData.properties['selectedCoreProductTypeValues'] =
        queryParams.get('selectedCoreProductTypeValues');
      telemetryData.properties['selectedTopThreadValues'] = queryParams.get(
        'selectedTopThreadValues'
      );
      telemetryData.properties['selectedBottomThreadValues'] = queryParams.get(
        'selectedBottomThreadValues'
      );
      telemetryData.properties['selectedConnectionValues'] = queryParams.get(
        'selectedConnectionValues'
      );
      telemetryData.properties['selectedMaterialValues'] = queryParams.get(
        'selectedMaterialValues'
      );
      telemetryData.properties['selectedServiceValues'] = queryParams.get(
        'selectedServiceValues'
      );
      telemetryData.properties['browseOptions'] =
        queryParams.get('browseOptions');
    } else if (telemetryData.uri.includes('tool')) {
      if (!telemetryData.properties) {
        telemetryData.properties = {};
      }
      const splitURI = telemetryData.uri.split('/');

      telemetryData.name = 'ToolDetails';
      telemetryData.properties['sapOrLegacy'] = splitURI[splitURI.length - 1];
    } else if (telemetryData.uri.includes('shopping-cart')) {
      telemetryData.name = 'ShoppingCart';
    } else if (telemetryData.uri.includes('browse')) {
      telemetryData.name = 'Browse';
    } else if (telemetryData.uri.includes('previous-requests')) {
      telemetryData.name = 'PreviousRequests';
    } else if (telemetryData.uri.includes('favorites')) {
      telemetryData.name = 'Favorites';
    } else {
      telemetryData.name = 'Landing';
    }
  }
});

export { reactPlugin, appInsights };
