import React, { useEffect, useRef, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useOktaAuth } from '@okta/okta-react';
import styled from 'styled-components';
import { useOktaAuthContext } from '../../OktaAuthContext.js';

import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

import IconButton from '@mui/material/IconButton';

import { Search } from 'react-feather';
import { X } from 'react-feather';

import ErrorModal from '../../Modals/ErrorModal.jsx';
import { ErrorModalContext } from '../../Modals/ErrorModal.jsx';
import ApplicationContext from '../../ApplicationContext.js';

import { fetchTool } from '../../backend/tooldetails.js';

import Spinner from '../../shared/Loader/Spinner';

const SearchBarContainer = styled.div`
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const StyledMagnifyingGlass = styled(Search)`
  padding-right: 20px;
`;

const PreviousSearchesDropdown = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  top: 135px;
  background-color: var(--color-gray5);
  max-height: 300px;
  min-height: 70px;
  border-top: none !important;
  border: 2px solid #1976d2;
  // border-radius: 4px;
  // border-top-left-radius: 0px;
  // border-top-right-radius: 0px;
  overflow-y: auto;
  overflow-x: clip;
  word-wrap: break-word;
  @media (max-width: 1024px) {
    margin-left: 5%;
    margin-right: 5%;
    left: 0;
    right: 0;
  }
  @media (min-width: 1024px) {
    width: 466px;
  }
  z-index: 99;
`;

const ButtonContainer = styled.div`
  padding-top: 32px;
  width: 100%;
`;

const PrevSearchQueriesContainer = styled.div`
  display: flex;
  align-items: center;
`;

const PreviousSearchQuery = styled.button`
  font-family: Univers;
  padding-left: 10px;
  margin-bottom: 6px;
  width: 100%;
  background: none;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: var(--color-gray4);
  }
  text-align: left;
`;

const ClearIndividualSearchQueriesButton = styled.button`
  font-family: Univers;
  border: none;
  background: none;
  cursor: pointer;
  margin-left: 8px;
  margin-right: 4px;
  padding: 0;
`;

const ClearPreviousSearchesContainer = styled.div`
  position: absolute;
  background-color: var(--color-gray5);
  width: 465px;
  @media (max-width1024px) {
    width: 88%;
  }
`;

const Divider = styled.hr`
  width: 97%;
  margin-bottom: 0px;
  border: 0.3px solid var(--color-gray3);
`;

const ClearPreviousSearchesButton = styled.button`
  font-family: Univers;
  font-size: 12px;
  font-weight: 500;
  border: none;
  color: #e50000;
  background: none;
  padding: 5px;
  cursor: pointer;
`;

const SpinnerContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  z-index: 99;
`;

const SearchBar = () => {
  const previousSearchesArray =
    JSON.parse(localStorage.getItem('previousSearchesArray')) || [];

  const navigate = useNavigate();
  const location = useLocation();
  const formRef = useRef(null);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  const { classType } = useContext(ApplicationContext);

  const [searchQuery, setSearchQuery] = useState('');
  const [clickInsideDropdown, setClickInsideDropdown] = useState(false);
  const [navigationByKeyboard, setNavigationByKeyboard] = useState(false);

  const [showPreviousSearchesDropdown, setShowPreviousSearchesDropdown] =
    useState(false);
  const [warning, setWarning] = useState('');
  const [
    redirectToSearchViewWIthoutToolCheck,
    setRedirectToSearchViewWIthoutToolCheck,
  ] = useState(false);
  const [searchForTool, setSearchForTool] = useState(false);
  const [toolData, setToolData] = useState(data);
  const { openModal } = useOktaAuthContext();
  const [previousSearchQueries, setPreviousSearchQueries] = useState(
    previousSearchesArray
  );

  const [, setError] = useContext(ErrorModalContext);

  const { authState } = useOktaAuth();

  const {
    data,
    isLoading,
    error: fetchToolError,
  } = useQuery('tool', () => fetchTool(searchQuery), {
    enabled: searchForTool,
    onSuccess: (fetchedData) => {
      setToolData(fetchedData);
    },
    onError: (error) => {
      const errorCode = error.message.split('/');
      setError(
        error.constructor.name === 'TypeError'
          ? {
              title: 'Looks like something went wrong',
              message:
                'Network connection lost. Please check your network connection and try again.',
            }
          : {
              title: 'Looks like something went wrong',
              message:
                'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
              errorCode: `#TO-1-${errorCode[1]}`,
            }
      );
    },
  });

  const handleClickInsideInput = () => {
    setShowPreviousSearchesDropdown((prev) => !prev);
  };

  const handleQueryInput = (event) => {
    setSearchQuery(event.target.value);
    setShowPreviousSearchesDropdown(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (searchQuery.length < 2) {
      setWarning('Search length must be at least 2 characters.');
    } else if (searchQuery.match(/^\s+$/)) {
      setWarning('Search must not be only empty characters.');
    } else if (searchQuery.length > 24) {
      setRedirectToSearchViewWIthoutToolCheck(true);
      navigate(
        `/search?term=${searchQuery}&resultsPerPage=10&paginatedRange=1&selectedClass=&sizeMinIdValue=&sizeMaxIdValue=&sizeMinOdValue=&sizeMaxOdValue=&sizeMinLengthValue=&sizeMaxLengthValue=&selectedCoreProductTypeValues=&selectedTopThreadValues=&selectedBottomThreadValues=&selectedConnectionValues=&selectedMaterialValues=&selectedServiceValues=&browseOptions=`
      );
    } else {
      setRedirectToSearchViewWIthoutToolCheck(false);
      if (!authState?.isAuthenticated) {
        openModal(
          `/search?term=${searchQuery}&resultsPerPage=10&paginatedRange=1&selectedClass=&sizeMinIdValue=&sizeMaxIdValue=&sizeMinOdValue=&sizeMaxOdValue=&sizeMinLengthValue=&sizeMaxLengthValue=&selectedCoreProductTypeValues=&selectedTopThreadValues=&selectedBottomThreadValues=&selectedConnectionValues=&selectedMaterialValues=&selectedServiceValues=&browseOptions=`
        );
      } else {
        if (!previousSearchesArray.includes(searchQuery)) {
          previousSearchesArray.push(searchQuery);
          localStorage.setItem(
            'previousSearchesArray',
            JSON.stringify(previousSearchesArray)
          );
          setPreviousSearchQueries(previousSearchQueries.concat(searchQuery));
        }
        setSearchForTool(true);
        setToolData(null);
      }
    }
  };

  const handlePreviousQueryClick = (query) => {
    setSearchQuery(query);
    if (!authState?.isAuthenticated) {
      openModal(
        `/search?term=${query}&resultsPerPage=10&paginatedRange=1&selectedClass=&sizeMinIdValue=&sizeMaxIdValue=&sizeMinOdValue=&sizeMaxOdValue=&sizeMinLengthValue=&sizeMaxLengthValue=&selectedCoreProductTypeValues=&selectedTopThreadValues=&selectedBottomThreadValues=&selectedConnectionValues=&selectedMaterialValues=&selectedServiceValues=&browseOptions=`
      );
    } else if (query.length > 24) {
      setRedirectToSearchViewWIthoutToolCheck(true);
      navigate(
        `/search?term=${query}&resultsPerPage=10&paginatedRange=1&selectedClass=&sizeMinIdValue=&sizeMaxIdValue=&sizeMinOdValue=&sizeMaxOdValue=&sizeMinLengthValue=&sizeMaxLengthValue=&selectedCoreProductTypeValues=&selectedTopThreadValues=&selectedBottomThreadValues=&selectedConnectionValues=&selectedMaterialValues=&selectedServiceValues=&browseOptions=`
      );
    } else {
      setRedirectToSearchViewWIthoutToolCheck(false);
      setSearchForTool(true);
      setToolData(null);
      setWarning('');
    }
  };

  useEffect(() => {
    if (searchQuery === '') {
      setWarning('');
    }
  }, [searchQuery]);

  useEffect(() => {
    if (searchForTool && !redirectToSearchViewWIthoutToolCheck) {
      if (!isLoading && toolData) {
        if (Object.entries(toolData).length === 0) {
          navigate(
            `/search?term=${searchQuery}&resultsPerPage=10&paginatedRange=1&selectedClass=&sizeMinIdValue=&sizeMaxIdValue=&sizeMinOdValue=&sizeMaxOdValue=&sizeMinLengthValue=&sizeMaxLengthValue=&selectedCoreProductTypeValues=&selectedTopThreadValues=&selectedBottomThreadValues=&selectedConnectionValues=&selectedMaterialValues=&selectedServiceValues=&browseOptions=`
          );
        } else {
          navigate(`/tool/${searchQuery}`);
        }
        setSearchForTool(false);
        setToolData(null);
      }
    }
  }, [searchForTool, isLoading, toolData]);

  // Effect to populate searchQuery from URL
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const term = searchParams.get('term');
    if (term) {
      setSearchQuery(term);
    }
  }, [location.search]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !clickInsideDropdown &&
        formRef.current &&
        !formRef.current.contains(event.target)
      ) {
        setShowPreviousSearchesDropdown(false);
      }
      setClickInsideDropdown(false);
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showPreviousSearchesDropdown]);

  document.addEventListener('keydown', function (event) {
    if (event.key === 'Tab') {
      setNavigationByKeyboard(true);
    }
  });

  document.addEventListener('click', function (event) {
    setNavigationByKeyboard(false);
  });

  const handleFocus = () => {
    if (navigationByKeyboard) {
      setShowPreviousSearchesDropdown((prev) => !prev);
    }
  };

  const handleClearQuery = (e) => {
    e.stopPropagation();
    setSearchQuery('');
    inputRef.current.focus();
  };

  const handleClearPreviousSearches = () => {
    localStorage.removeItem('previousSearchesArray');
    inputRef.current.focus();
  };

  const handleRemoveIndividualQuery = (e, queryToRemove) => {
    e.stopPropagation();
    const updatedArray = previousSearchesArray.filter(
      (query) => query !== queryToRemove
    );
    localStorage.setItem('previousSearchesArray', JSON.stringify(updatedArray));
    setPreviousSearchQueries(updatedArray);
  };

  return (
    <SearchBarContainer>
      <ErrorModal />
      <form onSubmit={handleSubmit} ref={formRef}>
        <FormControl style={{ height: '40px', width: '100%' }}>
          <TextField
            variant="outlined"
            value={searchQuery}
            onChange={handleQueryInput}
            autoComplete="off"
            placeholder="Keyword, Material/SAP/Legacy Number"
            onMouseDown={handleClickInsideInput}
            onFocus={handleFocus}
            InputProps={{
              inputRef: inputRef,
              startAdornment: <StyledMagnifyingGlass />,
              endAdornment: (
                <>
                  {!searchForTool ? (
                    searchQuery && (
                      <IconButton
                        aria-label="clear query"
                        alt="clear query"
                        edge="end"
                        onClick={(e) => handleClearQuery(e)}
                      >
                        <X size={18} />
                      </IconButton>
                    )
                  ) : (
                    <SpinnerContainer>
                      <Spinner active extraSmall={true} />
                    </SpinnerContainer>
                  )}
                </>
              ),
              style: {
                backgroundColor: 'var(--color-gray5)',
                height: '44px',
                width: '100%',
                borderRadius: 0,
              },
            }}
            style={{
              width: classType === 'compact' ? '90%' : '470px',
              marginLeft: classType === 'compact' ? '5%' : '0',
              marginRight: classType === 'compact' ? '5%' : '0',
            }}
          />

          <FormHelperText error style={{ color: 'var(--color-gray4)' }}>
            {warning}
          </FormHelperText>
        </FormControl>
      </form>

      {showPreviousSearchesDropdown && previousSearchesArray.length !== 0 && (
        <PreviousSearchesDropdown ref={dropdownRef}>
          <ClearPreviousSearchesContainer>
            <Divider />
            <ClearPreviousSearchesButton
              alt="clear previous searches"
              onClick={handleClearPreviousSearches}
            >
              Clear Recent Searches
            </ClearPreviousSearchesButton>
          </ClearPreviousSearchesContainer>
          <ButtonContainer>
            {previousSearchQueries.map((query) => (
              <PrevSearchQueriesContainer key={query}>
                <ClearIndividualSearchQueriesButton
                  alt="delete"
                  aria-label="delete previously searched query"
                  onClick={(e) => handleRemoveIndividualQuery(e, query)}
                >
                  <X size={12} />
                </ClearIndividualSearchQueriesButton>
                <PreviousSearchQuery
                  key={query}
                  onClick={() => handlePreviousQueryClick(query)}
                  alt="previously searched query"
                >
                  {query}
                </PreviousSearchQuery>
              </PrevSearchQueriesContainer>
            ))}
          </ButtonContainer>
        </PreviousSearchesDropdown>
      )}
    </SearchBarContainer>
  );
};

export default SearchBar;
