import * as animate_ from "./dom/animate.js";

// This is to accommodate for non-chromimum MS Edge not being able to
// "import * as..." and then export it back out again. If we save the 
// imports as a variable, we can then export them back out and Edge will
// pick them up. This also continues to work in the other major browsers.
// Should probably remove this when non-chromium Edge is no longer
// required (billy 05-02-2020).
const animate = animate_;

export {animate};
