import {SerialValueAttribute} from "./serialvalueattribute.js";
import * as Reflection from "./../reflection.js";

import * as H3D from "./../../h3d.js";

/**
 * @type {Reflection.Namespace}
 */
let h3dNamespace = null;

function CatalogDerivedTypes() {
    if (!h3dNamespace) {
        h3dNamespace = new Reflection.Namespace(H3D, ["Loader", "Model"]);
    }
}

/**
 * This interface is used to serialize scene objects. It includes support for maintaining
 * backwards compatibility with older h3d files.
 */
class SerialObject {
    constructor() {}
    
    /**
     * Returns the scoped type name for the specified type.
     * @param {ObjectConstructor|string} type Type to get scoped type name for.
     * @returns {string} Scoped type name.
     */
    getScopedTypeName(type) {
        CatalogDerivedTypes();
        
        const name = h3dNamespace.fullNameForType(type);
        return name;
    }
    
    /**
     * @param {SerialObject} obj Object to be serialized.
     * @param {Document} xmlDoc Document used to serialize the object.
     * @returns {Element} Serialized element.
     */
    _writeObjectXml(obj, xmlDoc) {
        CatalogDerivedTypes();
        
        const element = obj._writeXml(xmlDoc);
        return element;
    }
    
    /**
     * Write this SerialObject out to xml using the xml writer provided.
     * @type {Document} xmlDoc Document to which the SerialObject will be written to.
     * @returns {Element} Serialized element.
     */
    _writeXml(xmlDoc) {
        let name = this.getScopedTypeName(this);

        // create our element.
        const element = xmlDoc.createElement(name);
        
        // write our attributes
        const attributes = this._writeXmlAttributes(xmlDoc);
        attributes.forEach((value, name) => element.setAttribute(name, value));
        
        // write any nested elements.
        const elements = this._writeXmlElements(xmlDoc);
        elements.forEach(nestedElement => element.appendChild(nestedElement));
        
        return element;
    }
    
    /**
     * Objects that derive from SerialObject should implement this function to write any
     * xml attributes they need to serialize.
     * @type {Document} xmlDoc XML Document being used.
     * @returns {Map<string, any>} Attributes to apply.
     */
    _writeXmlAttributes(xmlDoc) {
        CatalogDerivedTypes();
        
        /**
         * @type {Map<string, any>}
         */
        const attributes = new Map();
        
        const serialProperties = h3dNamespace.getSerialValueAttributesForType(this);
        serialProperties.filter(p => !p.shouldSerializeAsElement).forEach(property => {
            if (this[property.name]) {
                const value = this[property.name].toString();
                const name = property.name.charAt(0).toUpperCase() + property.name.slice(1);
                
                attributes.set(name, value);
            }
        });
        
        return attributes;
    }
    
    /**
     * Objects that derive from SerialObject should implement this function to write any
     * child xml elements they need to serialize.
     * @returns {Array<Element>}
     */
    _writeXmlElements(xmlDoc) {
        CatalogDerivedTypes();
        
        /**
         * @type {Array<Element>}
         */
        const elements = [];
        
        const serialProperties = h3dNamespace.getSerialValueAttributesForType(this);
        serialProperties.filter(p => p.shouldSerializeAsElement).forEach(property => {
            if (this[property.name]) {
                const value = this[property.name];
                const name = property.name.charAt(0).toUpperCase() + property.name.slice(1);
                
                const element = xmlDoc.createElement(name);
                this._writeValue(value, element, xmlDoc);
                
                elements.push(element);
            }
        });
        
        return elements;
    }
    
    _writeValue(value, element, xmlDoc) {
        if (value instanceof SerialObject) {
            const subElement = this._writeObjectXml(value, xmlDoc);
            if (subElement) {
                element.appendChild(subElement);
            }
        }
        else {
            console.warn("The value requires additional _writeValue support that's not currently implemented. See original code.");
        }
    }
}

/**
 * This interface provides a means of getting and setting binary data associated with
 * SerialObjects.
 */
SerialObject.IBinaryData = class IBinaryData {
    /**
     * Get the binary data associated with the object.
     * @returns {ArrayBuffer}
     */
    get binaryData() {}
    
    /**
     * Sets the offset at which this objects binary data will be stored in the master
     * chunk of binary data in the file containing the SerialObjects.
     * @param {Number} offset An offset (in bytes) where the binary data will be located.
     */
    set binaryOffset(offset) {}
};

export {SerialObject};
