import React, { useRef, forwardRef, useEffect } from 'react';
import styled from 'styled-components';

const ProductDetails = styled.div`
  font-family: Univers;
  font-size: 26px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 16.9px;
  color: var(--color-gray1);
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-family: Univers;
  font-size: 16px;
  background-color: var(--color-gray5);
  color: var(--color-gray1);
  font-weight: 500;
`;

const ToolSummaryContainer = styled.div`
  margin-top: 20px;
`;

const ToolSummaryRowMobile = styled.div`
  padding: 10px 0px;
  font-family: Univers;
  font-size: 14px;
  word-break: break-word;
`;

const CategoryAndSpecsRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-family: Univers;
  font-size: 16px;
`;

const CategoryMobile = styled.div`
  font-weight: 600;
`;

const CategoryColumn = styled.div`
  width: 37%;
  margin-right: 18px;
  font-weight: 500;
  padding: ${(props) => (props.$isHeader ? '10px' : '15px 15px 15px 10px')};
`;

const SpecsColumn = styled.div`
  width: 63%;
  padding: ${(props) => (props.$isHeader ? '10px' : '15px 15px 15px 10px')};
  word-break: break-word;
`;

const ToolDescriptionExpanded = forwardRef((props, ref) => {
  const scrollableContentRef = useRef(null);

  useEffect(() => {
    if (scrollableContentRef.current) {
      scrollableContentRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, []);

  return (
    <div ref={ref}>
      <div ref={scrollableContentRef}></div>

      {props.classType !== 'compact' ? (
        <>
          <ProductDetails>Product Details</ProductDetails>
          <Header>
            <CategoryColumn $isHeader>Category</CategoryColumn>
            <SpecsColumn $isHeader>Specs</SpecsColumn>
          </Header>
          <ToolSummaryContainer>
            {props.toolDescription?.Properties?.map(
              (tool) =>
                tool.Name !== 'BDMI' && (
                  <React.Fragment key={`${tool.DisplayName + tool.Value}`}>
                    <CategoryAndSpecsRow>
                      <CategoryColumn>{tool.DisplayName}</CategoryColumn>
                      <SpecsColumn>{tool.Value || 'N/A'}</SpecsColumn>
                    </CategoryAndSpecsRow>
                  </React.Fragment>
                )
            )}
          </ToolSummaryContainer>
        </>
      ) : (
        <>
          {props.toolDescription?.Properties?.map(
            (tool) =>
              tool.Name !== 'BDMI' && (
                <ToolSummaryRowMobile key={tool}>
                  <CategoryMobile>{tool.DisplayName}</CategoryMobile>
                  <div>{tool.Value || 'N/A'}</div>
                </ToolSummaryRowMobile>
              )
          )}
        </>
      )}
    </div>
  );
});

export default ToolDescriptionExpanded;
ToolDescriptionExpanded.displayName = 'ToolDescriptionExpanded';
