import React, { useContext, useState, useEffect, useRef } from 'react';

import styled from 'styled-components';

import SearchBar from './SearchBar';
import ProductAndServiceCatalogMenu from './DockedPanelMenus/ProductAndServiceCatalogMenu';
import QuickQuoteMenu from './DockedPanelMenus/QuickQuoteMenu';
import MyAccountMenu from './DockedPanelMenus/MyAccountMenu';
import ShoppingCartMenu from './DockedPanelMenus/ShoppingCartMenu';

import MobileMenu from './DockedPanelMenus/MobileMenu';

import { ShoppingCart } from 'react-feather';
import { Menu } from 'react-feather';
import { ChevronDown } from 'react-feather';

import ApplicationContext from '../../ApplicationContext.js';
import { ErrorModalContext } from '../../Modals/ErrorModal';
import ErrorModal from '../../Modals/ErrorModal.jsx';

import DataFetchErrorPlaceholder from '../../shared/DataFetchErrorPlaceholder.jsx';

const PanelContainer = styled.div`
  font-family: Univers;
  width: 100%;
  height: 90px;
  background-color: var(--color-gray1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
    height: 110px;
    justify-content: start;
  }
`;

const MenuButton = styled.button`
  font-family: Univers;
  display: flex;
  align-items: center;
  color: var(--color-gray5);
  padding-left: ${(props) => (props.$largeLeftPadding ? '112px' : '0')};
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
`;

const TopTierContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
`;

const StyledTitle = styled.div`
  color: var(--color-gray5);
  font-size: 18px;
  font-weight: 500;
  text-align: center;
`;

const ShoppingCartElementContainer = styled.div`
  position: relative;
  display: flex;
`;

const ShoppingCartButton = styled.button`
  display: flex;
  align-items: center;
  flex-direction: row;
  background: none;
  border: none;
  cursor: pointer;
  padding-right: 112px;
  @media (max-width: 1024px) {
    padding: 0;
  }
`;

const ShoppingCartItemsNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2px;
  font-family: 'Univers';
  height: 20px;
  width: 20px;
  border-radius: 50%;
  color: white;
  background-color: var(--color-primary);
`;

const HamburgerMenuButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

const ProductAndServiceCatalogContainer = styled.div`
  position: relative;
`;

const MenuContainer = styled.div`
  position: relative;
`;

const DataFetchErrorPlaceholderContainer = styled.div`
  position: absolute;
  width: 868px;
  height: 331px;
  background: white;
  top: 45px;
  left: 20%;
  z-index: 3;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.2);
  // border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataFetchErrorPlaceholderCup = styled.div`
  position: absolute;
  top: 25px;
  left: 95%;
  transform: translate(-50%, 0);
  border: 10px solid white;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  border-bottom-color: white;
  width: 0;
  height: 0;
  margin: 0 auto;
`;

const DockedPanel = () => {
  const [, setError] = useContext(ErrorModalContext);

  const menuRef = useRef(null);
  const quickQuoteMenuRef = useRef(null);
  const myAccountMenuRef = useRef(null);
  const shoppingCartMenuRef = useRef(null);

  const {
    classType,
    shoppingCartItemsNumber,
    classesIsLoading,
    classesLoadingError,
  } = useContext(ApplicationContext);

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [
    showProductAndServiceCatalogMenu,
    setShowProductAndServiceCatalogMenu,
  ] = useState(false);
  const [showQuickQuoteMenu, setShowQuickQuoteMenu] = useState(false);
  const [showMyAccountMenu, setShowMyAccountMenu] = useState(false);
  const [showShoppingCartMenu, setShowShoppingCartMenu] = useState(false);

  let cachedClasses = JSON.parse(localStorage.getItem('fetchedClasses'));

  useEffect(() => {
    const handleClickOutsideProductAndServiceCatalogMenu = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowProductAndServiceCatalogMenu(false);
      }
    };
    document.addEventListener(
      'mousedown',
      handleClickOutsideProductAndServiceCatalogMenu
    );
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideProductAndServiceCatalogMenu
      );
    };
  }, []);

  useEffect(() => {
    const handleClickOutsideBulkQuote = (event) => {
      if (
        quickQuoteMenuRef.current &&
        !quickQuoteMenuRef.current.contains(event.target)
      ) {
        setShowQuickQuoteMenu(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutsideBulkQuote);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideBulkQuote);
    };
  }, []);

  useEffect(() => {
    const handleClickOutsideMyAccount = (event) => {
      if (
        myAccountMenuRef.current &&
        !myAccountMenuRef.current.contains(event.target)
      ) {
        setShowMyAccountMenu(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutsideMyAccount);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideMyAccount);
    };
  }, []);

  useEffect(() => {
    const handleClickOutsideShoppingCartMenu = (event) => {
      if (
        shoppingCartMenuRef.current &&
        !shoppingCartMenuRef.current.contains(event.target)
      ) {
        setShowShoppingCartMenu(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutsideShoppingCartMenu);
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideShoppingCartMenu
      );
    };
  }, []);

  return (
    <PanelContainer>
      <ErrorModal />

      {classType !== 'compact' ? (
        <>
          <ProductAndServiceCatalogContainer ref={menuRef}>
            <MenuButton
              $largeLeftPadding
              disabled={!cachedClasses && classesIsLoading}
              onClick={() =>
                setShowProductAndServiceCatalogMenu((prev) => !prev)
              }
            >
              Product and Service Catalog
              <ChevronDown />
            </MenuButton>
            {showProductAndServiceCatalogMenu &&
              !classesIsLoading &&
              (cachedClasses ? (
                <ProductAndServiceCatalogMenu
                  closeProductAndServiceCatalogMenu={() =>
                    setShowProductAndServiceCatalogMenu(false)
                  }
                />
              ) : (
                <>
                  <DataFetchErrorPlaceholderCup />
                  <DataFetchErrorPlaceholderContainer>
                    <DataFetchErrorPlaceholder />
                  </DataFetchErrorPlaceholderContainer>
                </>
              ))}
          </ProductAndServiceCatalogContainer>
          <SearchBar />
          <MenuContainer ref={quickQuoteMenuRef}>
            <MenuButton onClick={() => setShowQuickQuoteMenu((prev) => !prev)}>
              Quick Request <ChevronDown />{' '}
            </MenuButton>
            {showQuickQuoteMenu && <QuickQuoteMenu />}
          </MenuContainer>

          <MenuContainer ref={myAccountMenuRef}>
            <MenuButton onClick={() => setShowMyAccountMenu((prev) => !prev)}>
              My Account <ChevronDown />
            </MenuButton>
            {showMyAccountMenu && (
              <MyAccountMenu closeMenu={() => setShowMyAccountMenu(false)} />
            )}
          </MenuContainer>

          <ShoppingCartElementContainer ref={shoppingCartMenuRef}>
            <ShoppingCartButton
              aria-label="quick view of shopping cart"
              alt="quick view of shopping cart"
              onClick={() => setShowShoppingCartMenu((prev) => !prev)}
            >
              <ShoppingCart color={'var(--color-gray5)'} />{' '}
              {shoppingCartItemsNumber !== 0 && (
                <ShoppingCartItemsNumber>
                  {shoppingCartItemsNumber}
                </ShoppingCartItemsNumber>
              )}
            </ShoppingCartButton>
            {showShoppingCartMenu && (
              <ShoppingCartMenu
                closeMenu={() => setShowShoppingCartMenu(false)}
              />
            )}
          </ShoppingCartElementContainer>
        </>
      ) : (
        <>
          <TopTierContainer>
            <HamburgerMenuButton
              aria-label="open mobile menu"
              alt="open menu"
              onClick={() => setShowMobileMenu(true)}
            >
              {' '}
              <Menu color={'var(--color-gray5)'} size={28} />{' '}
            </HamburgerMenuButton>
            <StyledTitle>Product and Service Catalog</StyledTitle>
            <div ref={shoppingCartMenuRef}>
              <ShoppingCartButton
                aria-label="quick view of shopping cart"
                alt="navigate to shopping cart"
                onClick={() => setShowShoppingCartMenu((prev) => !prev)}
              >
                {' '}
                <ShoppingCart color={'var(--color-gray5)'} />{' '}
                {shoppingCartItemsNumber !== 0 && (
                  <ShoppingCartItemsNumber>
                    {shoppingCartItemsNumber}
                  </ShoppingCartItemsNumber>
                )}
              </ShoppingCartButton>
              {showShoppingCartMenu && (
                <ShoppingCartMenu
                  closeMenu={() => setShowShoppingCartMenu(false)}
                />
              )}
            </div>
          </TopTierContainer>
          <SearchBar />
          {showMobileMenu && (
            <MobileMenu handleShowMobileMenu={setShowMobileMenu} />
          )}
        </>
      )}
    </PanelContainer>
  );
};

export default DockedPanel;
