import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import SearchContext from '../../SearchContext';

const MainContainer = styled.div`
  border-left: 0.7px solid var(--color-gray3);
  margin-bottom: 4px;
  max-height: 200px;
  overflow-x: auto;
  overflow: -moz-scrollbars-vertical;
  position: relative;
  font-family: Univers;
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-left: ${(props) => props.$marginLeft};

  @media (max-width: 1024px) {
    margin-right: 12px;
    margin-left: ${(props) => props.$marginLeft};
    max-height: 200px;
    border-left: none;
    overflow-x: auto;
    overflow: -moz-scrollbars-vertical;
  }
`;

const OptionsRow = styled.button`
  font-family: Univers;
  appearance: none;
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  padding: ${(props) =>
    props.$isRowSelected ? '10px 10px 10px 7px' : '10px 10px 10px 35px'};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: var(--color-gray1);
  font-size: 16px;
  cursor: pointer;
  &:hover {
    color: var(--color-primary);
    text-decoration: underline;
  }

  @media (max-width: 1024px) {
    padding: 10px 10px 10px 7px;
  }
`;

const Option = styled.div`
  word-break: break-word;
  display: flex;
  align-items: center;
`;

const storeLeafClasses = (node) => {
  const leafClasses = [];

  const traverse = (currentNode) => {
    if (currentNode._leafClass) {
      leafClasses.push(currentNode._leafClass);
    }
    for (const key in currentNode) {
      if (key !== '_leafClass') {
        traverse(currentNode[key]);
      }
    }
  };

  traverse(node);
  return leafClasses;
};

const CategoryTree = ({
  isLeaf,
  level,
  data,
  selectedOption,
  options,
  handleOptionChange,
}) => {
  const { onLeafClassChange, onSelectClass, setIsLeaf } =
    useContext(SearchContext);
  const [leafClassesByLevel, setLeafClassesByLevel] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);

  let cachedClasses = JSON.parse(localStorage.getItem('fetchedClasses'));

  const currentLevelLeafClasses = storeLeafClasses(data);

  const marginLeft = `${level * 10}px`;

  useEffect(() => {
    setIsLeaf(isLeaf);

    const matchedId = cachedClasses.find(
      (className) => className.Name === selectedOption
    )?.Id;
    if (selectedOption && matchedId !== undefined) {
      onSelectClass(matchedId);
    }
  }, [selectedOption, isLeaf]);

  useEffect(() => {
    if (leafClassesByLevel) {
      onLeafClassChange(leafClassesByLevel?.filter(Boolean));
    }
  }, [leafClassesByLevel, onLeafClassChange]);

  useEffect(() => {
    setSelectedValue(selectedOption);
  }, [selectedOption]);

  if (!leafClassesByLevel[level]) {
    setLeafClassesByLevel((prevLeafClasses) => {
      const updatedLeafClasses = [...prevLeafClasses];
      updatedLeafClasses[level] = currentLevelLeafClasses;
      return updatedLeafClasses;
    });
  }

  const onSelect = (level, value) => {
    setSelectedValue(value);
    handleOptionChange(level, value);
  };

  const renderOptions = () => {
    const defaultOptions = options.filter(
      (option) => !selectedValue || option === selectedValue
    );

    defaultOptions.sort((a, b) => {
      const toolCountA = data[a]?._toolCount || 0;
      const toolCountB = data[b]?._toolCount || 0;
      return toolCountB - toolCountA;
    });

    return defaultOptions.map((option) => {
      const isRowSelected = selectedValue === option;
      const toolCount = data[option]?._toolCount || 0;

      if (toolCount === 0) return null;

      return (
        <OptionsRow
          key={option}
          value={option}
          onClick={() => onSelect(level, option)}
          $isRowSelected={isRowSelected ? 'selected' : ''}
          $isSelected={isRowSelected}
        >
          <Option>
            <div
              style={
                !isRowSelected
                  ? { display: 'inline-block' }
                  : { display: 'inline-block', width: '100%' }
              }
            >
              {option} {`[${toolCount}]`}
            </div>
          </Option>
        </OptionsRow>
      );
    });
  };

  return (
    <>
      <MainContainer $marginLeft={marginLeft}>{renderOptions()}</MainContainer>
    </>
  );
};

export default CategoryTree;
