import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';

import { ErrorModalProvider } from './Modals/ErrorModal';

import App from './app';

const container = document.getElementById('root');
const root = createRoot(container);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ErrorModalProvider>
        <App />
      </ErrorModalProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
