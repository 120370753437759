import {SerialNode} from "./../../serialization/serialnode.js";

class ShaderCodeBase extends SerialNode {
    constructor() {
        super();
        
        /**
         * @type {string}
         */
        this._code = "";
        
        /**
         * Number of lines in the code.
         * @type {Number}
         */
        this.lineCount = 0;
        
        /**
         * @type {Guid}
         */
        this.id = null;    // TODO: if needed this should be set to a valid value like in the original code (Billy 11-24-2020)
        
        /**
         * @type {Set<ShaderProgram.CompilationMessage>}
         */
        this._compilationMessages = new Set();
    }
    
    get code() {
        return this._code;
    }
    
    set code(value) {
        this._code = value;
        
        if (!this._code || this._code == "") {
            this.lineCount = 0;
        }
        else {
            this.lineCount = this._code.split("\n").length;
        }
    }
    
    get assetInfo() {}
    
    /**
     * @param {IAssetInfo} value
     */
    set assetInfo(value) {}
    
    get compliationMessages() {
        return this._compilationMessages;
    }
    
    set compliationMessages(value) {
        console.error("Not implemented from original.");
    }
    
    validateDataFormat(data) {
        throw new Error("Not implemented.");
    }
    
    dispose() {}
    
    /**
     * Saves this shader code to xml document provided.
     */
    save(xmlDoc) {
        console.error("Not implemented from original.");
    }
    
    clone(mapping, includeAllChildren) {
        console.error("Not implemented from original.");
    }
    
    _readXmlAttributes(data, element, parent) {
        console.error("Not implemented from original.");
    }
    
    _writeXmlAttributes(xmlDoc) {
        const attributes = super._writeXmlAttributes(xmlDoc);
        
        console.error("CDATA is not an attribute in the traditional sense, need to rethink this and return the CDATA properly (Billy 11-24-2020).");
        
        const cdata = xmlDoc.createCDATASection(this._code);
        
        return attributes;
    }
    
    /**
     * @param {SerialObject} destination
     */
    _copyValuesToObject(destination) {
        super._copyValuesToObject(destination);
        
        if (destination) {
            destination.code = this._code;
        }
    }
}

/**
 * @param {Stream} stream
 * @returns {ShaderCodeBase}
 */
ShaderCodeBase.load = function(stream) {
    console.error("Not implemented from original.");
    return null;
}

export {ShaderCodeBase};
