import {SerialObject} from "./serialobject.js";
import * as SerialValue from "./serialvalueattribute.js";

/**
 * This class provides the framework for serial object hierarchies.
 */
class SerialNode extends SerialObject {
    constructor() {
        super();
        
        this.name = "";

        /**
         * @type {SceneNode}
         */
        this.parent = null;

        /**
         * @type {Array<SceneNode>}
         */
        this.children = [];
    }
    
    getSerialValuesAttributes() {
        const attributes = [
            new SerialValue.Attribute("name")
        ];
        
        return attributes;
    }

    /**
     * Returns whether the node is an ancestor if this node or not.
     * @param {SerialNode} node Node to check.
     * @returns {Boolean} True if the node is an ancestor, false otherwise.
     */
    isAncestor(node) {
        const result = Array.from(this.ancestors).includes(node);
        return result;
    }

    /**
     * Returns the first ancestor of the matching type.
     * @param {ObjectConstructor|string} type Type of ancestor to get.
     * @returns {SerialNode} Matching ancestor.
     */
    getAncestor(type) {
        let result = null;

        for (const ancestor of this.ancestors()) {
            if (typeof type == "string" || type instanceof String) {
                if (typeof ancestor == type) {
                    result = ancestor;
                    break;
                }
            }
            else if (ancestor instanceof type) {
                result = ancestor;
                break;
            }
        }

        return result;
    }

    /**
     * Adds the specified node to be a child of this node.
     * @param {SceneNode} node Node to be added as a child.
     */
    addChild(node) {
        if (node.parent != null) {
            throw new Error("Cannot add a node as a child, that already has a parent.");
        }

        node.parent = this;
        this.children.push(node);

        this._onNewChild(node, null);
    }

    /**
     * Inserts the specified node sequentially after the specified node.
     * If the `after` node is null, then the node will be added to the front of the other child nodes.
     * @param {SceneNode} node Node to be added as a child.
     * @param {SceneNode} after Another child node to that the node will be sequentially added after.
     */
    insertChild(node, after) {
        if (node.parent != null) {
            throw new Error("Cannot add a node as a child, that already has a parent.");
        }

        if (after) {
            const index = this.children.indexOf(after);
            if (index != -1) {
                this.children.splice(index, 0, node);
            }
            else {
                throw new Error("Cannot add insert a node after a node that is not a child of this node.");
            }
        }
        else {
            this.children.unshift(node);
        }

        node.parent = this;

        this._onNewChild(node, after);
    }

    /**
     * @param {SerialNode} child 
     */
    removeChild(node) {
        if(!node || !(node instanceof SerialNode)) {
            throw new Error("Cannot remove invalid child");
        }

        if(node.parent === this) {
            node.removeFromParent();
            this._onRemoveChild(node);
        }
    }

    /**
     * Removes this node from being a child of it's parent node.
     */
    removeFromParent() {
        if (this.parent != null) {
            const index = this.parent.children.indexOf(this);
            if (index != -1) {
                this.parent.children.splice(index, 1);

                const parent = this.parent;
                this.parent = null;

                parent._onRemoveChild(this);
            }
        }
    }

    /**
     * Searches for the first node that matches the search criteria.
     * @param {constructor|string} type Constructor or string defining type to match against.
     * @param {string} name Name to match against.
     * @param {Boolean} recurse True if the search the children's children (..etc) should be searched, false otherwise.
     * @param {Boolean} includeSelf True if this node should be included in the search, false otherwise.
     * @returns {SerialNode} Matching node.
     */
    find(type, name, recurse, includeSelf) {
        let result = null;

        if (includeSelf && this._nodeMatchesNameAndType(this, name, type)) {
            result = this;
        }

        if (!result) {
            if (recurse) {
                for (const node of this.depthFirst()) {
                    if ((node != this || includeSelf) && this._nodeMatchesNameAndType(node, name, type)) {
                        result = node;
                        break;
                    }
                }
            }
            else {
                for (const child of this.children) {
                    if (this._nodeMatchesNameAndType(child, name, type)) {
                        result = child;
                        break;
                    }
                }
            }
        }

        return result;
    }

    /**
     * Returns a generator for children that match the specified type.
     * @param {constructor|string} type Constructor or string defining type of child to return.
     */
    * getChildren(type) {
        if (typeof type == "string" || type instanceof String) {
            for (const child of this.children) {
                if (typeof child == type) {
                    yield child;
                }
            }
            // this.children.forEach(child => {
            //     if (typeof child == type) {
            //         yield child;
            //     }
            // });
        }
        else {
            for (const child of this.children) {
                if (child instanceof type) {
                    yield child;
                }
            }
            // this.childNode.forEach(child => {
            //     if (child instanceof type) {
            //         yield child;
            //     }
            // });
        }
    }

    /**
     * Returns nodes of the specified type down this node's hierarchy.
     * @param {ObjectConstructor|string} type Constructor or string defining type of nodes to get.
     * @returns {Array<any>} Nodes of the specified type.
     */
    getNodes(type) {
        const nodes = Array.from(this.depthFirst()).filter(node => {
            let isValid = false;

            if (typeof type == "string" || type instanceof String) {
                if (typeof node == type) {
                    isValid = true;
                }
            }
            else if (node instanceof type) {
                isValid = true;
            }

            return isValid;
        });

        return nodes;
    }

    /**
     * Called when a child is added.
     * @param {SerialNode} child Node added as a child.
     * @param {SerialNode} after Node that it was added after, or null.
     */
    _onNewChild(child, after) {
        // intentionally blank.
    }

    /**
     * Called when a child is removed.
     * @param {SerialNode} child Node that was removed from being a child.
     */
    _onRemoveChild(child) {
        // intentionally blank.
    }

    /**
     * Returns true if the specified nodes matches the criteria or false otherwise.
     * @param {SerialNode} node Node to be checked.
     * @param {string} name Name to match against.
     * @param {constructor|string} type Constructor or string defining type to match against.
     * @returns {Boolean} True if the node matches the criteria, false otherwise.
     */
    _nodeMatchesNameAndType(node, name, type) {
        let matchesType;

        if (typeof type != "string") {
            matchesType = typeof node === type;
        }
        else {
            matchesType = node instanceof type;
        }

        return matchesType && (!name || node.name == name);
    }

    /**
     * Returns an ancestors generator.
     */
    * ancestors() {
        let curNode = this;

        while (curNode) {
            curNode = curNode.parent;
            yield curNode;
        }

        return curNode;
    }

    /**
     * Returns a depth-first generator.
     */
    * depthFirst() {
        const iterationStack = [{node: this, index: 0, visitedRoot: false}];

        while (iterationStack.length > 0) {
            const entry = iterationStack[iterationStack.length - 1];

            if (entry.index < entry.node.children.length) {
                const nextNode = entry.node.children[entry.index];
                entry.index += 1;

                iterationStack.push({node: nextNode, index: 0, visitedRoot: false});
            }
            else if (!entry.visitedRoot) {
                entry.visitedRoot = true;
                yield entry.node;
            }
            else {
                iterationStack.pop();

                // unwind until we hit a valid node or we run out of nodes.
                while (iterationStack.length > 0) {
                    const lastEntry = iterationStack[iterationStack.length - 1];
                    if (lastEntry.node || lastEntry.index < lastEntry.node.children.length) {
                        break;
                    }
                    else {
                        iterationStack.pop();
                    }
                }
            }
        }
    }

    /**
     * Returns a breadth-first generator.
     */
    * breadthFirst() {
        /**
         * @type {Array<Node>}
         */
        const iterationStack = [this];

        while (iterationStack.length > 0) {
            const curNode = iterationStack[0];
            yield curNode;

            curNode.children.forEach(childNode => iterationStack.push(childNode));
            iterationStack.shift();
        }
    }
    
    _writeXmlElements(xmlDoc) {
        const elements = super._writeXmlElements(xmlDoc);
        
        // CHANGE: this was original CloneableChildren, but was just returning the children,
        // so we're just going to directly iterate off the children here.
        
        this.children.forEach(child => {
            const element = this._writeObjectXml(child, xmlDoc);
            elements.push(element);
        });
        
        return elements;
    }
}

export { SerialNode };
