import ModelViewer from './model-preview/modelviewer';
import React from 'react';
import styled from 'styled-components';
import * as dom from './model-preview/dom';
import { Session } from './model-preview/session';

const PreviewControlsContainer = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 8px;
`;

const PreviewControlButton = styled.button`
  background: none;
  border: none;
  padding: 14px;
  min-height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PreviewCutButtonsContainer = styled.div.attrs((props) => ({
  style: {
    transform: `rotate(${-props.$rotation || 0}deg)`,
  },
}))`
  width: 58px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const PreviewCutButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 2px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus-visible {
    outline: none;
  }
`;

const PreviewResetButton = styled.button`
  background: none;
  border: none;
  color: white;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  font-family: 'Univers';
  font-size: 16px;
`;

export default class IconView extends React.Component {
  static sortedCameraTypesOptionsArr = null;

  constructor(props) {
    super(props);

    this.divRef = React.createRef();
    this.session = null;

    this.state = {
      toolRotation: 0,
      cutQuadrants: 0,
    };

    this.cutButtons = [
      {
        icon: '/assets/media/icon-preview/cut-top-left.svg',
        activeIcon: '/assets/media/icon-preview/cut-top-left-active.svg',
        quadrant: Session.CutQuadrant.TOP_LEFT,
        alt: 'top left',
      },
      {
        icon: '/assets/media/icon-preview/cut-top-right.svg',
        activeIcon: '/assets/media/icon-preview/cut-top-right-active.svg',
        quadrant: Session.CutQuadrant.TOP_RIGHT,
        alt: 'top right',
      },
      {
        icon: '/assets/media/icon-preview/cut-bottom-left.svg',
        activeIcon: '/assets/media/icon-preview/cut-bottom-left-active.svg',
        quadrant: Session.CutQuadrant.BOTTOM_LEFT,
        alt: 'bottom left',
      },
      {
        icon: '/assets/media/icon-preview/cut-bottom-right.svg',
        activeIcon: '/assets/media/icon-preview/cut-bottom-right-active.svg',
        quadrant: Session.CutQuadrant.BOTTOM_RIGHT,
        alt: 'bottom right',
      },
    ];
  }

  static {
    IconView.sortedCameraTypesOptionsArr = Object.entries(
      ModelViewer.CameraType
    )
      .sort(([, a], [, b]) => a - b)
      .map(([a, b]) => {
        return (
          <option value={b} key={b}>
            {a.toLowerCase()}
          </option>
        );
      });
  }

  sessionEventHandler(targetSession, eventType, eventData) {
    switch (eventType) {
      case Session.Event.READY: {
        // Raven.hideLoadingDialog();
        dom.animate.transition(this.divRef.current, '-hidden');
        break;
      }
      case Session.Event.FINISHED: {
        dom.animate.animation(this.divRef.current, 'close', false).then(() => {
          //this.props.onIconNotFound(this.props.iconUrl);
        });
        break;
      }
      case Session.Event.ROTATED_TOOL: {
        this.setState({
          ...this.state,
          toolRotation: eventData,
        });
        break;
      }

      case Session.Event.ERROR: {
        console.error(`Model viewer error: ${eventData}`);

        // might look for a way to see if the loading dialog is showing rather then just assuming
        // it might be and dismissing it.

        if (typeof this.props.onIconNotFound === 'function') {
          this.props.onIconNotFound(eventData);
        }
        break;
      }

      default: {
        console.warn(`Received an unsupported Session.Event: '${eventType}'.`);
        break;
      }
    }
  }

  toggleCut(quadrant) {
    let { cutQuadrants } = this.state;

    if ((cutQuadrants & quadrant) !== 0) {
      cutQuadrants &= ~quadrant;
    } else {
      cutQuadrants |= quadrant;
    }

    if (cutQuadrants === Session.CutQuadrant.ALL) {
      cutQuadrants = Session.CutQuadrant.NONE;
    }

    this.session?.applyCut(cutQuadrants);

    this.setState({
      ...this.state,
      cutQuadrants,
    });
  }

  onZoomOutClick() {
    this.session?.zoomCameraOut();
  }

  onZoomInClick() {
    this.session?.zoomCameraIn();
  }

  onResetClick() {
    this.session?.resetCamera();

    this.setState({
      toolRotation: 0,
      cutQuadrants: 0,
    });
  }

  renderCutIcons() {
    const { cutQuadrants } = this.state;
    let isBtnActive;
    return (
      <PreviewCutButtonsContainer
        className="cut-buttons-container"
        $rotation={this.state.toolRotation}
      >
        {this.cutButtons.map((btn) => {
          isBtnActive = (cutQuadrants & btn.quadrant) !== 0;
          return (
            <PreviewCutButton
              key={btn.quadrant}
              type="button"
              onClick={() => this.toggleCut(btn.quadrant)}
            >
              <img
                src={isBtnActive ? btn.activeIcon : btn.icon}
                alt={`Cut tool preview quadrant ${btn.alt}`}
              />
            </PreviewCutButton>
          );
        })}
      </PreviewCutButtonsContainer>
    );
  }

  render() {
    return (
      <div className="preview-panel hidden" ref={this.divRef}>
        <div className="preview">
          <PreviewControlsContainer>
            {this.renderCutIcons()}
            <PreviewControlButton
              type="button"
              onClick={this.onZoomOutClick.bind(this)}
            >
              <img
                src="/assets/media/icon-preview/zoom-out.svg"
                alt="Zoom out tool preview"
              />
            </PreviewControlButton>
            <PreviewControlButton
              type="button"
              onClick={this.onZoomInClick.bind(this)}
            >
              <img
                src="/assets/media/icon-preview/zoom-in.svg"
                alt="Zoom in tool preview"
              />
            </PreviewControlButton>
          </PreviewControlsContainer>
          <PreviewResetButton
            type="button"
            onClick={this.onResetClick.bind(this)}
          >
            <p>Reset</p>
          </PreviewResetButton>
        </div>
      </div>
    );
  }

  componentDidMount() {
    if (this.session) {
      console.warn('IconView componented mounted twice');
      // this.session.dispose()
      // this.session = null;
      return;
    }

    const mainDiv = this.divRef.current;
    this.session = new Session(mainDiv);
    this.session.onEvent = this.sessionEventHandler.bind(this);
    this.session.loadUrl(this.props.iconUrl);
  }

  componentWillUnmount() {
    this.session?.dispose();
    this.session = null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.toolRotation !== nextState.toolRotation ||
      this.state.cutQuadrants !== nextState.cutQuadrants
    );
  }
}
