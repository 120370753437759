/* 
Use this modal to show an error message to the user.

Usage: 
Add this to your component: const [error, setError] = useContext(ErrorModalContext);
Then call setError({title: 'Error Title', message: 'Error Message'}) to show the modal.
*/

import React, { createContext, useState, useContext } from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';

export const ErrorModalContext = createContext();

export const ErrorModalProvider = (props) => {
  const [error, setError] = useState(null);

  return (
    <ErrorModalContext.Provider value={[error, setError]}>
      {props.children}
    </ErrorModalContext.Provider>
  );
};

const modalStyle = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    zIndex: '1000',
  },
  content: {
    padding: '0px',
    margin: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none',
    border: 'none',
    background: 'none',
  },
};

const ModalContainer = styled.div`
  font-family: Univers;
  width: 350px;
  height: ${(props) => (props.$errorCode ? '250px' : '150px')};
  padding: 32px;
  // border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px var(--color-gray1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ModalTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--color-gray1);
`;

const ModalMessage = styled.div`
  font-size: 16px;
  font-weight: 300;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: var(--color-gray1);
`;

const ModalErrorCode = styled.div`
  font-weight: 400;
  line-height: 1.6;
  text-align: center;
  color: var(--color-gray1);
`;

const ModalButton = styled.button`
  font-family: Univers;
  height: 45px;
  background-color: #e5e9ed;
  border-top: unset;
  border-right: unset;
  border-left: unset;
  border-image: unset;
  border-bottom: 1px solid var(--color-gray1);
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  color: var(--color-gray1);
`;

const ErrorModal = () => {
  ReactModal.setAppElement('#root');
  const [error, setError] = useContext(ErrorModalContext);

  return (
    <ReactModal
      isOpen={!!error}
      onRequestClose={() => setError(null)}
      contentLabel="Error Modal"
      shouldCloseOnOverlayClick={false}
      style={modalStyle}
    >
      <ModalContainer $errorCode={error?.errorCode}>
        <ModalTitle>{error?.title ?? 'No Error'}</ModalTitle>
        <ModalMessage>{error?.message ?? 'No Message'}</ModalMessage>
        <ModalErrorCode>{error?.errorCode}</ModalErrorCode>
        <ModalButton
          onClick={() => setError(null)}
          aria-label="dismiss-error-modal"
        >
          OK
        </ModalButton>
        {error?.errorCode && (
          <ModalButton
            onClick={() => {
              const supportUrl =
                'https://teams.microsoft.com/l/app/f6405520-7907-4464-8f6e-9889e2fb7d8f?templateInstanceId=ad83a41e-b807-4895-b560-6cc2f28afe9b&environment=18a32d73-e11a-472c-b0ad-6aa712bcf920';
              window.open(supportUrl, '_blank');
            }}
          >
            Contact Support
          </ModalButton>
        )}
      </ModalContainer>
    </ReactModal>
  );
};

export default ErrorModal;
