import * as SerialValue from "./../serialization/serialvalueattribute.js";
import {TargetedNode} from "./targetednode.js";

class ValueNode extends TargetedNode {
    constructor() {
        super();

        this.propertyName = "";

        /**
         * @type {ValueNode.Transition}
         */
        this.transitionIn = ValueNode.Transition.BLEND;

        /**
         * @type {ValueNode.Transition}
         */
        this.transitionOut = ValueNode.Transition.USE_KEYFRAME;

        /**
         * @type {Number}
         */
        this._endDelay = 0;

        /**
         * @type {Array<ValueNode.Keyframe>}
         */
        this._sortedKeyFrames = [];
    }
    
    /**
     * Returns the value type of the keyframe.
     * @returns {constructor} Keyframe's value type.
     */
    get keyframeValueType() {
        return null;
    }

    get endDelay() {
        return this._endDelay;
    }

    set endDelay(value) {
        this._endDelay = Math.max(value, 0);
    }

    get finiteDuration() {
        let duration = 0;
        let keyframeCount = 0;

        for (const keyframe of this.getChildren(ValueNode.Keyframe)) {
            duration = Math.max(duration, keyframe.time);
            keyframeCount += 1;
        }

        if (keyframeCount == 0) {
            // add the transition out duration
            duration += this.endDelay;
        }

        return duration;
    }
    
    getSerialValuesAttributes() {
        const attributes = [
            new SerialValue.Attribute("endDelay"),
            new SerialValue.Attribute("propertyName"),
            new SerialValue.Attribute("transitionIn"),
            new SerialValue.Attribute("transitionOut")
        ];
        
        return attributes;
    }

    reverse() {
        let duration = this.finiteDuration;

        /**
         * @type {Array<ValueNode.Keyframe>}
         */
        const keyframes = Array.from(this.getChildren(ValueNode.Keyframe));

        let earliest = Number.MAX_SAFE_INTEGER;

        keyframes.forEach(keyframe => {
            earliest = Math.min(keyframe.time, earliest);

            keyframe.time = duration - keyframe.time;
            keyframe.reverse();
        });

        // if we had keyframes, set our end delay to the earliest time among them.
        if (keyframes.length != 0) {
            this.endDelay = earliest;
        }

        // swap the transition nodes
        const swap = this.transitionIn;
        this.transitionIn = this.transitionOut;
        this.transitionOut = swap;

        // HACK: Do this for now to deal with the fact that we let value anims linger after their time is up (from original)
        if (this.transitionIn == ValueNode.Transition.USE_KEYFRAME && this.startDelay != 0) {
            // offset the keyframes by teh current start delay
            keyframes.forEach(keyframe => keyframe.time += this.startDelay);

            this.startDelay = 0;
        }

        // HACK: Do this for now to deal with the fact that we let value anims linger after their time is up (from original)
        if (this.transitionOut == ValueNode.Transition.USE_KEYFRAME && this.endDelay != 0) {
            this.endDelay = 0;
        }
    }

    /**
     * @returns {ValueNode.Keyframe}
     */
    createKeyframe() {
        return new ValueNode.Keyframe();
    }

    /**
     * Adds the specified keyframe as a child.
     * @param {ValueNode.Keyframe} keyframe Keyframe to add.
     */
    addKeyframe(keyframe) {
        let previous = this.getLastKeyframeBeforeTime(keyframe.time);

        if (previous == keyframe) {
            previous = null;
        }

        this.insertChild(keyframe, previous);
    }

    /**
     * Removes the specified keyframe from being a child.
     * @param {ValueNode.Keyframe} keyframe Keyframe to remove from children.
     */
    removeKeyframe(keyframe) {
        this.removeChild(keyframe);
    }

    /**
     * Compares the times of two keyframes.
     * @param {ValueNode.Keyframe} left Keyframe to compare.
     * @param {ValueNode.Keyframe} right Another keyframe to compare against.
     * @returns {Number} -1 if left is less than right, 0 if they are equal, and 1 if left is greater than the right.
     */
    compare(left, right) {
        /**
         * @type {Number}
         */
        let result;

        if (left.time < right.time) {
            result = -1;
        }
        else if(left.time == right.time) {
            result = 0;
        }
        else {
            result = 1;
        }

        return result;
    }

    /**
     * Returns the last keyframe with time before the specified time.
     * @param {Number} time Keyframe time.
     * @param {Array<ValueNode.Keyframe>} excluding Collection of child keyframes to exclude.
     * @returns {ValueNode.Keyframe} Keyframe if it exists or null.
     */
    getLastKeyframeBeforeTime(time, excluding = []) {
        const excludeSet = new Set(excluding);
        const matches = this._sortedKeyFrames.filter(keyframe => keyframe.time <= time && !excludeSet.has(keyframe));
        const match = matches.length > 0 ? matches[matches.length - 1] : null;

        return match;
    }

    /**
     * Interpolates between the starting and ending value.
     * @param {any} startValue Starting value.
     * @param {ValueNode.InterpolationData} startData Interpolation data for the starting value.
     * @param {any} endValue Ending value.
     * @param {ValueNode.InterpolationData} endData Interpolation data for the ending node.
     * @param {Number} progress Value from 0.0-1.0 defining the progress of the interpolation.
     * @returns {any} Result of the interpolation.
     */
    interpolateValue(startValue, startData, endValue, endData, progress) {
        throw new Error("Not currently implemented.");
    }

    _onNewChild(child, after) {
        // NOTE: I have no idea this is trying to do. It doesn't seem to sort the
        // keyframes (Billy 11-5-2020).

        super._onNewChild(child, after);

        // make sure the keyframes are always in order.
        if (child) {
            let index = this._sortedKeyFrames.indexOf(child);

            // it may be negative to indicate that it isn't there.
            if (index == -1) {
                index = ~index;
            }

            // get the frame previous to this one
            const previous = index != 0 ? this._sortedKeyFrames[index - 1] : null;

            // if they don't match up, insert the keyframe properly (this doesn't make sense)
            if (previous != after) {
                this.addChild(child, previous);
                return;
            }

            // insert it at the proper index
            this._sortedKeyFrames.splice(index, 0, child);
        }
    }

    _onRemoveChild(child) {
        super._onRemoveChild(child);

        if (child) {
            const index = this._sortedKeyFrames.indexOf(child);
            if (index != -1) {
                this._sortedKeyFrames.splice(index, 1);
            }
        }
    }
}
/**
 * @enum {Number}
 */
ValueNode.Transition = Object.freeze({
    USE_UNDERLYING: 1,
    BLEND: 2,
    USE_KEYFRAME: 3
});

ValueNode.Keyframe = class Keyframe {
    constructor() {
        /**
         * @type {Object}
         */
        this.value = null;

        /**
         * TODO: not implemented
         * @type {Array<null>}
         */
        this.interpolationData = [];

        /**
         * @type {Number}
         */
        this._time = 0;
    }

    get time() {
        return this._time;
    }

    set time(value) {
        if (this._time != value) {
            // since the keyframes are only sorted on insertion, we need to remove
            // ourselves and then add ourselves again.

            if (this.parent) {
                this.parent.removeKeyframe(this);
            }

            this._time = value;

            if (this.parent) {
                this.parent.addKeyframe(this);
            }
        }
    }

    get leftInterpolationData() {
        return this.interpolationData[ValueNode.Instance.Direction.LEFT];
    }

    set leftInterpolationData(value) {
        this.interpolationData[ValueNode.Instance.Direction.LEFT] = value;
        if (value) {
            value.keyframe = this;
        }
    }

    get rightInterpolationData() {
        return this.interpolationData[ValueNode.Instance.Direction.RIGHT];
    }

    set rightInterpolationData(value) {
        this.interpolationData[ValueNode.Instance.Direction.RIGHT] = value;
        if (value) {
            value.keyframe = this;
        }
    }

    /**
     * @return {Boolean}
     */
    get hasAutoTangents() {
        let result = false;

        const directions = Object.keys(ValueNode.Instance.Direction);
        for (const direction of directions) {
            if (this.interpolationData[direction] && this.interpolationData[direction].hasAutoTangents) {
                result = true;
                break;
            }
        }

        return result;
    }

    /**
     * @type {Boolean}
     */
    get isNumerical() {
        throw new Error("Not currently implemented.");
    }
    
    getSerialValuesAttributes() {
        const attributes = [
            new SerialValue.Attribute("time")
        ];
        
        return attributes;
    }

    /**
     * 
     * @param {Number} index Index to check for.
     * @returns {Boolean}
     */
    hasValueForIndex(index) {
        return true;
    }

    /**
     * Returns a clone of the value.
     * @returns {Any} Clone of the value.
     */
    cloneValue() {
        throw new Error("Not currently implemented.");
    }

    reverse() {
        throw new Error("Not currently implemented.");
    }

    // TODO: finish the remaining methods if we need them (Billy 11-5-2020).
};

ValueNode.Keyframe.Direction = Object.freeze({
    LEFT: 0,
    RIGHT: 1
});

ValueNode.Keyframe.InterpolationDataName = Object.freeze([
    "LeftInterpolationData",
    "RightInterpolationData"
]);

export {ValueNode};
