import React from 'react';
import styled from 'styled-components';

import { ChevronRight } from 'react-feather';

const Button = styled.button`
  background: none;
  border: none;
  height: 0;
  display: flex;
  flex-grow: 1;
  min-width: 0;
  max-width: fit-content;
  align-items: center;
  font-family: Univers;
  font-size: 14px;
  text-align: left;
  color: var(--color-primary);
  margin-left: -15px;
  cursor: pointer;

  @media (max-width: 900px) {
    margin: 10px 0 0 0;
    padding: 0;
  }
`;

const ButtonText = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;
`;

const StyledChevron = styled(ChevronRight)`
  display: inline-block;
  cursor: pointer;
  margin-right: 8px;
  margin-left: 2px;
  padding-top: 2px;

  @media (max-width: 900px) {
    margin-right: 0;
  }
`;

const Breadcrumb = ({ onClick, text, lastOfType, isMobile }) => {
  return (
    <Button onClick={onClick}>
      <ButtonText>{text}</ButtonText>
      {!lastOfType && <StyledChevron size={16} strokeWidth={1.5} />}
    </Button>
  );
};

export default Breadcrumb;
