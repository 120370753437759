import React, { useState } from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import HeaderImage from '../../media/Halliburton_Logo.svg';

const modalStyle = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    zIndex: '1000',
  },
  content: {
    padding: '0px',
    margin: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none',
    border: 'none',
    background: 'none',
  },
};

const ModalContainer = styled.div`
  font-family: Univers;
  width: 378px;
  height: 296px;
  //   border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px var(--color-gray1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const ModalTitle = styled.div`
  font-family: Univers;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: center;
  padding-top: 36px;
`;

const ModalMessage = styled.div`
  font-family: Univers;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  padding-top: 20px;
  max-width: 270px;
`;

const ModalButton = styled.button`
  font-family: Univers;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  width: 326px;
  height: 50px;
  //   border-radius: 10px;
  margin: 42px 26px 27px;
  color: #fff;
  background: #ce0e2d;
  border: none;
  cursor: pointer;
`;

const StyledHalLogoImage = styled.img`
  width: 330px;
  height: 25px;
  padding: 21px 24px 0 21px;
`;

export const useSuccessModal = () => {
  const [isShowing, setIsShowing] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: '',
    message: 'No Message',
  });

  const toggle = () => {
    setIsShowing((isShowingNow) => !isShowingNow);
  };

  const show = () => {
    setIsShowing(true);
  };

  const hide = () => {
    setIsShowing(false);
  };

  const setContent = ({ title, message }) => {
    setModalContent({ title, message });
  };

  return [modalContent, setContent, isShowing, show, hide, toggle];
};

const SuccessModal = ({ isOpen, modalContent, onOkButtonClick }) => {
  ReactModal.setAppElement('#root');
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => false}
      contentLabel="Error Modal"
      shouldCloseOnOverlayClick={false}
      style={modalStyle}
    >
      <ModalContainer>
        <StyledHalLogoImage src={HeaderImage} alt="Halliburton logo" />
        <ModalTitle>{modalContent?.title ?? ''}</ModalTitle>
        <ModalMessage>{modalContent?.message ?? ''}</ModalMessage>
        <ModalButton
          onClick={() => {
            if (onOkButtonClick) {
              onOkButtonClick();
            }
          }}
        >
          OK
        </ModalButton>
      </ModalContainer>
    </ReactModal>
  );
};

export default SuccessModal;
