import React, { useState, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';

import CategoryTree from './CategoryTree';

import SearchContext from '../../SearchContext';

const CategoryContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  height: calc(100% + 100px);

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`;

const CategoryTitle = styled.p`
  font-size: 26px;
  font-weight: 500;
  color: var(--color-gray1);
  margin-bottom: 10px;
  margin-top: 15px;
  @media (max-width: 1024px) {
    margin-left: 12px;
    margin-top: 0;
  }
`;

const BackToTopButton = styled.button`
  font-family: Univers;
  width: 100%;
  height: 38px;
  font-size: 14px;
  align-self: flex-end;
  background-color: ${(props) =>
    props.$isSelectionMade ? 'var(--color-primary)' : '#6D757A'};
  color: white;
  border: unset;
  // border-radius: 8px;
  margin-top: 16px;
  transition: color 0.2s ease-out, background-color 0.2s ease-out,
    border-bottom-color 0.2s ease-out;

  cursor: ${(props) => (props.$isSelectionMade ? 'pointer' : '')};
  &:hover {
    background-color: ${(props) =>
      props.$isSelectionMade ? 'var(--color-dark-red)' : ''};
  }
  @media (max-width: 1024px) {
    width: 94%;
    align-self: center;
  }
`;

const createNestedStructure = (data) => {
  const nestedStructure = {};

  if (data) {
    data.forEach((item) => {
      const paths = item.path?.split('|');
      let currentNode = nestedStructure;

      paths.forEach((path, index) => {
        if (!currentNode[path]) {
          currentNode[path] = {
            _toolCount: item.count,
          };
        } else {
          currentNode[path]._toolCount += item.count;
        }

        if (index === paths.length - 1) {
          currentNode[
            path
          ]._leafClass = `${item.name}, ${item.count}, ${item.id}`;
        }

        currentNode = currentNode[path];
      });
    });
  }

  return nestedStructure;
};

const storeLeafClasses = (node, searchTerm) => {
  if (searchTerm === '') {
    const leafClasses = [];

    const traverse = (currentNode) => {
      if (currentNode._leafClass && currentNode._toolCount > 0) {
        leafClasses.push(currentNode._leafClass);
      }
      for (const key in currentNode) {
        if (key !== '_leafClass' && key !== '_toolCount') {
          traverse(currentNode[key]);
        }
      }
    };

    traverse(node);
    return leafClasses;
  }
};

const Category = () => {
  const {
    searchWithinClassesData,
    onBrowseOptionChange,
    searchTerm,
    browseOptions,
    handleClearBrowseOptions,
    setBackToTopClicked,
  } = useContext(SearchContext);
  const [selectedOptions, setSelectedOptions] = useState(browseOptions);

  const cachedClasses = JSON.parse(localStorage.getItem('fetchedClasses'));

  const optionsForNavigatingFromBrowse = cachedClasses
    ?.filter(
      (option) =>
        option.ClassPath !== null &&
        option.ClassPath !== '' &&
        option.ClassPath !== undefined &&
        option.Name !== 'Layout Drawing - General'
    )
    .map((option) => {
      return {
        path: option.ClassPath,
        name: option.Name,
        count: option.ToolCount,
        id: option.Id,
      };
    });

  const optionsForSearchView = searchWithinClassesData
    ?.map((searchClass) => {
      const option = cachedClasses.find(
        (classData) =>
          classData.Id === searchClass.ClassId &&
          classData.ClassPath &&
          classData.Name !== 'Layout Drawing - General'
      );
      if (option) {
        return {
          path: option.ClassPath,
          name: option.Name,
          count: searchClass.Count,
          id: option.Id,
        };
      }
      return null;
    })
    .filter(Boolean);

  const nestedStructure = createNestedStructure(
    searchTerm === '' ? optionsForNavigatingFromBrowse : optionsForSearchView,
    searchTerm
  );
  const nestedStructureLevel3 =
    nestedStructure['Materials Library']['Drilling_Exploration And Production'];

  const handleOptionChange = (level, option) => {
    setSelectedOptions((prevSelected) => {
      const updatedSelected = prevSelected.slice(0, level);
      updatedSelected[level - 1] = option;
      return updatedSelected;
    });
  };

  const previousBrowseOptionsRef = useRef();
  const previousSearchTermRef = useRef();

  useEffect(() => {
    if (
      previousBrowseOptionsRef.current !== undefined &&
      previousBrowseOptionsRef.current !== selectedOptions
    ) {
      onBrowseOptionChange(selectedOptions);
    }
    if (
      previousSearchTermRef.current !== undefined &&
      previousSearchTermRef.current !== searchTerm
    ) {
      setSelectedOptions([]);
    }
    previousSearchTermRef.current = searchTerm;
    previousBrowseOptionsRef.current = selectedOptions;
  }, [selectedOptions, searchTerm]);

  const renderCategoryTree = (data, level) => {
    const selectedOption = selectedOptions[level - 1];

    const currentLevelLeafClasses = storeLeafClasses(data, searchTerm);

    if (!data || Object.keys(data).length === 0) return null;

    const options = Object.keys(data);

    const hasChildren = options.some(
      (option) => typeof data[option] === 'object'
    );

    return (
      <>
        <div key={level}>
          <CategoryTree
            isLeaf={
              searchTerm === ''
                ? currentLevelLeafClasses.length === 1
                : !hasChildren
            }
            level={level}
            selectedOption={selectedOption}
            options={options}
            handleOptionChange={handleOptionChange}
            data={data}
          />
          {selectedOption &&
            renderCategoryTree(data[selectedOption], level + 1)}
        </div>
      </>
    );
  };

  const handleClearOptions = () => {
    handleClearBrowseOptions();
    setSelectedOptions([]);
    setBackToTopClicked(true);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <CategoryContainer>
      <CategoryTitle>Category</CategoryTitle>
      {renderCategoryTree(nestedStructureLevel3, 1)}
      <BackToTopButton
        $isSelectionMade={selectedOptions.length}
        onClick={() => handleClearOptions()}
      >
        Back to Top
      </BackToTopButton>
    </CategoryContainer>
  );
};

export default Category;
