import React from 'react';
import styled from 'styled-components';

import { ChevronLeft } from 'react-feather';

const Button = styled.button`
  background: none;
  border: none;
  height: 0;
  display: flex;
  align-items: center;
  font-family: Univers;
  font-size: 16px;
  text-align: left;
  color: var(--color-primary);
  margin-left: -15px;
  margin-bottom: 30px;
  margin-top: 10px;
  cursor: pointer;

  @media (max-width: 900px) {
    margin-top: 10px;
    margin-bottom: 0;
  }
`;

const StyledChevron = styled(ChevronLeft)`
  display: inline-block;
  cursor: pointer;

  transform: ${(props) =>
    props.$isRowExpanded ? 'rotate(90deg)' : 'rotate(0)'};
  transition: transform 0.3s ease;
`;

const BackButton = ({ onClick, text }) => {
  return (
    <Button onClick={onClick}>
      <StyledChevron strokeWidth={1.5} />
      <p>{text}</p>
    </Button>
  );
};

export default BackButton;
