import {IAssetInfo} from "./../assets/assetinfo.js";
import {IAssetProxy, ITypedAssetProxy} from "./../assets/assetproxy.js";
import {IUniformSource, UniformContainer} from "./../renderer/uniform.js";
import {MeshNode} from "./../scenenodes/meshnode.js";
import {XFormNode} from "./../xformnode.js";

class MeshNodeRoot extends IUniformSource(XFormNode) {
    constructor() {
        super();

        /**
         * @type {ITypedAssetProxy}
         */
        this._vertexProcessorMap = null;

        /**
         * @type {IAssetInfo}
         */
        this._vertexProcessorInfo = null;

        /**
         * @type {IAssetProxy}
         */
        this._materialMap = null;

        /**
         * @type {IAssetInfo}
         */
        this._materialMapInfo = null;

        this._uniforms = new UniformContainer();
    }

    /**
     * @returns {IAssetInfo}
     */
    get vertexProcessorMapInfo() {
        return this._vertexProcessorMapInfo;
    }

    set vertexProcessorMapInfo(value) {
        if (this._vertexProcessorMapInfo != value) {
            // release any existing vertex processor map
            this._releaseVertexProcessorMap();

            this._vertexProcessorMapInfo = value;

            // load the new vertex processor map if possible
            this._loadVertexProcessorMap();
        }
    }

    get vertexProcessorMap() {
        const result = this._vertexProcessorMap ? this._vertexProcessorMap.asset : null;
        return result;
    }

    /**
     * @returns {IAssetInfo}
     */
    get materialMapInfo() {
        return this._materialMapInfo;
    }

    set materialMapInfo(value) {
        if (this._materialMap != value) {
            // only allow material map types
            if (value && value.assetTYpe == IMaterialMap) {
                this._releaseMaterialMap(false);

                this._materialMapInfo = value;

                // load the new material map if possible
                this._loadMaterialMap(false);

                // refresh the render calls once everything is done
                this._refreshRenderCalls();
            }
        }
    }

    /**
     * @returns {IMaterialMap}
     */
    get materialMap() {
        const result = this._materialMap ? this._materialMap.iasset : null;
        return result;
    }

    /**
     * @returns {Array<Uniform>}
     */
    get uniforms() {
        return this._uniforms.uniforms;
    }

    /**
     * @returns {Array<Uniform>}
     */
    get renderUniforms() {
        return this._uniforms.renderUniforms;
    }

    addUniform(uniform) {
        this.addChild(uniform);
    }

    removeUniform(uniform) {
        uniform.removeFromParent();
    }

    sync() {
        this._uniforms.sync();
    }

    _loadVertexProcessorMap() {
        if (this._vertexProcessorMap) {
            if (this._vertexProcessorMapInfo && this._updater && this._updater.areAssetsLoaded) {
                this._vertexProcessorMap = AssetFactor.loadAsset(this._vertexProcessorInfo);
                if( this._vertexProcessorMap) {
                    this._refreshRenderCalls();
                    // TODO: add asset changed event to vertex processor map
                }
            }
        }
        else {
            throw new Error("Not implemented");
        }
    }

    _releaseVertexProcessorMap() {
        // release the model
        if (this._vertexProcessorMap) {
            // TODO: remove asset changed event from vertex processor map
            AssetFactory.releaseAsset(this._vertexProcessorMap);
            this._vertexProcessorMap = null;

            this._refreshRenderCalls();
        }
    }

    /**
     * 
     * @param {Boolean} shouldRefreshRenderCalls True if we should refresh the render calls, false otherwise.
     */
    _loadMaterialMap(shouldRefreshRenderCalls) {
        if (this._materialMapInfo) {
            if (this._materialMapInfo && this._updater && this._updater.areAssetsLoaded) {
                this._materialMap = AssetFactory.loadAsset(this._materialMapInfo);
                if (this._materialMap) {
                    if (shouldRefreshRenderCalls) {
                        this._refreshRenderCalls();
                    }

                    // TODO: add asset changed event
                }
            }
        }
        else {
            throw new Error("Not implemented.");
        }
    }

    _releaseMaterialMap(shouldRefreshRenderCalls) {
        // release the model
        if (this._materialMapInfo) {
            // TODO: remove asset changed event
            AssetFactory.releaseAsset(this._materialMap);
            this._materialMap = null;

            if (shouldRefreshRenderCalls) {
                this._refreshRenderCalls();
            }
        }
    }

    _refreshRenderCalls() {
        if (this._updater && this._updater.areAssetsLoaded) {
            /**
             * @type {Array<MeshNode>}
             */
            const meshes = this.getNodes(MeshNode);

            meshes.forEach(mesh => {
                // reset the updater so that it will re-submit it's render calls and get new vertex processors, materials, etc.
                mesh.setUpdater(null);
                mesh.setUpdater(this._updater)
            });
        }
    }

    _resubmitRenderCalls() {
        /**
         * @type {Array<MeshNode>}
         */
        const meshes = this.getNodes(MeshNode);

        meshes.forEach(mesh => mesh.resubmitRenderCalls());
    }

    _loadAssets() {
        super._loadAssets();

        // see if we need to load our assets
        this._loadVertexProcessorMap();
        this._loadMaterialMap(true);
    }

    _releaseAssets() {
        this._releaseVertexProcessorMap();
        this._releaseMaterialMap(true);

        super._releaseAssets();
    }

    _onNewChild(child, after) {
        super._onNewChild(child, after);

        if (child instanceof Uniform) {
            this.uniforms.push(child);
            this._resubmitRenderCalls();
        }
    }

    _onRemoveChild(child) {
        super._onRemoveChild(child);

        if (child instanceof Uniform) {
            const index = this._uniforms.indexOf(child);
            if (index != -1) {
                this._uniforms.splice(index, 1);
            }

            this._resubmitRenderCalls();
        }
    }
}

export {MeshNodeRoot};
