import {AnimationNode} from "./../animations/animationnode.js";
import {SerialNode} from "./../serialization/serialnode.js";

/**
 * The SceneNodes namespace contains all of the classes used by the 3d scene graph.
 */
class SceneNode extends SerialNode {
    constructor() {
        super();

        this.updateDepth = -1;
    }

    /**
     * Set the named object owner on this scene node, allowing any named references to be resolved.
     * @param {Any} owner The owner used to resolve named references.
     */
    setNamedObjectOwner(owner) {
        Array.from(this.getChildren(SceneNode)).forEach(child => child.setNamedObjectOwner(owner));
    }
}

export { SceneNode };
