import {GroupNode} from "./groupnode.js";

class Animation extends GroupNode {
    constructor() {
        super();
        
        /**
         * Name of the animation.
         */
        this.name = "";


        this.timeScale = 1;

        /**
         * True if the animation is paused, false otherwise.
         */
        this.isPaused = false;

        /**
         * True if the animation allows looping, false otherwise.
         */
        this.allowsLooping = true;

        /**
         * Object that is the target of the animation.
         * @type {Object}
         */
        this.target = null;
    }

    /**
     * This function should be called to update an instance of an animation. (Not implemented)
     * @param {Number} deltaTime Number of seconds to advance the animation.
     */
    update(deltaTime) {
        throw new Error("Not implemented");
    }

    /**
     * This function should be called to update an instance of an animation.
     * @param {Number} time Number of seconds to advance the animation.
     */
    setTime(time) {
        throw new Error("Not implemented");
    }

    /**
     * Stops the animation.
     */
    stop() {
        throw new Error("Not implemented");
    }

    /**
     * Restarts the animation.
     */
    restart() {
        throw new Error("Not implemented");
    }
}

export { Animation };
