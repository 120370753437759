import React, { useEffect, useState, useContext } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import styled from 'styled-components';
import * as siteConfig from '../siteconfig';
import ApplicationContext from '../ApplicationContext';
import { fetchChatBotToken } from '../backend/chatbot';

import BotAvatarIcon from '../../media/chatbot/bot-avatar.png';
import UserAvatarIcon from '../../media/chatbot/user-avatar.png';
import CloseChatIcon from '../../media/chatbot/close-chat.png';
import RemoveChatIcon from '../../media/chatbot/chat-bot-close-bubble.png';
import ChatInitiateIcon from '../../media/chatbot/chat-bot-icon.svg';
import { ExternalLink } from 'react-feather';

const ChatBot = styled.div`
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 350px;
  pointer-events: none;
  box-sizing: border-box;
  padding-right: 10px;
  padding-bottom: 10px;
  z-index: 99;
`;

const ChatBotOpenedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;

  & > #webchat {
    height: 500px;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: solid 1px gray;
    border-right: solid 1px gray;
    border-bottom: solid 1px gray;
    font-size: 16px;
    font-family: Univers;
    font-weight: 300;
    position: relative;
    pointer-events: all;
    background-color: white;
  }
`;

const ChatBotClosedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;

  & > button {
    font-family: Univers;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    pointer-events: all;
  }
`;

const ConversationBubbleToolTip = styled.div`
  width: 240px;
  font-size: 14px;
  background-color: #f4f4f4;
  padding: 10px;
  // border-radius: 5px;
  color: #1c1c1e;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
`;

const ChatBotHeader = styled.div`
  background-color: #cc0000;
  height: 50px;
  width: 100%;
  // border-top-left-radius: 5px;
  // border-top-right-radius: 5px;
  display: flex;
  justify-content: space-between;
`;

const ChatBotHeaderTitle = styled.div`
  font-size: 16px;
  font-family: Univers;
  line-height: 26px;
  color: whitesmoke;
  display: table-cell;
  padding: 13px 0px 0px 20px;
  font-weight: 500;
`;

const ChatBotHeaderCloseButton = styled.button`
  font-family: Univers;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  margin-right: 15px;
  pointer-events: all;
`;

const ChatBotHideButton = styled.button`
  font-family: Univers;
  margin-right: -5px;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2));
  pointer-events: all;
`;

const ChatBotOpenButton = styled.button`
  font-family: Univers;
  margin-top: 10px;
  margin-bottom: -10px;
  margin-right: -10px;
  pointer-events: all;

  & > img {
    filter: drop-shadow(5px 5px 4px darkslategray);
  }
`;

const ChatBotMobileIcon = styled(ExternalLink)`
  transform: rotateZ(270deg);
  color: var(--color-primary);
`;

const styleOptions = {
  userAvatarInitials: '',
  botAvatarInitials: '',
  accent: '#00809d',
  botAvatarBackgroundColor: '#FFFFFF',
  botAvatarImage: BotAvatarIcon,
  userAvatarImage: UserAvatarIcon,
  hideUploadButton: true,
  sendBoxButtonColor: 'white',
  sendBoxButtonShadeColor: '#CC0000',
  sendBoxHeight: 50,
  bubbleBackground: '#f4f4f4',
  bubbleBorderRadius: 5,
  bubbleFromUserBackground: '#f4f4f4',
  bubbleFromUserBorderRadius: 5,
  primaryFont: 'Univers',
  suggestedActionsStackedLayoutButtonTextWrap: true,
  suggestedActionLayout: 'stacked',
};

export default () => {
  const { classType } = useContext(ApplicationContext);
  const isMobile = classType === 'compact';
  const [chatOpen, setChatOpen] = useState(false);
  const [helperTextHidden, setHelperTextHidden] = useState(false);

  const { authState } = useOktaAuth();

  const [showChatBotIcon, setshowChatBotIcon] = useState(true);

  const onWindowScroll = () => {
    setshowChatBotIcon(window.scrollY === 0);
  };

  useEffect(() => {
    window.removeEventListener('scroll', onWindowScroll);
    if (isMobile) {
      window.addEventListener('scroll', onWindowScroll, { passive: true });
      return () => window.removeEventListener('scroll', onWindowScroll);
    }
  }, [isMobile]);

  useEffect(() => {
    if (chatOpen) {
      const chatbotUrl = siteConfig.chatBotUrl;

      if (chatbotUrl === undefined || chatbotUrl === null || chatbotUrl === '')
        return;

      const environmentEndPoint = chatbotUrl.slice(
        0,
        chatbotUrl.indexOf('/powervirtualagents')
      );
      const apiVersion = chatbotUrl
        .slice(chatbotUrl.indexOf('api-version'))
        .split('=')[1];
      const regionalChannelSettingsURL = `${environmentEndPoint}/powervirtualagents/regionalchannelsettings?api-version=${apiVersion}`;

      fetch(chatbotUrl)
        .then((response) => {
          return response.json();
        })
        .then((conversationInfo) => {
          fetch(regionalChannelSettingsURL)
            .then((response) => {
              return response.json();
            })
            .then(async (data) => {
              const store = window.WebChat.createStore(
                {},
                ({ dispatch }) =>
                  (next) =>
                  (action) => {
                    if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
                      dispatch({
                        meta: {
                          method: 'keyboard',
                        },
                        payload: {
                          activity: {
                            channelData: {
                              postBack: true,
                            },
                            //Web Chat will show the 'Greeting' System Topic message which has a trigger-phrase 'hello'
                            name: 'startConversation',
                            type: 'event',
                          },
                        },
                        type: 'DIRECT_LINE/POST_ACTIVITY',
                      });
                    }
                    return next(action);
                  }
              );
              const tokenObj = await fetchChatBotToken();
              const directline = data.channelUrlsById.directline;
              window.WebChat.renderWebChat(
                {
                  directLine: window.WebChat.createDirectLine({
                    domain: `${directline}v3/directline`,
                    token: tokenObj.token,
                  }),
                  locale: 'en-US',
                  store: store,
                  styleOptions: styleOptions,
                },
                document.getElementById('webchat')
              );
            })
            .catch((err) =>
              console.error(
                'An error occurred with fetching regionalChannelSettingsURL: ' +
                  err
              )
            );
        });
    }
  }, [chatOpen]);

  if (authState === null || authState?.isAuthenticated === false) return null;

  return (
    <ChatBot>
      {chatOpen ? (
        <ChatBotOpenedContainer>
          <ChatBotHeader>
            <ChatBotHeaderTitle>Engineered Solutions</ChatBotHeaderTitle>
            <ChatBotHeaderCloseButton
              alt="minimize chat"
              onClick={() => setChatOpen(false)}
            >
              <img alt="close" src={CloseChatIcon} height="15" />
            </ChatBotHeaderCloseButton>
          </ChatBotHeader>
          <div id="webchat" role="main" />
        </ChatBotOpenedContainer>
      ) : (
        <ChatBotClosedContainer>
          {!helperTextHidden && (!isMobile || showChatBotIcon) && (
            <>
              {' '}
              <ChatBotHideButton
                alt="hide chat bot from this view"
                onClick={() => setHelperTextHidden(true)}
              >
                <img
                  src={RemoveChatIcon}
                  height="20"
                  alt="hide chat helper text"
                />
              </ChatBotHideButton>
              <ConversationBubbleToolTip>
                What challenge can I help you solve?
              </ConversationBubbleToolTip>
            </>
          )}

          {isMobile && !showChatBotIcon ? (
            <ChatBotOpenButton
              alt="show chat bot"
              onClick={() => setshowChatBotIcon(true)}
            >
              <ChatBotMobileIcon />
            </ChatBotOpenButton>
          ) : (
            <ChatBotOpenButton
              alt="initiate chat"
              onClick={() => setChatOpen(true)}
            >
              <img src={ChatInitiateIcon} height="80" alt="chatbot" />
            </ChatBotOpenButton>
          )}
        </ChatBotClosedContainer>
      )}
    </ChatBot>
  );
};
