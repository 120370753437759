import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useOktaAuthContext } from '../../../OktaAuthContext.js';
import { useOktaAuth } from '@okta/okta-react';

const ProductAndServiceCatalogMenuContainer = styled.div`
  position: absolute;
  width: 820px;
  height: 260px;
  background: white;
  top: 45px;
  left: 20%;
  z-index: 3;
  padding: 32px 42px 42px 42px;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.2);
  // border-radius: 10px;

  &:after {
    content: '';
    position: absolute;
    top: -20px;
    left: 10%;
    transform: translate(-50%, 0);
    border: 10px solid white;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    border-bottom-color: white;
    width: 0;
    height: 0;
    margin: 0 auto;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: var(--color-gray2);
  padding-bottom: 12px;
  padding-left: ${(props) => (props.$surfaceTools ? '12px' : '0')};
`;

const LinkedCatalogButton = styled.button`
  background: none;
  border: none;
  margin-left: 0;
  margin-right: 10px;
  padding-left: 0;
  cursor: pointer;
  font-size: ${(props) => (props.$mobile ? '16px' : '14px')};
  line-height: ${(props) => !props.$mobile && '22px'};
  padding-bottom: ${(props) => (props.$mobile ? '14px' : '0')};
  text-align: left;
  font-family: Univers;
  color: var(--color-gray2);

  &:hover {
    color: #cc0000;
  }
`;

const ToolContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const HeaderAndBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.$biggerWidth ? '65%' : '35%')};
`;

const DownholeToolContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  height: 240px;
  flex-wrap: wrap;
  border-right: 1px solid var(--color-gray4);
`;

const SurfaceToolsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-left: 12px;
`;

const MobileToolsContainer = styled.div`
  height: 80%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 28px;
  padding-top: 20px;
  margin-bottom: 70px;
`;

const MobileHeaderText = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: var(--color-gray1);
  padding-bottom: 8px;
`;

const ProductAndServiceCatalogMenu = ({
  mobile,
  closeProductAndServiceCatalogMenu,
  closeMobileMenu,
}) => {
  let cachedClasses = JSON.parse(localStorage.getItem('fetchedClasses'));

  const navigate = useNavigate();
  const { openModal } = useOktaAuthContext();
  const { authState } = useOktaAuth();

  const options = cachedClasses
    ?.filter(
      (option) =>
        option.ClassPath !== null &&
        option.ClassPath !== '' &&
        option.Name !== 'Layout Drawing - General'
    )
    .map((option) => {
      const classes = option?.ClassPath?.split('|');

      const classIndex = classes?.indexOf('Downhole Tools');
      let classToRender = '';

      if (classIndex !== -1 && classIndex < classes?.length - 1) {
        const classAfterMain = classes[classIndex + 1];
        classToRender = classAfterMain;
      } else {
        return null;
      }

      return {
        className: classToRender,
      };
    });

  const filteredOptions = options?.filter(
    (option) =>
      option !== null &&
      option.className !== 'Tesat01' &&
      option.className !== 'Mechanical Kit' &&
      option.className !== 'Inactive Classes'
  );

  const uniqueClassesSet = new Set(
    filteredOptions?.map((toolClass) => toolClass.className)
  );

  const uniqueClasses = [...uniqueClassesSet].map((toolClass) =>
    filteredOptions?.find((className) => toolClass === className.className)
  );

  const surfaceToolsOptions = cachedClasses
    ?.filter(
      (option) =>
        option.ClassPath !== null &&
        option.ClassPath !== '' &&
        option.Name !== 'Layout Drawing - General'
    )
    .map((option) => {
      const classes = option?.ClassPath?.split('|');

      const classIndex = classes?.indexOf('Surface Tools');
      let classToRender = '';

      if (classIndex !== -1 && classIndex < classes?.length - 1) {
        const classAfterMain = classes[classIndex + 1];
        classToRender = classAfterMain;
      } else {
        return null;
      }

      return {
        className: classToRender,
      };
    });

  const filteredSurfaceToolOptions = surfaceToolsOptions?.filter(
    (option) =>
      option !== null &&
      option.className !== 'Tesat01' &&
      option.className !== 'Mechanical Kit' &&
      option.className !== 'Inactive Classes'
  );

  const uniqueSurfaceToolSet = new Set(
    filteredSurfaceToolOptions?.map((toolClass) => toolClass.className)
  );

  const uniqueSurfaceToolClasses = [...uniqueSurfaceToolSet].map((toolClass) =>
    filteredSurfaceToolOptions?.find(
      (className) => toolClass === className.className
    )
  );

  const redirectToBrowse = (className) => {
    mobile ? closeMobileMenu() : closeProductAndServiceCatalogMenu();

    if (authState?.isAuthenticated) {
      navigate(`/browse?${className}`);
    } else {
      openModal(`/browse?${className}`);
    }
  };

  return (
    <>
      {!mobile ? (
        <ProductAndServiceCatalogMenuContainer>
          <ToolContainer>
            <HeaderAndBodyContainer $biggerWidth>
              <HeaderText>Downhole Tools</HeaderText>{' '}
              <DownholeToolContainer>
                {uniqueClasses.map((uniqueClass) => (
                  <LinkedCatalogButton
                    key={uniqueClass.className}
                    onClick={() => redirectToBrowse(uniqueClass.className)}
                  >
                    {uniqueClass.className}
                  </LinkedCatalogButton>
                ))}
              </DownholeToolContainer>
            </HeaderAndBodyContainer>

            <HeaderAndBodyContainer>
              <HeaderText $surfaceTools>Surface Tools</HeaderText>{' '}
              <SurfaceToolsContainer>
                {uniqueSurfaceToolClasses.map((uniqueClass) => (
                  <LinkedCatalogButton
                    key={uniqueClass.className}
                    onClick={() => redirectToBrowse(uniqueClass.className)}
                  >
                    {uniqueClass.className}
                  </LinkedCatalogButton>
                ))}
              </SurfaceToolsContainer>
            </HeaderAndBodyContainer>
          </ToolContainer>
        </ProductAndServiceCatalogMenuContainer>
      ) : (
        <MobileToolsContainer>
          <MobileHeaderText>Downhole Tools</MobileHeaderText>
          {uniqueClasses.map((uniqueClass) => (
            <LinkedCatalogButton
              $mobile
              key={uniqueClass.className}
              onClick={() => redirectToBrowse(uniqueClass.className)}
            >
              {uniqueClass.className}
            </LinkedCatalogButton>
          ))}
          <MobileHeaderText>Surface Tools</MobileHeaderText>
          {uniqueSurfaceToolClasses.map((uniqueClass) => (
            <LinkedCatalogButton
              $mobile
              key={uniqueClass.className}
              onClick={() => redirectToBrowse(uniqueClass.className)}
            >
              {uniqueClass.className}
            </LinkedCatalogButton>
          ))}
        </MobileToolsContainer>
      )}
    </>
  );
};

export default ProductAndServiceCatalogMenu;
