import {AffineTransform} from "./../../../base/math.js";
import {ValueNode} from "./valuenode.js";

class MatrixValueNode extends ValueNode {
    constructor() {
        super();
    }

    get keyframeValueType() {
        return AffineTransform.Matrix4;
    }

    interpolateValue(startValue, startData, endValue, endData, progress) {
        throw new Error("Not currently implemented.");
    }
}

export {MatrixValueNode};
