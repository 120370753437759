import {IndexBuffer} from "./indexbuffer.js";
import {NamedReference} from "./../serialization/namedobject.js";
import {PrimitiveType, PrimitiveTypeToString} from "../types.js";
import {SerialObject} from "./../serialization/serialobject.js";
import {VertexBuffer} from "./vertexbuffer.js";

/**
 * Represents a basic draw call that can be saved (not yet) and loaded (not yet).
 */
class DrawCall extends SerialObject {
    constructor() {
        super();
        
        /**
         * The vertex buffer used by this draw call.
         * @type {NamedReference}
         */
        this.vertices = new NamedReference(null, VertexBuffer);

        /**
         * The index buffer used by this draw call.
         * @type {NamedReference}
         */
        this.indices = new NamedReference(null, IndexBuffer);

        /**
         * The type of geometry primitive rendered by this draw call.
         * 
         * @type {PrimitiveType}
         */
        this.primitiveType = PrimitiveType.TRIANGLES;

        /**
         * The offset in bytes into the index buffer where this draw call starts.
         * 
         * @type {Number}
         */
        this.indexOffset = 0;

        /**
         * The number of sequential indices in the index buffer used by this draw call.
         * 
         * @type {Number}
         */
        this.indexCount = 0;
    }
    
    _writeXmlAttributes(xmlDoc) {
        const attributes = super._writeXmlAttributes(xmlDoc);
        
        attributes.set("VertexBuffer", this.vertices.name);
        attributes.set("IndexBuffer", this.indices.name);
        attributes.set("Primitive", PrimitiveTypeToString(this.primitiveType));
        attributes.set("IndexOffset", this.indexOffset);
        attributes.set("IndexCount", this.indexCount);
        
        return attributes;
    }
}

export { DrawCall };
