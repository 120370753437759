import { useState, useRef } from 'react';

/**
 * Returns the forwarded reference if it exits or a new reference.
 *
 * @param {Object} forwardedRef Forwarded reference.
 *
 * @returns {Object} Forwarded reference or a new reference.
 */
function newOrForwardedRef(forwardedRef) {
  const ref =
    typeof forwardedRef !== 'undefined' && forwardedRef != null
      ? forwardedRef
      : useRef(null);
  return ref;
}

/**
 * Creates a useState React hook that is backed by a reference.
 *
 * @param {Any} initial Initial value or initializing function.
 *
 * @returns {Array} Getter and setter.
 */
function useRefState(initial) {
  const [getter, _setter] = useState(initial);
  const stateRef = useRef(getter);

  const setter = (value) => {
    stateRef.current = value;
    _setter(value);
  };

  return [stateRef, setter];
}

/**
 * Builds a class name of a collection of name / boolean(condition) pairs.
 *
 * If the boolean value of the pair is not defined or true, then the associated
 * name will be included in the classname, otherwise it will be ignored.
 *
 * @param {Array}
 *
 * @returns {String} Space delimited class names.
 */
function buildClassName(defs) {
  let updatedDefs;

  if (
    Array.isArray(defs) &&
    defs.length > 0 &&
    defs.length <= 2 &&
    !Array.isArray(defs[0])
  ) {
    updatedDefs = [defs];
  } else {
    updatedDefs = defs;
  }

  const className = updatedDefs
    .reduce((result, def) => {
      if (!Array.isArray(def)) {
        return result.concat(def);
      } else if (def[1] == true) {
        return result.concat(def[0]);
      } else {
        return result;
      }
    }, [])
    .join(' ');

  return className;
}

/**
 * Extends the given base class name.
 *
 * @param {String|Array<String>} base Base class name.
 * @param {String|Array<String>} extension Additional class name(s) to append.
 *
 * @returns {String} Extended class name.
 */
function extendClassName(base, extension) {
  let components =
    base != null && !/^\s*$/.test(base)
      ? !Array.isArray(base)
        ? base.split(' ')
        : base
      : [];

  if (extension != null && !/^\s*$/.test(extension)) {
    components = components.concat(
      !Array.isArray(extension) ? extension.split(' ') : extension
    );
  }

  const extended = Array.from(new Set(components)).join(' ');
  return extended;
}

function camelize(str, exceptions = []) {
  return str.replace(
    /\w[^\r\n\t\f\v \-]*/g,
    txt => exceptions.includes(txt) ? txt : txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
}

export { buildClassName, extendClassName, newOrForwardedRef, useRefState, camelize };
