import {Matrix4} from "./../../../base/math/affinetransform.js";
import {Vector3} from "./../../../base/math/vector3.js";

if (typeof Matrix4.prototype.translation === "undefined") {
    Object.defineProperty(Matrix4.prototype, "translation", {
        /**
        * Returns the containing translation as a vector.
        * @returns {Vector3} Translation vector.
        */
        get: function() {
            const result = new Vector3(this.m41, this.m42, this.m43);
            return result;
        },
       
        /**
        * Set the translation from a vector.
        * @param {Vector3} value Translation vector.
        */
        set: function(value) {
            this.m41 = value.x;
            this.m42 = value.y;
            this.m43 = value.z;
        }
    });
}

if (typeof Matrix4.prototype.decompose === "undefined") {
    Matrix4.prototype.decompose = function() {
        const copy = new Matrix4(this);
        
        if (copy.m44 == 0) {
            throw new Error("Cannot decompose an infinite project matrix.");
        }
        
        // normalize the matrix.
        
        if (copy.m44 != 1) {
            const scale = 1 / copy.m44;
            this.components = this.components.map(m => m * scale);
        }
        
        // get the translation and remove it
        const translation = this.translation;
        this.translation = Vector3.zero;
        
        const rows = [
            new Vector3(this.m11, this.m21, this.m31),
            new Vector3(this.m12, this.m22, this.m32),
            new Vector3(this.m13, this.m23, this.m33)
        ];
        
        // get the scale
        const scale = new Vector3(rows[0].magnitude, rows[1].magnitude, rows[2].magnitude);
        
        /**
         * @type {Vector3}
         */
        let eulerAngles;
        
        if (scale.x != 0 && scale.y != 0 && scale.z != 0) {
            // factor out the scale
            
            rows[0] = rows[0].multiply(scale.x);
            rows[1] = rows[1].multiply(scale.y);
            rows[2] = rows[2].multiply(scale.z);
            
            // check for negative scaling
            if (rows[0].dot(rows[1].cross(rows[2])) < 0) {
                scale.x *= -1;
                rows[0] = rows[0].multiply(-1);
                
                scale.y *= -1;
                rows[1] = rows[1].multiply(-1);
                
                scale.z *= -1;
                rows[2] = rows[2].multiply(-1);
            }
            
            // set them back again
            copy.m11 = rows[0].x;
            copy.m21 = rows[0].y;
            copy.m31 = rows[0].z;
            
            copy.m12 = rows[1].x;
            copy.m22 = rows[1].y;
            copy.m32 = rows[1].z;
            
            copy.m13 = rows[2].x;
            copy.m23 = rows[2].y;
            copy.m33 = rows[2].z;
            
            eulerAngles = new Vector3();
            
            eulerAngles.y = -Math.asin(copy.m13);
            const cosY = Math.cos(eulerAngles.y);
            
            if (cosY > 0) {
                eulerAngles.x = Math.atan2(copy.m23, copy.m33);
                eulerAngles.z = Math.atan2(copy.m12, copy.m11);
            }
            else {
                eulerAngles.x = Math.atan2(-copy.m23, -copy.m33);
                eulerAngles.z = Math.atan2(-copy.m12, -copy.m11);
            }
        }
        else {
            eulerAngles = Vector3.zero;
        }
        
        return {"translation": translation, "rotation": eulerAngles, "scale": scale};
    }
}

if (typeof Matrix4.createRotationX === "undefined") {
    /**
     * @param {Number} angle Angle in radians.
     */
    Matrix4.createRotationX = function(angle) {
        const num = Math.cos(angle);
        const num2 = Math.sin(angle)
        
        const components = [
            1, 0, 0, 0,
            0, num, num2, 0,
            0, -num2, num, 0,
            0, 0, 0, 1
        ];
        
        const result = new Matrix4(components);
        return result;
    }
}

if (typeof Matrix4.createRotationY === "undefined") {
    /**
     * @param {Number} angle Angle in radians.
     */
    Matrix4.createRotationY = function(angle) {
        const num = Math.cos(angle);
        const num2 = Math.sin(angle)
        
        const components = [
            num, 0, -num2, 0,
            0, 1, 0, 0,
            num2, 0, num, 0,
            0, 0, 0, 1
        ];
        
        const result = new Matrix4(components);
        return result;
    }
}

if (typeof Matrix4.createRotationZ === "undefined") {
    /**
     * @param {Number} angle Angle in radians.
     */
    Matrix4.createRotationZ = function(angle) {
        const num = Math.cos(angle);
        const num2 = Math.sin(angle)
        
        const components = [
            num, num2, 0, 0,
            -num2, num, 0, 0,
            0, 0, 1, 0,
            0, 0, 0, 1
        ];
        
        const result = new Matrix4(components);
        return result;
    }
}

if (typeof Matrix4.createXYZRotation === "undefined") {
    /**
     * @param {Vector3} rotation Rotation vector.
     * @returns {Matrix4}
     */
    Matrix4.createXYZRotation = function(rotation) {
        const rotationX = Matrix4.createRotationX(rotation.x);
        const rotationY = Matrix4.createRotationY(rotation.y);
        const rotationZ = Matrix4.createRotationZ(rotation.z);
        
        const result = rotationX.multiply(rotationY).multiply(rotationZ);
        return result;
    }
}

if (typeof Matrix4.createTransformFromVectors === "undefined") {
    /**
     * @param {Vector3} translation
     * @param {Vector3} eulerRotation
     * @param {Vector3} scale
     */
    Matrix4.createTransformFromVectors = function(translation, eulerRotation, scale) {
        const rotationMatrix = Matrix4.createXYZRotation(eulerRotation);
        const scaleMatrix = Matrix4.scale(scale.x, scale.y, scale.y);
        
        const result = scaleMatrix.multiply(rotationMatrix);
        result.translation = translation;
        
        return result;
    }
}

export {Matrix4};
