import {AABB} from "./h3d/aabb.js";
import * as Assets_ from "./h3d/assets.js";
import * as Animations_ from "./h3d/animations.js";
import {Loader} from "./h3d/loader.js";
import * as Model_ from "./h3d/model.js";
import * as Renderer_ from "./h3d/renderer.js";
import * as SceneNodes_ from "./h3d/scenenodes.js";
import * as Serialization_ from "./h3d/serialization.js";
import {XFormNode} from "./h3d/xformnode.js";

// This is to accommodate for non-chromimum MS Edge not being able to
// "import * as..." and then export it back out again. If we save the 
// imports as a variable, we can then export them back out and Edge will
// pick them up. This also continues to work in the other major browsers.
// Should probably remove this when non-chromium Edge is no longer
// required (billy 05-02-2020).
const Animations = Animations_;
const Assets = Assets_;
const Model = Model_;
const Renderer = Renderer_;
const SceneNodes = SceneNodes_;
const Serialization = Serialization_;

export { 
    AABB,
    Assets,
    Animations, 
    Loader, 
    Model, 
    Renderer,
    SceneNodes,
    Serialization,
    XFormNode
};
