/**
 * @file General type definitions for Hal3d.
 */

/**
* Specifies the primitive type to be used for rendering draw calls.
* 
* @enum
*/
const PrimitiveType = Object.freeze({
   /**
    * Draw triangles by specifying 3 vertices at a time.
    */
   TRIANGLES: 0,

   /**
    * Draw lines by specifying 2 vertices at a time.
    */
   LINES: 1,

   /**
    * The number of primitive types
    */
   POINTS: 2
});

function PrimitiveTypeToString(type) {
  let result = "";
  
  switch (type) {
    case PrimitiveType.TRIANGLES: {
      result = "Triangles";
      break;
    }
    
    case PrimitiveType.LINES: {
      result = "Lines";
      break;
    }
    
    case PrimitiveType.POINTS: {
      result = "POINTS";
      break;
    }
    
    default: {
      console.warn(`Invalid primitive type of: '${type}'.`);
    }
  }
  
  return result;
}

export {PrimitiveType, PrimitiveTypeToString};
