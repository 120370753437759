import React from 'react';

/**
 * Image with multiple resolutions.
 */
const SourceSetImage = React.forwardRef((props, forwardedRef) => {
  if (props.images.length > 1) {
    return (
      <img
        ref={forwardedRef}
        alt={props.alt}
        src={props.images[0]}
        srcSet={props.images
          .map((source, index) => `${source} ${index + 1}x`)
          .join(', ')}
      />
    );
  } else {
    return <img ref={forwardedRef} alt={props.alt} src={props.images[0]} />;
  }
});

export default SourceSetImage;
