import {AnimationNode} from "./animationnode.js";

/**
 * This class provides an interface for animations that target a particular object.
 */
class TargetedNode extends AnimationNode {
    constructor() {
        super();
        
        /**
         * @type {string}
         */
        this.targetName = "";

        /**
         * @type {Boolean}
         */
        this.allowDelayedAttach = false;
    }
}

TargetedNode.Instance = class TargetedNodeInstance extends AnimationNode.Instance {
    constructor() {
        super();
        
        /**
         * @type {Object}
         */
        this._target = null;
    }

    get target() {
        const t = this._target ? this._target : super.target;
        return t;
    }

    _setup(target) {
        let result = super._setup(target);
        if (result) {
            const obj = this._resolveTarget(target);
            if (obj) {
                this._setTarget(obj);
            }
            else if (!this.node.allowDelayedAttach) {
                result = false;
            }
        }

        return result;
    }

    _resolveTarget(parentTarget) {
        let resolvedTarget = null;

        if (parentTarget) {
            if (this.node.targetName) {
                throw new Error("Not currently implemented.");
            }
            else {
                resolvedTarget = parentTarget;
            }
        }

        return resolvedTarget;
    }

    _setTarget(target) {
        this._target = target;
        // this._attachToTarget();  // TODO: not implemented (not necessary?)
    }
};

export {TargetedNode};
