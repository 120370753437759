class Attribute {
    constructor(name, shouldSerializeAsElement = false) {
        this.name = name;
        this.shouldSerializeAsElement = shouldSerializeAsElement;
    }
}

class IProvider {
    /**
     * Returns a collection of serial value attributes contained in this object.
     * @returns {Array<Attribute>}
     */
    getSerialValuesAttributes() {}
}

class SerialValueAttribute {
    constructor() {
        this.serializeAsElement = false;
    }
}

export {Attribute, IProvider, SerialValueAttribute};
