import React, { useEffect, useState, useContext } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import Breadcrumb from '../../../shared/Breadcrumb';
import ProductCard from '../../LandingPage/ProductCard';
import { fetchClasses } from '../../../backend/search.js';
import Skeleton from 'react-loading-skeleton';
import ApplicationContext from '../../../ApplicationContext';

const BrowsePanelContainer = styled.div`
  padding: 18px 28px;

  @media (max-width: 1024px) {
    width: 90%;
    padding: 18px 5%;
  }
`;

const LeafClassTitle = styled.p`
  font-size: 28px;
  font-weight: 500;
  color: var(--color-gray1);
  margin: 0;
`;

const BreadcrumbContainer = styled.div`
  display: flex;
  margin: 18px 0 24px 24px;

  @media (max-width: 1024px) {
    margin: 18px 0 24px 0;
  }
`;

const ProductCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: 1024px) {
    justify-content: space-between;
  }
`;

const ShowAllToolsButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ShowAllToolsButton = styled.button`
  font-family: Univers;
  color: var(--color-gray1);
  font-size: 16px;
  margin-top: 10px;
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
`;

const SplideContainer = styled(Splide)`
  height: 42px;
  width: 90%;

  padding-left: 4%;
  padding-right: 4%;

  .splide__arrow {
    background: none;
    height: 20px;
    width: 20px;
  }

  .splide__arrow--prev {
    background: var(--color-primary);
    margin-right: 20px;

    right: 88%;
    left: 0;
  }

  .splide__arrow--next {
    background: var(--color-primary);
    right: 0;
  }

  .splide__arrow svg {
    fill: #fff;
    height: 0.8em;
    width: 0.8em;
  }
`;

const SlideContainer = styled.div`
  display: flex;
  width: 100%;
  height: 42px;
  align-items: center;
  justify-content: center;
`;

const SlideLinkedButton = styled.div`
  background: none;
  border: none;
  height: 100px;
  display: flex;
  max-width: 80%;
  min-width: 100px;
  align-items: center;
  font-family: Univers;
  font-size: 14px;
  text-align: left;
  color: var(--color-primary);
  cursor: pointer;
`;

const ButtonText = styled.div`
  color: var(--color-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;
`;

const SkeletonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledSkeleton = styled(Skeleton)`
  margin: 12px;
`;

const BrowsePanel = () => {
  const [breadcrumbArray, setBreadcrumbArray] = useState([]);
  const navigate = useNavigate();
  const { classType } = useContext(ApplicationContext);

  const {
    data: classesData,
    isLoading: classesIsLoading,
    error: classesError,
  } = useQuery('fetchClasses', fetchClasses, {
    onError: (error) => {
      const errorCode = error.message.split('/');
      if (error.constructor.name === 'TypeError') {
        setError({
          title: 'Looks like something went wrong',
          message:
            'Network connection lost. Please check your network connection and try again.',
        });
      } else {
        setError({
          title: 'Looks like something went wrong',
          message:
            'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
          errorCode: `#CL-1-${errorCode[1]}`,
        });
      }
    },
  });

  const topLevelParam = decodeURIComponent(window.location.search);
  const queryString = topLevelParam.split('?')[1];
  const params = new URLSearchParams(queryString);
  const lastParam = Array.from(params.keys()).pop();

  useEffect(() => {
    const buildBreadcrumbPath = (classes, target, path = []) => {
      for (const item of classes) {
        const segments = item.ClassPath?.split('|');
        if (segments && segments.includes(target)) {
          const targetIndex = segments.indexOf(target);
          const newPath = [...path, ...segments.slice(0, targetIndex + 1)];
          return newPath;
        }
        if (item.children && item.children.length > 0) {
          const result = buildBreadcrumbPath(item.children, target, [
            ...path,
            item.Name,
          ]);
          if (result) return result;
        }
      }
      return null;
    };

    if (!classesIsLoading && classesData) {
      const breadcrumbs = buildBreadcrumbPath(classesData, lastParam);
      if (breadcrumbs) {
        const slicedBreadcrumbs = breadcrumbs.slice(2); // Remove the first two classes
        if (slicedBreadcrumbs.join() !== breadcrumbArray.join()) {
          setBreadcrumbArray(slicedBreadcrumbs);
        }
      }
    }
  }, [queryString, classesData, lastParam, breadcrumbArray, classesIsLoading]);

  const searchObjects = (array, category) => {
    let result = new Map();
    let toolCountMap = new Map();

    const accumulateToolCount = (item) => {
      if (item.children && item.children.length > 0) {
        item.children.forEach((child) => accumulateToolCount(child));
      }
      if (item.ToolCount !== undefined) {
        if (item.ClassPath) {
          let parentPath = item.ClassPath.split('|').slice(0, -1).join('|');
          if (toolCountMap.has(parentPath)) {
            toolCountMap.set(
              parentPath,
              toolCountMap.get(parentPath) + item.ToolCount
            );
          } else {
            toolCountMap.set(parentPath, item.ToolCount);
          }
        }
      }
    };

    array.forEach((item) => {
      accumulateToolCount(item);
    });

    array.forEach((item) => {
      if (item.ClassPath) {
        const segments = item.ClassPath.split('|');
        const topLevelIndex = segments?.indexOf(category);
        if (topLevelIndex !== -1 && topLevelIndex < segments?.length - 1) {
          const nextIndex = topLevelIndex + 1;
          const nextPathSegment = segments[nextIndex];
          if (nextPathSegment) {
            const classId = item.Id;
            const isLast = nextIndex === segments.length - 1;
            const imagePath = item.ImagePath;
            const toolCount = item.ToolCount || 0;
            if (toolCount > 0 || !isLast) {
              result.set(nextPathSegment, {
                isLast,
                classId,
                imagePath,
                toolCount,
              });
            }
          }
        }
      }
      if (item.children && item.children.length > 0) {
        const childrenResult = searchObjects(item.children, category);
        childrenResult.forEach((value, key) => {
          result.set(key, value);
        });
      }
    });

    // Filter out items with ToolCount of 0
    result.forEach((value, key, map) => {
      const fullPath = `${category}|${key}`;
      if (toolCountMap.has(fullPath) && toolCountMap.get(fullPath) === 0) {
        map.delete(key);
      }
    });

    return result;
  };

  const filteredClassesMap = searchObjects(classesData || [], lastParam);
  const filteredClasses = Array.from(
    filteredClassesMap,
    ([segment, isLast]) => ({
      segment,
      isLast,
    })
  );

  const handleClick = (className) => {
    const newUrl = `${topLevelParam}&${className}`;
    navigate(newUrl);
  };

  const handleRedirectToSearchPage = (classId, className, showAllTools) => {
    if (!showAllTools) {
      setBreadcrumbArray((prevBreadcrumbArray) => [
        ...prevBreadcrumbArray,
        className,
      ]);
    }

    const urlParams = showAllTools
      ? breadcrumbArray.map((breadcrumb) => encodeURIComponent(breadcrumb))
      : [...breadcrumbArray, className].map((breadcrumb) =>
          encodeURIComponent(breadcrumb)
        );

    const queryParams = urlParams.join(',');

    navigate(
      `/search?term=&resultsPerPage=10&paginatedRange=1&selectedClass=${classId}&sizeMinIdValue=&sizeMaxIdValue=&sizeMinOdValue=&sizeMaxOdValue=&sizeMinLengthValue=&sizeMaxLengthValue=&selectedCoreProductTypeValues=&selectedTopThreadValues=&selectedBottomThreadValues=&selectedConnectionValues=&selectedMaterialValues=&selectedServiceValues=&browseOptions=${queryParams}`
    );
  };

  const handleRedirectLeafClassFromLandingToSearchPage = (classId, path) => {
    const pathArray = path.split('|');
    const trimmedArray = pathArray.slice(2);
    const trimmedPath = trimmedArray.join(',');
    const encodedUrl = encodeURIComponent(trimmedPath);

    navigate(
      `/search?term=&resultsPerPage=10&paginatedRange=1&selectedClass=${classId}&sizeMinIdValue=&sizeMaxIdValue=&sizeMinOdValue=&sizeMaxOdValue=&sizeMinLengthValue=&sizeMaxLengthValue=&selectedCoreProductTypeValues=&selectedTopThreadValues=&selectedBottomThreadValues=&selectedConnectionValues=&selectedMaterialValues=&selectedServiceValues=&browseOptions=${encodedUrl}`
    );
  };

  const handleBreadcrumbClick = (clickedBreadcrumb) => {
    const clickedIndex = breadcrumbArray.indexOf(clickedBreadcrumb);

    if (clickedIndex === -1) {
      return;
    }
    const elementsBeforeClicked = breadcrumbArray.slice(0, clickedIndex + 1);
    const newUrl = `/browse?${elementsBeforeClicked.join('&')}`;
    navigate(newUrl);
  };

  const classFinder = classesData?.find(
    (classObject) =>
      classObject.Name === breadcrumbArray[breadcrumbArray.length - 1]
  );

  useEffect(() => {
    if (!classesIsLoading && filteredClasses.length < 1 && classesData) {
      const foundClass = classesData.find(
        (toolClass) => toolClass.Name === lastParam
      );
      if (foundClass) {
        handleRedirectLeafClassFromLandingToSearchPage(
          foundClass.Id,
          foundClass.ClassPath
        );
      }
    }
  }, [filteredClasses, classesIsLoading, classesData, lastParam]);

  const splideOptions = {
    type: false,
    heightRatio: 0.5,
    arrows: true,
    perPage: window.innerWidth < 400 ? 1 : 'auto',
    pagination: false,
  };

  return (
    <BrowsePanelContainer>
      <LeafClassTitle>{lastParam}</LeafClassTitle>
      <BreadcrumbContainer>
        {breadcrumbArray.length !== 0 && (
          <>
            {window.innerWidth > 400 ? (
              breadcrumbArray.map((breadcrumb, index) => (
                <Breadcrumb
                  key={index}
                  text={breadcrumb}
                  lastOfType={breadcrumbArray.length - 1 === index}
                  onClick={() => handleBreadcrumbClick(breadcrumb)}
                />
              ))
            ) : (
              <SplideContainer options={splideOptions}>
                {breadcrumbArray.map((path, index) => (
                  <SplideSlide key={index}>
                    <SlideContainer>
                      <SlideLinkedButton
                        onClick={
                          index === breadcrumbArray.length - 1
                            ? null
                            : () => handleBreadcrumbClick(path)
                        }
                      >
                        <ButtonText>{path}</ButtonText>
                      </SlideLinkedButton>
                    </SlideContainer>
                  </SplideSlide>
                ))}
              </SplideContainer>
            )}
          </>
        )}
      </BreadcrumbContainer>
      <ProductCardContainer>
        {classesIsLoading && (
          <SkeletonContainer>
            {Array.from({ length: 8 }).map((_, index) => (
              <StyledSkeleton
                key={index}
                width={classType === 'compact' ? '150px' : '190px'}
                height="238px"
              />
            ))}
          </SkeletonContainer>
        )}
        {!classesIsLoading &&
          [...new Set(filteredClasses)].map((segment, index) => (
            <ProductCard
              key={index}
              productName={segment.segment}
              marginBottom={'16px'}
              isBrowseView
              cardImage={segment.isLast.imagePath}
              onClick={
                !segment.isLast.isLast
                  ? () => handleClick(segment.segment)
                  : () =>
                      handleRedirectToSearchPage(
                        segment.isLast.classId,
                        segment.segment
                      )
              }
            />
          ))}
      </ProductCardContainer>
      <ShowAllToolsButtonContainer>
        <ShowAllToolsButton
          onClick={() =>
            handleRedirectToSearchPage(
              classFinder.Id,
              breadcrumbArray[breadcrumbArray.length - 1],
              true
            )
          }
        >
          Show All Tools in This Category
        </ShowAllToolsButton>
      </ShowAllToolsButtonContainer>
    </BrowsePanelContainer>
  );
};

export default BrowsePanel;
