import {BinaryWriter} from "./../../../base/binarywriter.js";
import {ReloadableResource} from "./reloadableresource.js";

/**
 * Encapsulates buffer data that can be used to set a renderer resource's data.
 */
class BufferData {
    /**
     * Creates a new BufferData.
     * @param {ArrayBuffer|BufferData} data Data to be set or copied.
     * @param {Boolean} makeCopy True if the data should be copied, false if it should be set.
     */
    constructor(data, makeCopy) {
        /**
         * @type {ArrayBuffer}
         */
        this.data = null;

        if (makeCopy) {
            /**
             * @type {ArrayBuffer}
             */
            let source;

            if (data instanceof BufferData) {
                source = data.data;
            }
            else {
                source = data;
            }

            this.data = new ArrayBuffer(source.byteLength);

            const writer = new BinaryWriter(this.data);
            writer.copyBytes(source);
        }
        else {
            /**
             * @type {ArrayBuffer}
             */
            let source;

            if (data instanceof BufferData) {
                source = data.data;
            }
            else {
                source = data;
            }

            this.data = source;
        }
    }
}

/**
 * The base class for renderer resources that can be serialized.
 */
class BufferResource extends ReloadableResource {
    constructor() {
        super();
    }
    
    /**
     * Creates a new buffer of the specified sizes.
     * @param {Number} sizeInBytes Size of the buffer in bytes.
     * @param {Number} structureByteSize Size of the ___ in bytes.
     */
    createDynamicBuffer(sizeInBytes, structureByteSize) {}

    /**
     * This function is used to set the buffer data of the index buffer.
     * @param {BufferData} buffer Buffer to set.
     */
    setStaticData(buffer) {}

    /**
     * Sets the buffer as dynamic data.
     * @param {Number} offsetInBytes Offset from the start of the buffer in bytes.
     * @param {BufferData} buffer Buffer to be set.
     */
    setDynamicData(offsetInBytes, buffer) {}
}

export {BufferData, BufferResource};
