import { Vector3 } from "./vector3";

class Plane {
    /**
     * @param {Vector3} normal 
     * @param {Vector3} point 
     */
    constructor(
        normal = new Vector3(0, 0, 1),
        point = Vector3.zero)
    {
        this.n = normal
        this.d = 0 - Vector3.dot(normal, point);
    }

    normalize() {
        throw new Error('Not implemented')
    }
}

export default Plane;