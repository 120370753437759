import { Plane, Vector3 } from "../../base/math";
import { Matrix4 } from "./math/matrix4";
import { Model } from "./model";
import { SerialNode } from "./serialization";
import { CutProcessor } from "./serialization/modelprocessors/cutprocessor";

// Hal.CG.Hal3d.SceneNodes.CutModelNode.cs

class CutModelNode extends SerialNode /* ModelNode */ {
    static CutMethod = {
        Fast: 0,
        Accurate: 1
    }

    /**
     * @param {number} value
     */
    set tolerance(value) {
        value = Math.max(0, value);
        if(this._tolerance !== value) {
            this._tolerance = value;
            this.rebuildCut();
        }
    }

    /**
     * @param {boolean} value
     */
    set createCaps(value) {
        if(this._createCaps !== value) {
            this._createCaps = value;
            this.rebuildCut();
        }
    }

    /**
     * @param {number} value
     */
    set cutMethod(value) {
        if(this._cutMethod !== value) {
            this._cutMethod = value;
            this.rebuildCut();
        }
    }

    /**
     * @param {Model} model 
     */
    constructor(model) {
        super();
        this.name = 'CutModelNode'

        this.originalModel = model;

        this._tolerance = 0.0001;
        this._createCaps = true;
        this._cutMethod = CutModelNode.CutMethod.Fast;
    }

    addModification(modification) {
        this.addChild(modification);
    }

    removeModification(modification) {
        this.removeChild(modification);
    }

    rebuildCut() {
        // super.reloadModel(); // ModelNode
    }

    _onNewChild(child, after) {
        super._onNewChild(child, after);

        if(child instanceof CutModelNode.Modification) {
            this.rebuildCut();
        }
    }

    _onRemoveChild(child) {
        super._onRemoveChild(child);

        if(child instanceof CutModelNode.Modification) {
            this.rebuildCut();
        }
    }

    onModelInfoChanged() { throw new Error('Not implemented') }

    /**
     * @param {CutModelNode.CutModelAssetInfo} cutInfo 
     */
    buildFastModelInfo(cutInfo) {
        const processor = new CutProcessor(this._tolerance, this._createCaps);

        for(const mod of this.getChildren(CutModelNode.Modification)) {
            const processorMod = mod.getProcessorModification();
            processor.addModification(processorMod);
        }

        cutInfo.cuts.push(processor);
    }

    /**
     * @param {CutModelNode.CutModelAssetInfo} cutInfo 
     */
    buildAccurateModelInfo(cutInfo) {
        for(const mod of this.getChildren(CutModelNode.Modification)) {
            const processor = new CutProcessor(this._tolerance, this._createCaps);

            const processorMod = mod.getProcessorModification();
            processor.addModification(processorMod);
            
            cutInfo.cuts.push(processor);
        }
    }

    /**
     * @returns {Promise<CutModelNode.CutModelAssetInfo>} 
     */
    getModelInfo() {
        if(this.originalModel !== null) {
            const cutInfo = new CutModelNode.CutModelAssetInfo(this.originalModel);

            switch(this._cutMethod) {
                case CutModelNode.CutMethod.Fast:
                    this.buildFastModelInfo(cutInfo);
                    break;
    
                case CutModelNode.CutMethod.Accurate:
                    this.buildAccurateModelInfo(cutInfo);
                    break;
    
                default:
                    throw new Error('Not implemented');
            }
    
            return cutInfo;
        }

        return null;
    }

    loadAssets() { throw new Error('Not implemented') }

    releaseAssets() { throw new Error('Not implemented') }

    releaseUncutModel() { throw new Error('Not implemented') }

    loadUncutModel() { throw new Error('Not implemented') }

    originalModelChanged() { throw new Error('Not implemented') }
}

CutModelNode.CutModelAssetInfo = class {
    /**
     * @param {Model} model 
     */
    constructor(model) {
        /**
         * @type {Model}
         */
        this.originalModel = model;

        /**
         * @type {Array<CutProcessor>}
         */
        this.cuts = [];
    }

    /**
     * @returns {Promise<Model>}
     */
    load() {
        return new Promise((resolve, reject) => {
            try {
                let cutData = this.originalModel;
    
                for(let i = 0; i < this.cuts.length; i++) {
                    cutData = this.cuts[i].processModelData(cutData);
                }
    
                const result = new Model();
                
                //... init with cutData result
    
                resolve(result);
            }
            catch(e) {
                reject(`Failed to cut model: ${e}`);
            }
        })
    }
}

CutModelNode.Modification = class extends SerialNode {
    /**
     * @abstract
     * @returns {CutProcessor.Modification} 
     */
    getProcessorModification() {}
    
    rebuildCut() {
        const node = this.getAncestor(CutModelNode);
        if(node !== null) {
            node.rebuildCut();
        }
    }
}

CutModelNode.Cut = class extends CutModelNode.Modification {
    /**
     * @param {Vector3} value
     */
    set position(value) {
        if(this._position !== value) {
            this._position = value;
            this.rebuildCut();
        }
    }

    /**
     * @param {Vector3} value
     */
    set rotation(value) {
        if(this._rotation !== value) {
            this._rotation = value;
            this.rebuildCut();
        }
    }

    /**
     * @param {Vector3} position 
     * @param {Vector3} rotation 
     */
    constructor(position, rotation) {
        super();
        this.name = 'CutModelNode.Cut';

        this._position = position;
        this._rotation = rotation;
    }

    getProcessorModification() {
        const rot = this._rotation.multiply(Math.PI / 180.0);
        const transform = Matrix4.createEulerXYZRotation(rot);
        const normal = Vector3.transform(new Vector3(0, 0, -1), transform);

        return new CutProcessor.Cut(new Plane(normal, this._position));
    }
}

CutModelNode.Union = class extends CutModelNode.Modification {
    constructor() {
        super();
        this.name = 'CutModelNode.Union';
    }

    _onNewChild(child, after) {
        super._onNewChild(child, after);

        if(child instanceof CutModelNode.Modification) {
            this.rebuildCut();
        }
    }

    _onRemoveChild(child) {
        super._onRemoveChild(child);

        if(child instanceof CutModelNode.Modification) {
            this.rebuildCut();
        }
    }

    getProcessorModification() {
        const union = new CutProcessor.Union();

        for(const mod of this.getChildren(CutModelNode.Modification)) {
            union.addModification(mod.getProcessorModification());
        }

        return union;
    }
}

CutModelNode.AxialCut = class extends CutModelNode.Modification {
    static Axis = {
        XAxis: 0,
        YAxis: 1,
        ZAxis: 2
    }

    /**
     * @param {number} value
     */
    set cutAxis(value) {
        if(this._cutAxis !== value) {
            this._cutAxis = value;
            this.rebuildCut();
        }
    }

    /**
     * @param {number} value
     */
    set startAngle(value) {
        if(this._startAngle !== value) {
            this._startAngle = value;
            this.rebuildCut();
        }
    }

    /**
     * @param {number} value
     */
    set cutAngle(value) {
        value = Math.max(0, value);
        value = Math.min(360, value)
        if(this._cutAngle !== value) {
            this._cutAngle = value;
            this.rebuildCut();
        }
    }

    constructor(
        cutAxis = CutModelNode.AxialCut.Axis.XAxis,
        startAngle = 0,
        cutAngle = 0)
    {
        super();
        this.name = 'CutModelNode.AxialCut';

        this._cutAxis = cutAxis;
        this._startAngle = startAngle;
        this._cutAngle = cutAngle;
    }

    /**
     * @returns {CutProcessor.Modification}
     */
    getProcessorModification() {
        let startNormal;
        let endNormal;
        let rotAxis;

        switch(this._cutAxis) {
            case CutModelNode.AxialCut.Axis.XAxis:
                startNormal = new Vector3(0, 0, -1);
                rotAxis = new Vector3(1, 0, 0);
                break;

            case CutModelNode.AxialCut.Axis.YAxis:
                startNormal = new Vector3(0, 0, -1);
                rotAxis = new Vector3(0, 1, 0);
                break;

            case CutModelNode.AxialCut.Axis.ZAxis:
                startNormal = new Vector3(1, 0, 0);
                rotAxis = new Vector3(0, 0, 1);
                break;

            default:
                throw new Error('Not implemented')
        }

        const rot1 = Matrix4.createFromAxisAngle(rotAxis, this._startAngle * (Math.PI / 180));
        startNormal = Vector3.transform(startNormal, rot1);

        const rot2 = Matrix4.createFromAxisAngle(rotAxis, this._cutAngle * (Math.PI / 180));
        endNormal = Vector3.transform(startNormal, rot2).multiply(-1);

        if(this._cutAngle === 0) {
            return new CutProcessor.Union();
        }
        else if(this._cutAngle === 180) {
            return new CutProcessor.Cut(new Plane(startNormal, Vector3.zero));
        }
        else if(this._cutAngle < 180) {
            const union = new CutProcessor.Union()

            const cut1 = new CutProcessor.Cut(new Plane(startNormal, Vector3.zero))
            union.addModification(cut1)
            
            const cut2 = new CutProcessor.Cut(new Plane(endNormal, Vector3.zero));
            union.addModification(cut2);
            return union;
        }
        else {
            const compoundCut = new CutProcessor.CompoundCut();

            const cut1 = new CutProcessor.Cut(new Plane(startNormal, Vector3.zero))
            compoundCut.addCut(cut1);
    
            const cut2 = new CutProcessor.Cut(new Plane(endNormal, Vector3.zero))
            compoundCut.addCut(cut2);
            return compoundCut;
        }
    }
}

export { CutModelNode }
