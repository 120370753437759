/**
 * @file Functionality as it might relate to the underlying system.
 */

import {BinaryReader} from "./../model-preview/binaryreader.js";
import {BinaryWriter} from "./binarywriter.js";
 
/**
 * The size of values on this system.
 * @enum {Number}
 */
const SizeOf = Object.freeze({
    FLOAT: 4,
    
    UINT16: 2,
    UINT: 4,
    UINT32: 4,
});
 
/**
 * Returns whether binary writes are little-endian or not.
 * @returns {Boolean} True if the system is little-endian or false otherwise.
 */
const isLittleEndian = function() {
    const testValue = -1863.1;
    
    const checkBuffer = new Float32Array(1);
    checkBuffer[0] = testValue;
    
    const testBuffer = new ArrayBuffer(SizeOf.FLOAT * 2);
    
    const testWriter = new BinaryWriter(testBuffer);
    testWriter.writeFloat32(testValue);
    
    const testReader = new BinaryReader(testBuffer);
    const readValue = testReader.readFloat32();
    
    const result = Math.abs(readValue - testValue) < 0.0001;
    return result;
};
 
 export {SizeOf, isLittleEndian};
 