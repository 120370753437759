import {BufferResource} from "./bufferresource.js";

class VertexBuffer extends BufferResource {
    constructor() {
        super();
        
        /**
         * @type {VertexBuffer.AttributeList}
         */
        this.attributes = [];

        /**
         * @type {ArrayBuffer}
         */
        this.data = null;

        /**
         * Offset in bytes to the ___.
         * @type {Number}
         */
        this.binaryOffset = 0;
    }

    get binaryData() {
        return this.data;
    }

    createDynamicBuffer(sizeInBytes, structureByteSize) {
        throw new Error("Invalid operation.");
    }

    setStaticData(buffer) {
        this.data = buffer.data;
    }

    setDynamicData(offsetInBytes, buffer) {
        throw new Error("Invalid operatoin.");
    }

    release(isReloading) {
        this.data = null;
    }
    
    _writeXmlElements(xmlDoc) {
        const elements = super._writeXmlElements(xmlDoc);
        
        this.attributes.attributes.forEach(attribute => {
            const element = xmlDoc.createElement("Attribute");
            
            const elementAttributes = new Map([
                ["Usage", VertexBuffer.Attribute.usageToString(attribute.usage)],
                ["UsageIndex", attribute.usageIndex],
                ["Type", VertexBuffer.Attribute.typeToString(attribute.type)],
                ["Count", attribute.count],
                ["Offset", attribute.offset],
                ["Stride", attribute.stride]
            ]);
            
            elementAttributes.forEach((value, name) => element.setAttribute(name, value));
            
            elements.push(element);
        });
        
        return elements;
    }
}

/**
 * Data used to define a vertex attribute of a vertex buffer.
 */
VertexBuffer.Attribute = class Attribute {
    constructor(usage = Attribute.Usage.UNKNOWN, usageIndex = 0, type = Attribute.Type.UNKNOWN, count = 0, offset = 0, stride = 0) {
        /**
         * The usage of this attribute.
         * @type {Attribute.Usage}
         */
        this.usage = usage;

        /**
         * The usage index of this attribute. Some usages can occur more than once for each vertex.
         * @type {Number}
         */
        this.usageIndex = usageIndex;

        /**
         * The data type of this attribute.
         * @type {Attribute.Type}
         */
        this.type = type;

        /**
         * The per-vertex count of this attribute. For example, if the position of a vertex was 
         * represented by 3 floats then the attribute type would be Float and the attribute count would be 3.
         * @type {Number}
         */
        this.count = count;

        /**
         * The offset (in bytes) from the start of the vertex buffer of the first occurrence of this attribute.
         * @type {Number}
         */
        this.offset = offset;

        /**
         * The stride (in bytes) that occurs between each occurrence of this attribute in the vertex buffer.
         * @type {Number}
         */
        this.stride = stride;
    }

    /**
     * Get the size of a single element of this attribute (in bytes). This is based on the type.
     */
    get elementSize() {
        let bytes = 0;

        // since this is JS and not using native types, we're just going to hard-code the byte
        // sizes and hope that they work out.

        switch (this.type) {
            case Attribute.Type.FLOAT: {
                bytes = 4;
                break;
            }

            case Attribute.Type.UINT8: {
                bytes = 4;
                break;
            }

            default: {
                break;
            }
        }

        return bytes;
    }

    /**
     * Get the size of this attribute (in bytes). This is based on the type and count.
     */
    get size() {
        return this.elementSize * this.count;
    }
};

/**
 * Creates a new attribute by copying values from another.
 * @param {VertexBuffer.Attribute} attribute Attribute to copy values from.
 */
VertexBuffer.Attribute.copyFrom = function(attribute) {
    let result = new VertexBuffer.Attribute(attribute.usage, attribute.usageIndex, attribute.type, attribute.count, attribute.offset, attribute.stride);
    return result;
};

/**
 * The usage of the attribute.
 * @enum {Number}
 */
VertexBuffer.Attribute.Usage = Object.freeze({
    /**
     * This represents an invalid usage and should not be used.
     */
    UNKNOWN: -1,

    /**
     * Indicates that the attribute contains positional information.
     */
    POSITION: 0,

    /**
     * Indicates that the attribute contains a vertex normal.
     */    
    NORMAL: 1,

    /**
     * Indicates that the attribute contains a per vertex color.
     */
    COLOR: 2,

    /**
     * Indicates that the attribute contains a vertex texture coordinate.
     */
    TEXTURE_COORDINATE: 3,

    /**
     * Indicates that the attribute contains a vertex tangent.
     */
    TANGENT: 4,

    /**
     * Indicates that the attribute contains a vertex binormal.
     */
    BINORMAL: 5,

    /**
     * Indicates that the attribute contains one or more blending indices.
     */
    BLEND_INDICES: 6,

    /**
     * Indicates that the attribute contains one or more blending weights.
     */
    BLEND_WEIGHTS: 7
});

/**
 * The data type of the attribute.
 * @enum {Number}
 */
VertexBuffer.Attribute.Type = Object.freeze({
    /**
     * This represents an invalid type and should not be used.
     */
    UNKNOWN: -1,

    /**
     * A 32-bit floating point number.
     */
    FLOAT: 1,

    /**
     * An unsigned 8-bit integer.
     */
    UINT8: 2
});

/**
 * Converts the Attribute.Usage to a string.
 * @param {VertexBuffer.Attribute.Usage} usage Usage to convert to a string.
 * @returns {string} Usage converted to a string.
 */
VertexBuffer.Attribute.usageToString = function(usage) {
    let result = "";
    
    switch (usage) {
        case VertexBuffer.Attribute.Usage.UNKNOWN: {
            result = "Unknown";
            break;
        }

        case VertexBuffer.Attribute.Usage.POSITION: {
            result = "Position";
            break;
        }
        
        case VertexBuffer.Attribute.Usage.NORMAL: {
            result = "Normal";
            break;
        }
        
        case VertexBuffer.Attribute.Usage.COLOR: {
            result = "Color";
            break;
        }
        
        case VertexBuffer.Attribute.Usage.TEXTURE_COORDINATE: {
            result = "TextureCoordinate";
            break;
        }
        
        case VertexBuffer.Attribute.Usage.TANGENT: {
            result = "Tangent";
            break;
        }
        
        case VertexBuffer.Attribute.Usage.BINORMAL: {
            result = "Binormal";
            break;
        }
        
        case VertexBuffer.Attribute.Usage.BLEND_INDICES: {
            result = "BlendIndices";
            break;
        }
        
        case VertexBuffer.Attribute.Usage.BLEND_WEIGHTS: {
            result = "BlendWeights";
            break;
        }
    }
    
    return result;
};

/**
 * Converts the Attribute.Type to a string.
 * @param {VertexBuffer.Attribute.Type} type Type to convert to string.
 * @returns {string} Type converted to string.
 */
VertexBuffer.Attribute.typeToString = function(type) {
    let result = "";
    
    switch (type) {
        case VertexBuffer.Attribute.Type.UNKNOWN: {
            result = "Unknown";
            break;
        }
        
        case VertexBuffer.Attribute.Type.FLOAT: {
            result = "Float";
            break;
        }
        
        case VertexBuffer.Attribute.Type.UINT8: {
            result = "UInt8";
            break;
        }
    }
    
    return result;
};

/**
 * A collection of vertex attributes.
 */
VertexBuffer.AttributeList = class AttributeList {
    /**
     * Creates a new attribute list.
     * @param {VertexBuffer.AttributeList|Array<VertexBuffer.Attribute>} attributes Initial attributes.
     */
    constructor(attributes = []) {
        /**
         * @type {Array<VertexBuffer.Attribute>}
         */
        this.attributes = [];
        
        if (attributes instanceof VertexBuffer.AttributeList) {
            attributes.attributes.forEach(attribute => {
                this.attributes.push( VertexBuffer.Attribute.copyFrom(attribute) );
            });
        }
        else if (Array.isArray(attributes)) {
            attributes.forEach(attribute => {
                this.attributes.push( VertexBuffer.Attribute.copyFrom(attribute) );
            });
        }
    }

    get vertexSize() {  
        let bytes = 0;

        if (this.attributes.length > 0) {
            if (this.attributes[0].stride == 0) {
                this.computeOffsetsAndStride();
            }

            bytes = this.attributes[0].stride;
        }

        return bytes;
    }

    /**
     * Computes the offsets and stride for each attribute in the collection.
     */
    computeOffsetsAndStride() {
        let offset = 0;

        this.attributes.forEach(attribute => {
            attribute.offset = offset;
            offset += attribute.size;
        });

        let stride = offset;
        this.attributes.forEach(attribute => attribute.stride = stride);
    }

    /**
     * Returns the usage count for the specified usage.
     * @param {Attribute.Usage} usage Usage category to get the count for.
     * @returns {Number} Usage count.
     */
    getAttributeUsageCount(usage) {
        let count = this.attributes.reduce((total, attribute) => {
            if (attribute.usage == usage) {
                total += 1;
            }

            return total;
        }, 0);

        return count;
    }

    /**
     * Returns the attribute that matches the usage category and usage index.
     * @param {Attribute.Usage} usage Attribute's usage category.
     * @param {Number} usageIndex Usage index of the attribute.
     * @returns {Attribute} Matching attribute or null if it doesn't exist.
     */
    getAttribute(usage, usageIndex) {
        let matchingAttribute = null;

        for (let attribute of this.attributes) {
            if (attribute.usage == usage && attribute.usageIndex == usageIndex || usageIndex == -1) {
                matchingAttribute = attribute;
                break;
            }
        }

        return matchingAttribute;
    }
    
    /**
     * Adds an attribute to the list.
     * @param {VertexBuffer.Attribute} attribute Attribute to add.
     */
    add(attribute) {
        this.attributes.push(attribute);
    }
    
    /**
     * Removes the specified attribute from the list.
     * @param {VertexButter.Attribute} attribute Attribute to be removed.
     */
    remove(attribute) {
        const index = this.attributes.indexOf(attribute);
        if (index != -1) {
            this.attribute.splice(index, 1);
        }
    }
};

export { VertexBuffer };
