import {DrawCall} from "./drawcall.js";
import {Vector3} from "./../../../base/math.js";

/**
 * Mesh interface
 */
const IMesh = Base => class extends Base {
    /**
     * @type {Array<DrawCall>}
     */
    get drawCalls() {
        return null;
    }

    /**
     * @type {Array<BoneData>}
     */
    get bones() {
        return null;
    }

    /**
     * @type {Boolean}
     */
    get isSkeletal() {
        return undefined;
    }

    /**
     * 
     * @param {Ray} ray 
     * @param {Number} tolerance 
     * @returns {TraceResult}
     */
    trace(ray, tolerance) {
        return undefined;
    }

    /**
     * 
     * @param {SpatialCuller} culler 
     * @returns {Boolean}
     */
    isInside(culler) {
        return undefined;
    }

    /**
     * @returns {Array<Vector3>} 
     */
    getAllVertexPositions() {
        return undefined;
    }
}

export {IMesh};
