/**
 * This interface is used to allow us to reference objects by name for serialization purposes.
 * @param {ObjectConstructor} Base Class to be extended.
 */
const INamedObject = Base => class INamedObject extends Base {
    /**
     * The name of this object.
     * @returns <string>
     */
    get name() {}

    /**
     * Sets this object's name.
     * @param {string} value String to set as name.
     */
    set name(value) {}
}

/**
 * This interface provides functionality for resolving named object references after deserialization.
 * @param {Object} Base Class to be extended.
 */
const INamedObjectOwner = Base => class INamedObjectOwner extends Base {
    /**
     * Get an object of the specified type with the specified name.
     * @param {string} name The name of the object to get.
     * @param {string|ObjectConstructor} type The type of the object to get.
     * @returns {Object} The object of the specified type and name, or null if one was not found.
     */
    getNamedObject(name, type) {}
}

/**
 * Used to deal with interfaces which aren't don't really exist in JS.
 */
class _NamedReference {}

/**
 * This is used to reference an object by name when serializing and resolve that named reference when deserializing.
 */
class NamedReference extends INamedObject(_NamedReference) {
    /**
     * Creates a new NamedReference with the specified type.
     * @param {Object} obj Object to store.
     * @param {ObjectConstructor|string} type Constructor or string defining the type of this reference.
     */
    constructor(obj, type) {
        super();

        /**
         * @type {ObjectConstructor|string}
         */
        this._type = type;

        /**
         * @type {Object}
         */
        this._owner = null;

        /**
         * @type {INamedObject}
         */
        this._object = null;

        this._name = "";

        this.object = obj;
    }
    
    /**
     * @returns {INamedObjectOwner}
     */
    get owner() {
        const owner = this._owner ? this._owner : null;
        return owner;
    }

    set owner(value) {
        // save off our object's name
        if (this._object) {
            this._name = this._object.name;
            this._object = null;
        }
        
        // set our new owner
        this._owner = value;

        // find our object in this owner
        this._findObject();
    }

    _findObject() {
        let obj = null;

        // find our object in this owner
        if (this._owner && this._name) {
            obj = this._owner.getNamedObject(this._name, this._type);
        }

        this._object = obj;
    }

    // --- INamedObject Conformance ---

    /**
     * The name of the object to reference.
     */
    get name() {
        const name = this._object ? this._object.name : this._name;
        return name;
    }

    set name(value) {
        this._name = value;
        this._findObject();
    }

    get object() {
        return this._object;
    }

    set object(value) {
        this._object = value;

        if (this._object) {
            this._name = this._object.name;
        }
        else {
            this._name = null;
        }
    }
}

export {INamedObject, INamedObjectOwner, NamedReference};
