import {BinaryStream} from "./../../../base/binarystream.js";
import {BinaryWriter} from "./../../../base/binarywriter.js";
import {ModelFile} from "./modelfile.js";
import * as Reflection from "./../../../base/reflection.js";
import {SerialObject} from "./serialobject.js";
import * as System from "./../../../base/system.js";

class H3DFormat {
    constructor() {}
    
    /**
     * Save the scene file data to the specified stream in the H3D format.
     * @param {ModelFile.Data} data Data to be saved.
     * @returns {Promise} Contains the saved binary data as Unit8Array on success, or null on failure.
     */
    save(data) {
        return new Promise(resolve => {
            const wrapper = new H3DFormat.SerialWrapper();
            const result = wrapper.save(data);
            
            resolve(result);
        });
    }
}

/**
 * The file extension for files in the H3D file format.
 */
H3DFormat.fileExtension = ".h3d";

H3DFormat.FIRST_COMPRESSION_VERSION = 2;
H3DFormat.FORMAT_VERSION = 2;
H3DFormat.FORMAT_BYTE_ORDER_MARK = 1;

H3DFormat.SerialWrapper = class SerialWrapper extends SerialObject {
    constructor() {
        super();
        
        /**
         * @type {ModelFile.Data}
         */
        this._data = null;
    }
    
    /**
     * Save the scene file data in the H3D format.
     * @param {ModelFile.Data} data Model file data to be saved.
     * @returns {Uint8Array} Binary data of the data in the H3D format.
     */
    save(data) {
        const xmlDoc = document.implementation.createDocument("", "", null);
        
        // TODO: make compression optional (from original).
        const isCompressed = true;
        
        const binaryData = this._combineBinaryData(data);
        
        this._data = data;
        
        /**
         * @type {Uint8Array}
         */
        let result = null;
        
        try {
            const element = this._writeXml(xmlDoc);
            xmlDoc.appendChild(element);
            
            const serializer = new XMLSerializer();
            let xmlText = serializer.serializeToString(xmlDoc);
            
            // there's a bug(?) on some browsers that the xml declaration is not
            // written out, so add it if we need to (Billy 11-25-2020).
            // REF: https://bugs.webkit.org/show_bug.cgi?id=25206
            
            if (!/^\<\?xml[^\?]*\?\>/.test(xmlText)) {
                const xmlDeclaration = `<?xml version="${xmlDoc.xmlVersion || '1.0'}" encoding="UTF-8"?>`; //xmlVersion is deprecated in Firefox https://developer.mozilla.org/en-US/docs/Web/API/Document/xmlVersion 
                xmlText = xmlDeclaration + xmlText;
            }
            
            const stream = new BinaryStream();
            
            let xmlStream = null;
            let binaryStream = null;
            
            if (isCompressed) {
                // compress the xml text
                xmlStream = pako.gzip(xmlText);
                
                // compress the binary data
                binaryStream = pako.gzip(binaryData);
            }
            else {
                console.error("uncompressed data is not currently supported.");
            }
            
            /**
             * @type {Array<function(BinaryWriter):void>}
             */
            const headerWriteSteps = [
                // Write the header tag that identifies this as an H3D file
                writer => {
                    writer.writeUint8(0);
                    writer.writeUint8("H".charCodeAt(0));
                    writer.writeUint8("3".charCodeAt(0));
                    writer.writeUint8("D".charCodeAt(0));
                },
                
                // Write the byte order mark
                writer => writer.writeUint32(H3DFormat.FORMAT_BYTE_ORDER_MARK),
                
                // Write the version
                writer => writer.writeUint32(H3DFormat.FORMAT_VERSION),
                
                // Write the data offset (this should be the total size of all header info)
                writer => writer.writeUint32(25),
                
                // Write the xml length
                writer => writer.writeUint32(xmlStream.byteLength),
                
                // Write the binary length
                writer => writer.writeUint32(binaryStream.byteLength),
            ];
            
            const headerData = new ArrayBuffer(4);
            const headerWriter = new BinaryWriter(headerData, null, null, System.isLittleEndian());
            
            headerWriteSteps.forEach(step => {
                headerWriter.seek(BinaryWriter.SeekOrigin.BEGIN, 0);
                step(headerWriter);
                
                stream.write(headerData);
            });
            
            // Write whether this is compressed
            headerWriter.seek(BinaryWriter.SeekOrigin.BEGIN, 0);
            headerWriter.writeUint8(isCompressed);
            
            const headerByteData = new Uint8Array(headerData, 0, 1);
            stream.write(headerByteData);
            
            stream.write(xmlStream);
            stream.write(binaryStream);
            
            result = stream.bytes;
        }
        catch (exception) {
            console.error(`An exception occurred while saving to the H3D format: ${exception}`);
        }
        
        return result;
    }
    
    /**
     * @param {ModelFile.Data} data Data whose binary data needs to be combined.
     * @returns {ArrayBuffer} Combined vertex and index buffer data.
     */
    _combineBinaryData(data) {
        let totalLength = 0;
        const dataObjects = [];
        
        const buffers = [...data.vertexBuffers.values(), ...data.indexBuffers.values()];//data.vertexBuffers.values().concat(data.indexBuffers.values());
        
        buffers.forEach(buffer => {
            if (Reflection.conformsTo(buffer, SerialObject.IBinaryData)) {
                totalLength += buffer.binaryData.byteLength;
                dataObjects.push(buffer);
            }
        });
        
        const result = new ArrayBuffer(totalLength);
        const writer = new BinaryWriter(result);
        
        dataObjects.forEach(buffer => {
            const binaryData = buffer.binaryData;
            buffer.binaryOffset = writer.offset;
            
            writer.copyBytes(binaryData);
        });
        
        return result;
    }
    
    _writeXmlElements(xmlDoc) {
        const elements = super._writeXmlElements(xmlDoc);
        
        const objects = new Map([
            ["VertexBuffers", Array.from(this._data.vertexBuffers.values())],
            ["IndexBuffers", Array.from(this._data.indexBuffers.values())],
            ["Materials", this._data.materials],
            ["SceneNodes", this._data.sceneNodes],
            ["Animations", this._data.animations]
        ]);
        
        objects.forEach((data, name) => {
            const element = xmlDoc.createElement(name);
            
            data.forEach(objectData => {
                const result = this._writeObjectXml(objectData, xmlDoc);
                element.appendChild(result);
            });
            
            elements.push(element);
        });
        
        // the mesh data is a little different because we need add each sub-element
        // into a mesh element under the meshes element.
        
        const meshes = xmlDoc.createElement("Meshes");
        
        this._data.meshes.forEach(mesh => {
            const element = xmlDoc.createElement("Mesh");
            
            const meshAttributes = mesh._writeXmlAttributes(xmlDoc);
            meshAttributes.forEach((value, name) => element.setAttribute(name, value));
            
            const subElements = mesh._writeXmlElements(xmlDoc);
            subElements.forEach(subElement => element.appendChild(subElement));
            
            meshes.appendChild(element);
        });
        
        elements.push(meshes);
        
        return elements;
    }
};

export {H3DFormat};
