import {IndexBuffer} from "./../renderer/indexbuffer.js";
import {Material} from "./../renderer/material.js";
import {IMesh} from "./../renderer/mesh.js";
import {StaticMesh} from "./../renderer/staticmesh.js";
// import {SceneNode} from "./../scenenodes/scenenode.js";
import {SerialNode} from "./serialnode.js";
import {TangentBinormalProcessor} from "./modelprocessors/tangentbinormalprocessor.js";
import {VertexBuffer} from "./../renderer/vertexbuffer.js";

/**
 * Controls model file loading / saving
 */
class ModelFile {
    constructor(data = null) {
        /**
         * The inner Data of this model property.
         * @type {ModelFile.Data}
         */
        this.modelData = data;
    }

    /**
     * The scene nodes in this model file. (Not supported)
     * @returns {Array<SceneNode>}
     */
    get sceneNodes() {
        return this.modelData.sceneNodes;
    }

    /**
     * The animations in this model file. (Not supported)
     * @returns {List<Animation>}
     */
    get animations() {
        return this.modelData.animations;
    }

    /**
     * The meta data in this model file.
     * @type {Map<string, string>}
     */
    get metaData() {
        return this.modelData.metaData;
    }
}

/**
 * This class provides all the data that can be contained in a model file.
 */
ModelFile.Data = class Data {
    constructor() {
        /**
         * The scene's vertex buffers and their names used for serialization.
         * @type {Map<string, VertexBuffer>}
         */
        this.vertexBuffers = new Map();

        /**
         * The scene's index buffers and their names used for serialization.
         * @type {Map<string, IndexBuffer>}
         */
        this.indexBuffers = new Map();

        /**
         * The root scene nodes contained in this scene file. (Not supported)
         * @type {Array<SceneNode>}
         */
        this.sceneNodes = [];

        /**
         * The scene's meshes and their names used for serialization.
         * @type {Map<string, StaticMesh>}
         */
        this.meshes = new Map();

        /**
         * The scene's materials and their names used for serialization.
         * @type {Map<string, Material}
         */
        this.materials = new Map();

        /**
         * The animations contained in this file. (Not supported)
         * @type {Array<Animation>}
         */
        this.animations = [];

        /**
         * The meta data about this scene file
         * @type {Map<string, string>}
         */
        this.metaData = new Map();
    }

    /**
     * Returns the named object of the given type.
     * @param {string} name Name of the object
     * @param {ObjectConstructor|string} type Type of the object
     * @returns {any} The object.
     */
    getNamedObject(name, type) {
        let result = null;

        if (name) {
            if (type == IMesh || type == StaticMesh) {
                result = this.meshes.get(name);
            }
            else if (type == Material) {
                result = this.materials.get(name);
            }
            else if (type == VertexBuffer) {
                result = this.vertexBuffers.get(name);
            }
            else if (type == IndexBuffer) {
                result = this.indexBuffers.get(name);
            }
            else {
                throw new Error(`The type '${type}' is not supported or a matching object could not be found.`);
            }
        }

        return result;
    }
    
    /**
     * Moves the non-resources to another data.
     * @param {ModelFile.Data} destination
     */
    moveNonResourcesTo(destination) {
        destination.sceneNodes = this.sceneNodes;
        this.scenNodes = null;
        
        destination.meshes = this.meshes;
        this.meshes = null;
        
        destination.materials = this.materials;
        this.materials = null;
        
        destination.animations = this.animations;
        this.animations = null;
        
        destination.metaData = this.metaData;
        this.metaData = null;
    }
};

export { ModelFile };
