import {Resource} from "./resource.js";

class ReloadableResource extends Resource {
    constructor() {
        super();
    }
    
    /**
     * @param {Boolean} isReloading True if the resource if reloading, false otherwise.
     */
    release(isReloading) {}
    
    // callReload()    // NOTE: not implemented from source, implement if needed.
}

export {ReloadableResource};
