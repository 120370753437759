import {Vector3} from "./../../base/math.js";

/**
 * An Axis Aligned Bounding Box class (AABB). This class is used to spatial sorting and
 * partitioning of meshes.
 */
class AABB {
    /**
     * Creates a new bounding box as defined by the two points.
     * @param {Vector3} a Point on edge of the bounding box.
     * @param {Vector3} b Point on opposite end of the bounding box.
     */
    constructor(a = null, b = null) {
        if (typeof b === "undefined") {
            b = a;
        }

        /**
         * Minimum value of the bounding box.
         * 
         * This value should not be directly written to, only read from.
         * @type {Vector3}
         */
        this.min = null;

        /**
         * Maximum value of the bounding box.
         * 
         * This value should not be directly written to, only read from.
         * @type {Vector3}
         */
        this.max = null;

        if (a != null) {
            this.union(a);
        }

        if (b != null) {
            this.union(b);
        }
    }

    /**
     * True if the bounding box has valid values or false otherwise.
     * @returns {Boolean}
     */
    get isValid() {
        const result = this.min != null && this.max != null;
        return result;
    }

    /**
     * Return the length of difference on the x-axis.
     * @returns {Number}
     */
    get width() {
        const result = this.max.x - this.min.x;
        return result;
    }

    /**
     * Return the length of difference on the y-axis.
     * @returns {Number}
     */
    get height() {
        const result = this.max.y - this.min.y;
        return result;
    }

    /**
     * Return the length of difference on the z-axis.
     * @returns {Number}
     */
    get depth() {
        const result = this.max.z - this.min.z;
        return result;
    }

    /**
     * Grows the bounds of the box by including the location of the point.
     * @param {Vector3} point Point to include in the bounding box.
     */
    union(point) {
        if (this.min != null) {
            this.min.x = Math.min(this.min.x, point.x);
            this.min.y = Math.min(this.min.y, point.y);
            this.min.z = Math.min(this.min.z, point.z);
        }
        else {
            this.min = new Vector3(point.x, point.y, point.z);
        }

        if (this.max != null) {
            this.max.x = Math.max(this.max.x, point.x);
            this.max.y = Math.max(this.max.y, point.y);
            this.max.z = Math.max(this.max.z, point.z);
        }
        else {
            this.max = new Vector3(point.x, point.y, point.z);
        }
    }
}

export { AABB };
