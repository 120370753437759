import React, { useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useOktaAuthContext } from '../../OktaAuthContext.js';
import { useOktaAuth } from '@okta/okta-react';
import ApplicationContext from '../../ApplicationContext';

import TemporaryProductCardImage from '../../../media/icons/product-card/placeholder_image.png';

const CardContainer = styled.button`
  background-color: #fff;
  width: 190px;
  height: 238px;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-right: ${(props) => (props.$isBrowseView ? '12px' : '40px')};
  margin-left: 6px;
  margin-bottom: ${(props) => props.$marginBottom};
  box-shadow: -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
  border: none;
  // border-radius: 12px;
  cursor: pointer;
  &:hover {
    transition: 0.2s ease-out;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.18);
    padding: 0px;
  }
  @media (max-width: 502px) {
    width: ${(props) => props.$isBrowseView && '44%'};
  }
`;

const ProductImage = styled.img`
  height: 190px;
  width: 100%;
  object-fit: cover;
  // border-top-left-radius: 10px;
  // border-top-right-radius: 10px;
`;

const ProductNameContainer = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  // display: ${(props) => (props.$isLongString ? 'block' : 'flex')};
  align-items: center;
  justify-content: center;
  position: relative;
  border-top: 1px solid var(--color-gray3);
  overflow: hidden;
  word-break: break-word;
`;

const ProductName = styled.div`
  font-family: Univers;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: var(--color-gray1);
  margin-left: 12px;
  margin-right: 12px;
`;

const ProductCard = ({
  productName,
  marginBottom,
  isBrowseView,
  onClick,
  cardImage,
}) => {
  const navigate = useNavigate();
  const { classType } = useContext(ApplicationContext);

  const { openModal } = useOktaAuthContext();
  const { authState } = useOktaAuth();

  const redirectToBrowse = (className) => {
    if (authState?.isAuthenticated && !isBrowseView) {
      navigate(`/browse?${className}`);
    } else {
      openModal(`/browse?${className}`);
    }
  };

  const isLongString =
    classType === 'compact' ? productName.length > 37 : productName.length > 47;

  const ellipsizeString = (str) => {
    const maxLength = classType === 'compact' ? 37 : 47;
    const ellipsis = '...';

    return str.slice(0, maxLength - ellipsis.length) + ellipsis;
  };

  return (
    <CardContainer
      $isBrowseView={isBrowseView}
      $marginBottom={marginBottom}
      onClick={
        isBrowseView
          ? () => onClick(productName)
          : () => redirectToBrowse(productName)
      }
    >
      <ProductImage
        alt="product image"
        src={cardImage || TemporaryProductCardImage}
      />
      <ProductNameContainer $isLongString={isLongString}>
        {' '}
        <ProductName>
          {isLongString ? ellipsizeString(productName) : productName}
        </ProductName>
      </ProductNameContainer>
    </CardContainer>
  );
};

export default ProductCard;
