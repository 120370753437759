import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

import SearchContext from '../SearchContext';

import Breadcrumb from '../../../shared/Breadcrumb';

const Container = styled.div`
  padding-bottom: 30px;
`;

const SearchParagraph = styled.p`
  font-family: Univers;
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 2px;
  color: var(--color-gray1);
  + * {
    margin-top: 0px;
    margin-bottom: 10px;
  }
`;

const OptionButton = styled.button`
  font-family: Univers;
  font-size: 16px;
  cursor: pointer;
  text-decoration: ${(props) => (props.$selected ? 'none' : 'underline')};
  color: ${(props) => (props.$selected ? 'var(--color-gray1)' : '#cc0000')};
  background-color: transparent;
  border: none;
  width: 0px;
  margin-right: 10px;
  @supports (-moz-appearance: none) {
    margin-right: 15px;
  }
`;

const BreadcrumbContainer = styled.div`
  display: flex;
  margin-top: 12px;
  margin-left: 10px;
`;

const SplideContainer = styled(Splide)`
  height: 42px;
  width: 90%;

  padding-left: 4%;
  padding-right: 4%;

  .splide__arrow {
    background: none;
    height: 20px;
    width: 20px;
  }

  .splide__arrow--prev {
    background: var(--color-primary);
    margin-right: 20px;

    right: 88%;
    left: 0;
  }

  .splide__arrow--next {
    background: var(--color-primary);
    right: 0;
  }

  .splide__arrow svg {
    fill: #fff;
    height: 0.8em;
    width: 0.8em;
  }
`;

const SlideContainer = styled.div`
  display: flex;
  width: 100%;
  height: 42px;
  align-items: center;
  justify-content: center;
`;

const SlideLinkedButton = styled.div`
  background: none;
  border: none;
  height: 100px;
  display: flex;
  max-width: 80%;
  min-width: 100px;
  align-items: center;
  font-family: Univers;
  font-size: 14px;
  text-align: left;
  color: var(--color-primary);
  cursor: pointer;
`;

const ButtonText = styled.div`
  color: var(--color-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;
`;

const SearchResultsHeader = ({
  searchTerm,
  urlRange,
  rangeStart,
  rangeEnd,
}) => {
  const [selectedOption, setSelectedOption] = useState(parseInt(urlRange));

  const { sumOfResults, onRangeFilterChange, browseOptions } =
    useContext(SearchContext);

  const navigate = useNavigate();

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onRangeFilterChange(option);
  };

  const topLevelParam = decodeURIComponent(window.location.search);
  const params = new URLSearchParams(topLevelParam.split('?')[1]);
  const browseParams = params.get('browseOptions');
  const browseOptionsArray = browseParams?.split(',');

  const handleBreadcrumbClick = (clickedBreadcrumb) => {
    const clickedIndex = browseOptionsArray.indexOf(clickedBreadcrumb);

    if (clickedIndex === -1) {
      return;
    }
    const elementsBeforeClicked = browseOptionsArray.slice(0, clickedIndex + 1);

    const newUrl = `/browse?${elementsBeforeClicked.join('&')}`;

    navigate(newUrl);
  };

  const resultRangeStart = rangeStart;
  const resultRangeEnd = rangeEnd; // skip + take

  const splideOptions = {
    type: false,
    heightRatio: 0.5,
    arrows: true,
    perPage: window.innerWidth < 400 ? 1 : 'auto',
    pagination: false,
  };

  return (
    <Container>
      <SearchParagraph>Search Results</SearchParagraph>
      {browseOptions.length !== 0 && (
        <>
          {window.innerWidth > 400 ? (
            <BreadcrumbContainer>
              {browseOptionsArray.map((breadcrumb, index) => (
                <Breadcrumb
                  key={index}
                  text={breadcrumb}
                  lastOfType={browseOptionsArray.length - 1 === index}
                  onClick={
                    browseOptionsArray.length - 1 !== index
                      ? () => handleBreadcrumbClick(breadcrumb)
                      : null
                  }
                />
              ))}
            </BreadcrumbContainer>
          ) : (
            <SplideContainer options={splideOptions}>
              {browseOptionsArray.map((path, index) => (
                <SplideSlide key={index}>
                  <SlideContainer>
                    <SlideLinkedButton
                      onClick={
                        index === browseOptionsArray.length - 1
                          ? null
                          : () => handleBreadcrumbClick(path)
                      }
                    >
                      <ButtonText> {path} </ButtonText>
                    </SlideLinkedButton>
                  </SlideContainer>
                </SplideSlide>
              ))}
            </SplideContainer>
          )}
        </>
      )}

      <p>
        Showing
        {` ${sumOfResults > 0 ? resultRangeStart : 0}-${
          sumOfResults > 0 ? Math.min(resultRangeEnd, sumOfResults) : 0
        } `}{' '}
        of {sumOfResults ? sumOfResults : 0} results{' '}
        {searchTerm && searchTerm != '' ? `for "${searchTerm}"` : ''}
      </p>
      {sumOfResults !== 0 &&
        sumOfResults !== undefined &&
        sumOfResults > 10 && (
          <div>
            Results per page:
            <OptionButton
              $selected={selectedOption === 10}
              onClick={() => handleOptionClick(10)}
            >
              10
            </OptionButton>
            <OptionButton
              $selected={selectedOption === 25}
              onClick={() => handleOptionClick(25)}
            >
              25
            </OptionButton>
            <OptionButton
              $selected={selectedOption === 50}
              onClick={() => handleOptionClick(50)}
            >
              50
            </OptionButton>
          </div>
        )}
    </Container>
  );
};

export default SearchResultsHeader;
