/**
 * Initiates a property transition by adding or removing a class;
 * @param {HTMLElement} element Element to have property transitioned.
 * @param {String} transitionClassName Class to appended or removed.
 * @return {Promise} Promise for the completion of the transition.
 */
function transition(element, transitionClassName) {
    return new Promise((resolve, reject) => {
        element.addEventListener("transitionend", e => {
            if (e.type == "transitionend") {
                resolve();
            }
            else {
                reject(new Error(`Tried to transition an element and received an event of unknown type: '${e.type}'`)); 
            }
        }, {once: true});

        switch (transitionClassName[0]) {
            case "-": {
                element.classList.remove( transitionClassName.slice(1) );
                break;
            }

            case "+": {
                transitionClassName = transitionClassName.slice(1);
                // intentionally falling through
            }

            default: {
                element.classList.add( transitionClassName );
                break;
            }
        }
    });
}

/**
 * Initiates an animation on the specified element by appending a classname.
 * @param {HTMLElement} element Element to be animated.
 * @param {String} className Classname to append to trigger animation.
 * @param {Boolean} isClassNameRemovedOnEnd Whether to remove the classname on completion.
 * @return {Promise} Promise for the completion of the animation.
 */
function animation(element, className, isClassNameRemovedOnEnd = true) {
    return new Promise((resolve, reject) => {
        element.addEventListener("animationend", e => {
            if (e.type == "animationend") {
                if (isClassNameRemovedOnEnd) {
                    element.classList.remove( className );
                }

                resolve();
            }
            else {
                reject(new Error(`Tried to animate an element and received an event of unknown type: '${e.type}'`));
            }
        }, {once: true});

        element.classList.add( className );
    });
}

function onTransitionEnd(element) {
    return new Promise((resolve, reject) => {
        element.addEventListener("transitionend", e => {
            e.stopPropagation();

            if (e.type == "transitionend") {
                resolve();
            }
            else {
                reject(new Error(`Waiting for transitionend on element but received unknown event of type: ${e.type}`));
            }
        }, {once: true});
    });
}

export {transition, animation, onTransitionEnd}
