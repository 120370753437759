import ReactModal from 'react-modal';
import React, { useRef } from 'react';

import { oktaSignIn } from '../services/okta-service';
import { useOktaAuthContext } from '../OktaAuthContext';

const OktaAuthModal = () => {
  ReactModal.setAppElement('#root');
  const widgetRef = useRef();
  const { isOpen, closeModal, redirectPath } = useOktaAuthContext();

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Auth Modal"
      shouldCloseOnOverlayClick={true}
      style={{
        overlay: {
          background: 'rgba(0, 0, 0, 0.5)',
          zIndex: '1000',
        },
        content: {
          display: 'flex',
          margin: 'auto',
          justifyContent: 'center',
          alignItems: 'center',
          outline: 'none',
          border: 'none',
          background: 'none',
          overflow: 'none',
          width: '0px',
          height: '0px',
        },
      }}
      onAfterOpen={() => {
        sessionStorage.setItem(
          'preAuthPath',

          redirectPath ?? '/'
        );

        oktaSignIn
          .showSignInAndRedirect({
            el: widgetRef.current,
            state: JSON.stringify(redirectPath),
          })
          .then((response) => {
            if (response.status === 'SUCCESS') {
              oktaSignIn.authClient.handleLoginRedirect(response.tokens);
              closeModal();
            }
          })
          .catch((error) => {
            console.error('LOGIN ERROR', error);
          });
      }}
    >
      <div ref={widgetRef} />
    </ReactModal>
  );
};

export default OktaAuthModal;
