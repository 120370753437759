import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import ApplicationContext from '../../ApplicationContext.js';

import { ChevronRight } from 'react-feather';
import { ChevronDown } from 'react-feather';

const MainContainer = styled.div`
  font-family: Univers;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 12px;
  border: 1px solid #ced6df;
  // border-radius: 10px;
  background: white;
  margin: 3px 14px;

  @media (max-width: 1024px) {
    border: 0;
    border-top: 1px solid var(--color-gray4);
    // border-radius: 0;
    margin: 0;
    padding-left: 5%;
    padding-right: 5%;
    &:last-child {
      border-bottom: 1px solid var(--color-gray4);
    }
    @media (max-width: 500px) {
      padding-left: 5%;
      padding-right: 5%;
    }
  }
`;

const QuoteDetailsRowContainerButton = styled.button`
  font-family: Univers;
  font-size: 16px;
  display: flex;
  margin-bottom: ${(props) => props.$IsRowExpanded && '12px'};
  width: 100%;

  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
`;

const ChevronAndQuoteDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 1024px) {
    align-items: flex-start;
  }
`;

const Chevron = styled.div`
  display: flex;
  margin: 0 4px 0 4px;
  @media (max-width: 1024px) {
    padding-left: 0;
  }
`;

const QuoteDetail = styled.div`
  font-weight: ${(props) => (props.$isBold ? '500' : '400')};
  color: var(--color-gray1);
  margin-right: 34px;
  overflow-wrap: anywhere;
  text-align: left;

  &:nth-of-type(1) {
    width: 80%;
  }

  &:nth-of-type(2) {
    width: 20%;
  }

  @media (max-width: 1024px) {
    margin-right: 8px;

    &:nth-of-type(3) {
      width: 70%;
    }

    &:nth-of-type(2) {
      width: 30%;
    }
  }
`;

const ProductContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 34px;
  margin-bottom: 4px;

  @media (max-width: 1024px) {
    margin-left: 0;
    width: 100%;
    margin-bottom: 20px;
  }
`;

const ProductDetail = styled.div`
  margin-right: 4px;

  &:nth-of-type(1) {
    color: #6c727d;
    width: 20%;

    @media (max-width: 1024px) {
      width: unset;
      font-weight: 400;
      color: var(--color-gray1);
    }
  }

  &:nth-of-type(2) {
    color: #008185;
    width: 20%;

    @media (max-width: 1024px) {
      width: 10%;
      font-weight: 500;
    }
  }
`;

const ProductNameButton = styled.button`
  font-family: Univers;
  font-size: 16px;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  text-align: left;

  color: var(--color-gray1);
  font-weight: 500;
  width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 1024px) {
    font-weight: 400;
    width: unset;
    white-space: initial;
    overflow: initial;
    text-overflow: initial;
    overflow-wrap: anywhere;
  }
`;

const MobileQuantity = styled.div`
  font-weight: 500;
  width: 15%;
  color: var(--color-gray1);
`;

const MobileProductNameAndNumber = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
`;

const PreviousQuotesCluster = ({ data }) => {
  const [isRowExpanded, setIsRowExpanded] = useState(false);

  const { classType } = useContext(ApplicationContext);

  const isMobile = classType === 'compact';

  const navigate = useNavigate();

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    return date.toLocaleDateString('en-US');
  };

  const handleRedirectToToolDetailsPage = (sap) => {
    navigate(`/tool/${sap}`);
  };

  return (
    <MainContainer>
      <QuoteDetailsRowContainerButton
        onClick={() => setIsRowExpanded((prev) => !prev)}
        $IsRowExpanded={isRowExpanded}
        aria-label={isRowExpanded ? 'expand row' : 'minimize row'}
      >
        <ChevronAndQuoteDetailsContainer>
          <Chevron>
            {isRowExpanded ? (
              <ChevronDown size={20} color={'var(--color-primary)'} />
            ) : (
              <ChevronRight size={20} color={'var(--color-gray2)'} />
            )}
          </Chevron>
          <QuoteDetail $isBold>
            {!isMobile && 'Request Date: '}
            {formatDate(data.DateTime)}
          </QuoteDetail>
          <QuoteDetail $isBold={isMobile}>
            {isMobile ? 'Request# ' : 'REQUEST# '}
            {data.OrderNumber || data.id.replace(/-/g, '')}
          </QuoteDetail>
        </ChevronAndQuoteDetailsContainer>
      </QuoteDetailsRowContainerButton>
      {isRowExpanded && (
        <>
          {' '}
          {data.Products.map((product) => (
            <ProductContainer key={product.SapNumber}>
              {isMobile ? (
                <MobileProductNameAndNumber>
                  {' '}
                  <ProductNameButton
                    onClick={() =>
                      handleRedirectToToolDetailsPage(product.SapNumber)
                    }
                  >
                    {product.Name}
                  </ProductNameButton>
                  <ProductDetail>MAT# {product.SapNumber}</ProductDetail>
                </MobileProductNameAndNumber>
              ) : (
                <>
                  {' '}
                  <ProductNameButton
                    onClick={() =>
                      handleRedirectToToolDetailsPage(product.SapNumber)
                    }
                  >
                    {product.Name}
                  </ProductNameButton>
                  <ProductDetail>MAT# {product.SapNumber}</ProductDetail>
                </>
              )}
              {isMobile ? (
                <MobileQuantity>Qty {product.Quantity}</MobileQuantity>
              ) : (
                <ProductDetail>QUANTITY {product.Quantity}</ProductDetail>
              )}
            </ProductContainer>
          ))}
        </>
      )}
    </MainContainer>
  );
};

export default PreviousQuotesCluster;
