import * as siteConfig from '../siteconfig';
import { oktaAuth } from '../services/okta-service';
import { appInsights } from '../appInsights.js';

export const addItemToShoppingCart = async (
  productData,
  sapNumber,
  quantity
) => {
  const postData = {
    productData: productData,
    sap: sapNumber,
    quantity: quantity,
  };
  const accessToken = (await oktaAuth.tokenManager.get('accessToken'))
    ?.accessToken;
  const idToken = (await oktaAuth.tokenManager.get('idToken'))?.idToken;
  try {
    const response = await fetch(
      `${siteConfig.searchApiUrl}/shoppingcart/setproductquantity`,
      {
        method: 'POST',
        headers: {
          accept: 'text/plain',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          'X-OktaIdToken': idToken,
        },
        body: JSON.stringify(postData),
      }
    );

    if (!response.ok) {
      throw new Error(
        `Failed to add item to shopping cart /${response.status}/`
      );
    }
    return response.json();
  } catch (error) {
    appInsights.trackException({ error });
    throw error;
  }
};

export const batchAddItemToShoppingCart = async (batchArray) => {
  const accessToken = (await oktaAuth.tokenManager.get('accessToken'))
    ?.accessToken;
  const idToken = (await oktaAuth.tokenManager.get('idToken'))?.idToken;
  try {
    const response = await fetch(
      `${siteConfig.searchApiUrl}/shoppingcart/batchadd`,
      {
        method: 'POST',
        headers: {
          accept: 'text/plain',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          'X-OktaIdToken': idToken,
        },
        body: JSON.stringify(batchArray),
      }
    );

    if (!response.ok) {
      const errorResponse = await response.text();

      throw new Error(
        response.status !== 400
          ? `Failed to batch add item to shopping cart /${response.status}/`
          : errorResponse
      );
    }
    return response.json();
  } catch (error) {
    appInsights.trackException({ error });
    throw error;
  }
};

export const fetchShoppingCartItems = async () => {
  const accessToken = (await oktaAuth.tokenManager.get('accessToken'))
    ?.accessToken;
  const idToken = (await oktaAuth.tokenManager.get('idToken'))?.idToken;

  try {
    const response = await fetch(
      `${siteConfig.searchApiUrl}/shoppingcart/allproducts`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-OktaIdToken': idToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(
        `Failed to fetch shopping cart items /${response.status}/`
      );
    }
    return response.json();
  } catch (error) {
    appInsights.trackException({ error });
    throw error;
  }
};

export const removeItemFromShoppingCart = async (sapNumber, quantity) => {
  const accessToken = (await oktaAuth.tokenManager.get('accessToken'))
    ?.accessToken;
  const idToken = (await oktaAuth.tokenManager.get('idToken'))?.idToken;
  try {
    const response = await fetch(
      `${siteConfig.searchApiUrl}/shoppingcart/removeproduct/${sapNumber}?quantity=${quantity}`,
      {
        method: 'DELETE',
        headers: {
          accept: 'text/plain',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          'X-OktaIdToken': idToken,
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        `Failed to remove item from shopping cart /${response.status}/`
      );
    }
    return response.json();
  } catch (error) {
    appInsights.trackException({ error });
    throw error;
  }
};

export const removeAllItemsFromShoppingCart = async () => {
  const accessToken = (await oktaAuth.tokenManager.get('accessToken'))
    ?.accessToken;
  const idToken = (await oktaAuth.tokenManager.get('idToken'))?.idToken;
  try {
    const response = await fetch(
      `${siteConfig.searchApiUrl}/shoppingcart/removeallproducts`,
      {
        method: 'DELETE',
        headers: {
          accept: 'text/plain',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          'X-OktaIdToken': idToken,
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        `Failed to remove all items from shopping cart /${response.status}/`
      );
    }
    return response.json();
  } catch (error) {
    appInsights.trackException({ error });
    throw error;
  }
};

export const requestQuote = async () => {
  const accessToken = (await oktaAuth.tokenManager.get('accessToken'))
    ?.accessToken;
  const idToken = (await oktaAuth.tokenManager.get('idToken'))?.idToken;
  try {
    const response = await fetch(
      `${siteConfig.searchApiUrl}/shoppingcart/requestQuote`,
      {
        method: 'POST',
        headers: {
          accept: 'text/plain',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          'X-OktaIdToken': idToken,
        },
        body: null,
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to request quote /${response.status}/`);
    }
    return response.json();
  } catch (error) {
    appInsights.trackException({ error });
    throw error;
  }
};
