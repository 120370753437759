/**
 * Node representing a 1:M relationship.
 */
class TreeNode {
    constructor(value = null) {
        /**
         * @type {any}
         */
        this.value = value;

        /**
         * @type {TreeNode}
         */
        this.parent = null;

        /**
         * @type {Array<TreeNode>}
         */
        this.childNodes = [];
    }

    /**
     * Adds the specified node as a child of this node.
     * @param {TreeNode} node Node to as a child.
     */
    addChild(node) {
        if (node.parent != this) {
            if (node.parent) {
                node.removeFromParent();
            }
    
            node.parent = this;
            this.childNodes.push(node);
        }
    }

    /**
     * Removes this node from its parent's list of child nodes.
     */
    removeFromParent() {
        if (this.parent) {
            const index = this.parent.childNodes.indexOf(this);
            if (index != -1) {
                this.parent.childNodes.splice(index, 1);
                this.parent = null;
            }
        }
    }
    
    /**
     * Returns an array of TreeNodes whose values satisfy the provided testing function.
     * @param {function(any):Boolean} callback Testing function.
     * @returns {Array<TreeNode>} Array of satisfied TreeNodes.
     */
    filter(callback) {
        /**
         * @type {Array<TreeNode>}
         */
        let matches = [];
        
        this.depthFirst().forEach(node => {
            if (node.value && callback(node.value)) {
                matches.push(node);
            }
        });
        
        return matches;
    }
    
    /**
     * Returns the first TreeNode whose value satisfies the provided testing function.
     * @param {function(any):Boolean} callback Testing function.
     * @returns {TreeNode|null} TreeNode with satisfying value or null if one could not be found.
     */
    find(callback) {
        let match = null;
        
        for (const node of this.depthFirst()) {
            if (node.value && callback(node.value)) {
                match = node;
                break;
            }
        }
        
        return match;
    }
    
    /**
     * Returns an ancestors generator.
     */
    * ancestors() {
        let curNode = this.parent;

        while (curNode) {
            yield curNode;
            curNode = curNode.parent;
        }

        return curNode;
    }

    /**
     * Returns a depth-first generator.
     */
    * depthFirst() {
        const iterationStack = [{node: this, index: 0, visitedRoot: false}];

        while (iterationStack.length > 0) {
            const entry = iterationStack[iterationStack.length - 1];

            if (entry.index < entry.node.childNodes.length) {
                const nextNode = entry.node.childNodes[entry.index];
                entry.index += 1;

                iterationStack.push({node: nextNode, index: 0, visitedRoot: false});
            }
            else if (!entry.visitedRoot) {
                entry.visitedRoot = true;
                yield entry.node;
            }
            else {
                iterationStack.pop();

                // unwind until we hit a valid node or we run out of nodes.
                while (iterationStack.length > 0) {
                    const lastEntry = iterationStack[iterationStack.length - 1];
                    if (lastEntry.node || lastEntry.index < lastEntry.node.childNodes.length) {
                        break;
                    }
                    else {
                        iterationStack.pop();
                    }
                }
            }
        }
    }

    /**
     * Returns a breadth-first generator.
     */
    * breadthFirst() {
        /**
         * @type {Array<Node>}
         */
        const iterationStack = [this];

        while (iterationStack.length > 0) {
            const curNode = iterationStack[0];
            yield curNode;

            curNode.childNodes.forEach(childNode => iterationStack.push(childNode));
            iterationStack.shift();
        }
    }
}

function* depth_first_iterator(rootNode) {
    let node = null;

    for (let childIndex = 0; childIndex < rootNode.childNodes.length; ++childIndex) {
        node = rootNode.childNodes[childIndex];
        yield;
    }

    node = rootNode;
    yield;

    return node;
}

export { TreeNode };
