import React, { useEffect, useContext, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';

import ApplicationContext from '../../ApplicationContext.js';
import { ErrorModalContext } from '../../Modals/ErrorModal.jsx';
import ErrorModal from '../../Modals/ErrorModal.jsx';
import { useOktaAuthContext } from '../../OktaAuthContext.js';
import { Check } from 'react-feather';
import Spinner from '../../shared/Loader/Spinner';

import {
  addItemToShoppingCart,
  removeItemFromShoppingCart,
} from '../../backend/shoppingcart.js';

const StyledForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: end;

  @media (max-width: 1024px) {
    justify-content: space-between;
  }
`;

const StyledCustomInput = styled.input`
  width: 52px;
  height: 30px;
  // border-radius: 6px;
  border: solid 1px var(--color-gray3);
  box-sizing: border-box;
  font-family: Univers;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: var(--color-gray1);
  padding: 0;
  margin-left: 4px;
`;

const NumericInputLabel = styled.label`
  font-family: Univers;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: var(--color-gray1);
`;

const UpdateItemButton = styled.button`
  font-family: Univers;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: ${(props) => (props.$isRemove ? 'var(--color-primary)' : '#fff')};
  width: ${(props) => props.$width ?? '182px'};
  height: ${(props) => props.$height ?? '40px'};
  // border-radius: 10px;
  background-color: ${(props) =>
    props.$isRemove ? '#fff' : 'var(--color-primary)'};
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin-left: 12px;
  cursor: pointer;
  &:disabled {
    color: ${(props) =>
      props.$grayedOut ? 'var(--color-gray3)' : 'var(--color-primary)'};
    border: ${(props) =>
      props.$isRemove
        ? 'none'
        : props.$grayedOut
        ? '1px solid var(--color-gray3)'
        : '1px solid var(--color-primary)'};
    background-color: #fff;
    cursor: default;
  }

  @media (max-width: 1024px) {
    font-size: ${(props) =>
      props.$isShoppingCartView && !props.$isRemove && '12px'};

    background-color: ${(props) =>
      (props.$isSuggestedItems || props.$isCheckMark) && '#fff'};
    color: ${(props) =>
      props.$isInCart
        ? '#008185 !important'
        : props.$isSuggestedItems || props.$isRemove
        ? 'var(--color-primary)'
        : ' #fff'};

    width: ${(props) =>
      props.$isSuggestedItems ? '70px' : props.$width ? props.$width : '20px'};
    margin: ${(props) => props.$isSuggestedItems && !props.$width && '0'};
    border: none !important;
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  z-index: 99;
`;

const UpdateItemInCartForm = ({
  toolData,
  oktaRedirectOnLoginTo,
  buttonWidth,
  buttonHeight,
  isShoppingCartView,
  isSuggestedItems,
}) => {
  const {
    shoppingCartItemsData,
    updateCartItemsNumber,
    updateShoppingCartData,
    classType,
  } = useContext(ApplicationContext);

  const [, setError] = useContext(ErrorModalContext);
  const { openModal } = useOktaAuthContext();

  const [inputValue, setInputValue] = useState(1);
  const [isAddToCartButtonDisabled, setAddToCartButtonDisabled] =
    useState(false);
  const [isAddToCartInputDisabled, setAddToCartInputDisabled] = useState(false);
  const [quantityInCart, setQuantityInCart] = useState(0);
  const [userInputDifferenceWithCart, setUserInputDifferenceWithCart] =
    useState(0);
  const [isRemoving, setIsRemoving] = useState(false);

  const isInCart = quantityInCart && quantityInCart > 0;

  const handleMutationError = (
    error,
    oktaRedirectOnLoginTo,
    errorCodePrefix
  ) => {
    const errorCode = error.message.split('/');
    if (error.constructor.name === 'TypeError') {
      setError({
        title: 'Looks like something went wrong',
        message:
          'Network connection lost. Please check your network connection and try again.',
      });
    } else if (errorCode[1] === '401') {
      openModal(oktaRedirectOnLoginTo);
    } else {
      setError({
        title: 'Looks like something went wrong',
        message: `We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below`,
        errorCode: `#${errorCodePrefix}-${errorCode[1]}`,
      });
    }
  };

  const { mutate: mutateShoppingCartList, isLoading: isAddingToShoppingCart } =
    useMutation(
      (data) =>
        addItemToShoppingCart(
          data.productData,
          data.sap.toString(),
          data.quantity
        ),
      {
        onError: (error) =>
          handleMutationError(error, oktaRedirectOnLoginTo, 'SC-2'),
        onSuccess: (response) => {
          updateShoppingCartData(response);
          updateCartItemsNumber(response.length);
        },
      }
    );

  const {
    mutate: removeFromShoppingCartList,
    isLoading: isRemovingFromShoppingCart,
  } = useMutation(
    (data) => removeItemFromShoppingCart(data.sap.toString(), data.quantity),
    {
      onError: (error) =>
        handleMutationError(error, oktaRedirectOnLoginTo, 'SC-3'),
      onSuccess: (response) => {
        updateShoppingCartData(response);
        updateCartItemsNumber(response.length);
      },
    }
  );

  useEffect(() => {
    const toolQuantityInCart =
      shoppingCartItemsData?.find(
        (tool) => tool.SapNumber?.toString() === toolData.SapNumber?.toString()
      )?.Quantity || 0;
    setQuantityInCart(toolQuantityInCart);

    const isCurrentlyInCart = toolQuantityInCart && toolQuantityInCart > 0;
    const newInputValue =
      isAddingToShoppingCart || isRemovingFromShoppingCart
        ? inputValue
        : toolQuantityInCart || 1;

    setAddToCartInputDisabled(
      isAddingToShoppingCart ||
        !toolData ||
        !shoppingCartItemsData ||
        isRemovingFromShoppingCart
    );
    setAddToCartButtonDisabled(
      isAddingToShoppingCart ||
        !toolData ||
        !shoppingCartItemsData ||
        !!toolQuantityInCart ||
        isRemovingFromShoppingCart
    );

    setInputValue(newInputValue);

    const currentUserInputDifferenceWithCart = isCurrentlyInCart
      ? (parseInt(newInputValue) || 1) - toolQuantityInCart
      : 0;
    setUserInputDifferenceWithCart(currentUserInputDifferenceWithCart);
  }, [
    isAddingToShoppingCart,
    toolData,
    shoppingCartItemsData,
    isRemovingFromShoppingCart,
  ]);

  const handleUserInput = (event) => {
    const newValue = event.target.value;
    const newIntValue = parseInt(newValue);

    if ((newValue.length > 1 && newValue.startsWith('0')) || newValue === '') {
      setInputValue('');
    } else if (
      !isNaN(newIntValue) &&
      (isInCart ? newIntValue >= 0 : newIntValue > 0)
    ) {
      setInputValue(newValue);

      if (isInCart && quantityInCart) {
        setUserInputDifferenceWithCart(newIntValue - parseInt(quantityInCart));
      }
    }
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (!isInCart) {
      mutateShoppingCartList({
        sap: toolData?.SapNumber,
        productData: toolData?.Name,
        quantity: inputValue,
      });
    } else if (userInputDifferenceWithCart > 0) {
      mutateShoppingCartList({
        sap: toolData?.SapNumber,
        productData: toolData?.Name,
        quantity: inputValue,
      });
    } else if (userInputDifferenceWithCart < 0) {
      removeFromShoppingCartList({
        sap: toolData?.SapNumber,
        quantity: -userInputDifferenceWithCart,
      });
    }
  };

  const handleRemoveItem = () => {
    setIsRemoving(true);
    removeFromShoppingCartList({
      sap: toolData?.SapNumber,
      quantity: quantityInCart,
    });
  };

  const getUpdateButtonText = () => {
    if (isInCart) {
      if (isRemovingFromShoppingCart || isAddingToShoppingCart) {
        return isRemoving ? (
          classType === 'compact' ? (
            <SpinnerContainer>
              <Spinner active extraSmall={true} />
            </SpinnerContainer>
          ) : (
            'Removing...'
          )
        ) : classType === 'compact' ? (
          <SpinnerContainer>
            <Spinner active extraSmall={true} />
          </SpinnerContainer>
        ) : (
          'Updating...'
        );
      } else if (isShoppingCartView && userInputDifferenceWithCart === 0) {
        return classType === 'compact' ? 'X' : 'Remove';
      } else {
        return userInputDifferenceWithCart !== 0 ? (
          classType === 'compact' && !isSuggestedItems ? (
            <Check color={'var(--color-primary)'} size={16} strokeWidth={4} />
          ) : (
            'Update Qty'
          )
        ) : isInCart ? (
          'In Cart'
        ) : (
          ''
        );
      }
    } else {
      return isAddingToShoppingCart ? 'Adding...' : 'Add to Cart';
    }
  };

  return (
    <>
      <ErrorModal />
      <StyledForm onSubmit={onFormSubmit}>
        <NumericInputLabel>
          Qty
          <StyledCustomInput
            disabled={isAddToCartInputDisabled}
            type="number"
            min={0}
            value={inputValue}
            step={1}
            onInput={handleUserInput}
          />
        </NumericInputLabel>

        <UpdateItemButton
          $isSuggestedItems={isSuggestedItems}
          $isShoppingCartView={isShoppingCartView}
          $isInCart={getUpdateButtonText() === 'In Cart'}
          $isCheckMark={
            userInputDifferenceWithCart !== 0 && classType === 'compact'
          }
          $isRemove={
            getUpdateButtonText() === 'Remove' || getUpdateButtonText() === 'X'
          }
          $width={getUpdateButtonText() === 'X' ? '20px' : buttonWidth}
          $height={buttonHeight}
          $grayedOut={isAddingToShoppingCart || isRemovingFromShoppingCart}
          disabled={
            getUpdateButtonText() !== 'Remove' &&
            getUpdateButtonText() !== 'X' &&
            isAddToCartButtonDisabled &&
            (!isInCart || userInputDifferenceWithCart === 0)
          }
          onClick={
            isShoppingCartView && userInputDifferenceWithCart === 0
              ? handleRemoveItem
              : undefined
          }
        >
          {getUpdateButtonText()}
        </UpdateItemButton>
      </StyledForm>
    </>
  );
};

export default UpdateItemInCartForm;
