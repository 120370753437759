import * as siteConfig from '../siteconfig';
import { oktaAuth } from '../services/okta-service';
import { appInsights } from '../appInsights.js';

// Search Results page calls

export const searchForTool = async (
  keyword,
  resultsPerPage,
  calculatedSkip
) => {
  const postData = {
    skip: calculatedSkip, //pagination
    take: resultsPerPage, // results per page
    keyword: keyword,
    responseFields: [
      'SapNumber', // Material Number
      'LegacyNumber',
      'ProductDescription', // Name of the tool
      "Properties['Description']",
      'DescriptionLite[0]',
      'DescriptionLite[1]',
      'DescriptionLite[2]',
      'DescriptionLite[3]',
      'DescriptionLite[4]',
      'DescriptionLite[5]',
      'DescriptionLite[6]',
      'DescriptionLite[7]',
      'DescriptionLite[8]',
      'DescriptionLite[9]',
      'DescriptionLite[10]',
    ],
    propertyFilters: {},
    rangePropertyFilters: {},
  };
  const accessToken = (await oktaAuth.tokenManager.get('accessToken'))
    ?.accessToken;
  try {
    const response = await fetch(
      `${siteConfig.searchApiUrl}/tools/filteredsearch`,
      {
        method: 'POST',
        headers: {
          accept: 'text/plain',

          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(postData),
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to search for tools /${response.status}/`);
    }
    return response.json();
  } catch (error) {
    appInsights.trackException({ error });
    throw error;
  }
};

export const searchWithinClasses = async (keyword) => {
  const accessToken = (await oktaAuth.tokenManager.get('accessToken'))
    ?.accessToken;
  try {
    const response = await fetch(
      `${siteConfig.searchApiUrl}/tools/search/classes?keyword=${keyword}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          keyword: keyword,
        },
      }
    );
    if (!response.ok) {
      throw new Error(
        `Failed to search for tools within classes /${response.status}/`
      );
    }
    return response.json();
  } catch (error) {
    appInsights.trackException({ error });
    throw error;
  }
};

export const fetchClasses = async () => {
  const accessToken = (await oktaAuth.tokenManager.get('accessToken'))
    ?.accessToken;
  try {
    const response = await fetch(`${siteConfig.searchApiUrl}/classes`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (!response.ok) {
      throw new Error(`Failed to fetch classes /${response.status}/`);
    }
    return response.json();
  } catch (error) {
    appInsights.trackException({ error });
    throw error;
  }
};
