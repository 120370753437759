import {AABB} from "../aabb.js";
import {AffineTransform} from "../../../base/math.js";
import {DrawCall} from "./drawcall.js";
import {ISpatialObject} from "./spatialobject.js";
import {IUniformSource} from "./uniform.js";

/**
 * This is required to support out interface style classes, since they need
 * to be based off something. Not ideal, but I can't think of a better way
 * (Billy 11-9-2020).
 */
class _RenderCall {}

/**
 * This class represents a single unit of work to be completed by the renderer.
 */
class RenderCall extends ISpatialObject(_RenderCall) {
    constructor() {
        super();

        /**
         * @type {Object}
         */
        this.owner = null;

        /**
         * @type {Number}
         */
        this.spacialVersion = 0;

        /**
         * The actual geometry to be rendered.
         * @type {DrawCall}
         */
        this.drawCall = null;

        /**
         * The transform matrix to transform the geometry into world space.
         */
        this.modelMatrix = AffineTransform.Matrix4.identity;

        this.materialName = "";

        this._syncInlineMaterial = false;
        this._inlineMaterial = null;

        this.material = null;
        this.vertexProcessor = null;

        /**
         * @type {Array<IUniformSource>}
         */
        this.uniforms = [];

        /**
         * @type {AffineTransform.Matrix4}
         */
        this._renderMatrix = null;

        /**
         * @type {AABB}
         */
        this._renderBounds = null;
    }

    // --- ISpatialObject Conformance ---

    get wsTransform() {
        return this.modelMatrix;
    }

    get wsBounds() {
        return this.wsBounds;
    }

    get renderWsTransform() {
        const transform = this._renderMatrix ? this._renderMatrix : this.wsTransform;
        return transform;
    }

    get renderWsBounds() {
        const bounds = this._renderBounds ? this._renderMatrix : this.wsBounds;
        return bounds;
    }

    syncSpatialData() {
        this._renderMatrix = this.wsTransform;
        this._renderBounds = new AABB(this.wsBounds);
    }
};

export { RenderCall };
