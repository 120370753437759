import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  font-family: Univers;
  background-color: var(--color-gray1);
  height: 80px;
  padding-top: 20px;
  padding-right: 112px;
  padding-left: 112px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 820px) {
    padding-right: 0;
    padding-left: 0;
  }
`;

const FooterLinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 400px) {
    padding-right: 2%;
    padding-left: 2%;
  }
`;

const FooterLink = styled.a`
  color: var(--color-gray5);
  padding-bottom: 10px;
  padding-right: 3px;
  cursor: pointer;
  font-weight: 100;
  font-size: 16px;
  text-decoration: none;
`;

const HalInfo = styled.p`
  color: #9ea6b5;
  font-size: 16px;
  font-weight: 200;
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <FooterContainer>
      <FooterLinksContainer>
        <FooterLink
          href="https://www.halliburton.com/en/about-us/corporate-governance/terms-and-conditions"
          target="_blank"
        >
          Terms and Conditions |
        </FooterLink>
        <FooterLink
          href="https://www.halliburton.com/en/about-us/corporate-governance/privacy-policy"
          target="_blank"
        >
          Privacy Policy
        </FooterLink>
      </FooterLinksContainer>
      <HalInfo>2000-{currentYear} Halliburton All Rights Reserved</HalInfo>
    </FooterContainer>
  );
};

export default Footer;
