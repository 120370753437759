
class BinaryWriter {
    /**
     * Creates a new BinaryWriter.
     * @param {ArrayBuffer} buffer Data buffer.
     * @param {Number} byteOffset Number of bytes to offset from the start of the buffer.
     * @param {Number} byteLength Length of the data in bytes from the offset.
     * @param {Boolean} isLittleEndian Whether the data is little edian or big endian.
     */
    constructor(buffer, byteOffset, byteLength, isLittleEndian = false) {
        if (!byteOffset || typeof byteOffset === "undefined") {
            byteOffset = 0;
        }

        if (!byteLength || typeof byteLength === "undefined") {
            byteLength = buffer.byteLength - byteOffset;
        }

        this._view = new DataView(buffer, byteOffset, byteLength);
        this._offset = 0;

        this._useLittleEndian = isLittleEndian;
    }

    get useLittleEndian() {
        return this._useLittleEndian;
    }

    set useLittleEndian(use) {
        this._useLittleEndian = use;
    }

    get offset() {
        return this._offset;
    }

    get byteLength() {
        return this._view.byteLength;
    }

    /**
     * Writes a 64-bit signed integer to the current position.
     * @param {Number} Value to write as 64-bit signed integer.
     */
    writeBigInt64(value) {
        this._view.setBigInt64(this._offset, value, this._useLittleEndian);
        this._offset += 8;
    }

    /**
     * Write a 64-bit unsigned integer to the current position.
     * @param {Number} Value to write as 64-bit unsigned integer.
     */
    writeBigUInt64(value) {
        this._view.setBigUint64(this._offset, value, this._useLittleEndian);
        this._offset += 8;
    }

    /**
     * Writes a 32-bit signed float to the current position.
     * @param {Number} Value to write as 32-bit signed float.
     */
    writeFloat32(value) {
        this._view.setFloat32(this._offset, value, this._useLittleEndian);
        this._offset += 4;
    }

    /**
     * Writes a 64-bit signed float to the current position.
     * @param {Number} Value to write as 64-bit signed float.
     */
    writeFloat64(value) {
        this._view.setFloat64(this._offset, value, this._useLittleEndian);
        this._offset += 8;
    }

    /**
     * Writes a 8-bit signed integer to the current position.
     * @param {Number} Value to write as 8-bit signed integer.
     */
    writeInt8(value) {
        this._view.setInt8(this._offset, value, this._useLittleEndian);
        this._offset += 1;
    }

    /**
     * Writes a 16-bit signed integer to the current position.
     * @param {Number} Value to write as 16-bit signed integer.
     */
    writeInt16(value) {
        this._view.setInt16(this._offset, value, this._useLittleEndian);
        this._offset += 2;
    }

    /**
     * Writes a 32-bit signed integer to the current position.
     * @param {Number} Value to write as 32-bit signed integer.
     */
    writeInt32(value) {
        this._view.setInt32(this._offset, value, this._useLittleEndian);
        this._offset += 4;
    }

    /**
     * Writes a 8-bit unsigned integer to the current position.
     * @param {Number} Value to write as 8-bit unsigned integer.
     */
    writeUint8(value) {
        this._view.setUint8(this._offset, value, this._useLittleEndian);
        this._offset += 1;
    }

    /**
     * Writes a 16-bit unsigned integer to the current position.
     * @param {Number} Value to write as 16-bit unsigned integer.
     */
    writeUint16(value) {
        this._view.setUint16(this._offset, value, this._useLittleEndian);
        this._offset += 2;
    }

    /**
     * Writes a 32-bit unsigned integer from the current position.
     * @param {Number} Value to write as 32-bit unsigned integer.
     */
    writeUint32(value) {
        this._view.setUint32(this._offset, value, this._useLittleEndian);
        this._offset += 4;
    }

    /**
     * Writes the specified number of bytes to the current position.
     * @param {Number} count Number of bytes to read.
     */
    // writeBytes(count) {
    //     if (this._offset + count > this._view.byteLength) {
    //         throw new Error("Cannot read past the end of the buffer.");
    //     }

    //     const bytes = new DataView(this._view.buffer, this._offset, count);
    //     this._offset += count;
    //     return bytes;
    // }
     /**
     * Copies the bytes from the view starting from the current position.
     * @param {ArrayBuffer|Uint8Array|DataView} bytes Bytes to be copied.
     */
    copyBytes(bytes) {
        let source;
        
        if (bytes instanceof ArrayBuffer) {
            source = new Uint8Array(bytes);
        }
        else if (bytes instanceof Uint8Array) {
            source = bytes;
        }
        else if (bytes instanceof DataView) {
            source = new Uint8Array(bytes.buffer, bytes.byteOffset, bytes.byteLength)
        }
        else {
            throw new Error(`Bytes are not of a supported type: '${typeof bytes}', should be: ArrayBuffer, UInt8Array or DataView.`);
        }

        const dest = new Uint8Array(this._view.buffer, this._view.byteOffset + this._offset);
        dest.set(source);

        this._offset += source.length;
    }

    /**
     * Offsets by the number of bytes from the specified position.
     * @param {Number} offset Number of bytes to offset by.
     * @param {BinaryWriter.SeekOrigin} origin Position to offset from.
     */
    seek(offset, origin = BinaryWriter.SeekOrigin.CURRENT) {
        let absoluteOffset;

        switch (origin) {
            case BinaryWriter.SeekOrigin.BEGIN: {
                // TODO: should probably check if we go lower than the start offset
                absoluteOffset = offset;
                break;
            }

            case BinaryWriter.SeekOrigin.END: {
                absoluteOffset = this._view.byteLength + offset;
                break;
            }

            case BinaryWriter.SeekOrigin.CURRENT:
            default: {
                absoluteOffset = this._offset + offset;
                break;
            }
        }

        if (absoluteOffset >= 0 && absoluteOffset < this._view.byteLength) {
            this._offset = absoluteOffset;
        }
        else {
            throw new Error("offset is beyond the bounds of the buffer.");
        }
    }
}

/**
 * @enum {Number}
 */
BinaryWriter.SeekOrigin = Object.freeze({
    BEGIN: 0,
    CURRENT: 1,
    END: 2
});

export { BinaryWriter }
