import {AnimationNode} from "./animationnode.js";

import * as SerialValue from "./../serialization/serialvalueattribute.js";

class GroupNode extends AnimationNode {
    constructor() {
        /**
         * @type {Boolean}
         */
        this.loop = false;
    }
    
    getSerialValuesAttributes() {
        const attributes = [new SerialValue.Attribute("loop")];
        return attributes;
    }
    
    /**
     * Adds a child animation node to this group.
     * @param {AnimationNode} node The child node to add.
     */
    addChildNode(node) {
        this.addChild(node);
    }
    
    /**
     * Removes an animation node from this group.
     * @param {AnimationNode} node The child node to remove.
     */
    removeChildNode(node) {
        if (node.parent == this) {
            node.removeFromParent();
        }
    }
    
    getDurationWithLooping(maximumChildDuration) {
        const duration = this.loop ? Number.MAX_SAFE_INTEGER : maximumChildDuration;
        return duration;
    }
    
    getFiniteDuration() {
        let latestEndTime = Array.from(this.getChildren(AnimationNode)).reduce((endTime, child) => {
            return Math.max(endTime, child.startDelay + child.getFiniteDuration());
        }, 0);
        
        return latestEndTime;
    }
    
    reverse() {
        let duration = this.getFiniteDuration();
        
        // reverse the times by flipping the start times around.
        this.getChildren(AnimationNode).forEach(child => {
            const childDuration = child.getFiniteDuration();
            
            child.startDelay = duration - (child.startDelay + childDuration);
            child.reverse();
        });
    }
}

export {GroupNode};
