import {SerialNode} from "./../serialization/serialnode.js";

class SerialAssetInfo extends SerialNode {
    /**
     * Returns a new SerialAssetInfo with the specified type.
     * @param {ObjectConstructor} type The asset's type.
     */
    constructor(type) {
        super();
        
        this._assetType = type;
    }
    
    get assetType() {
        return this._assetType;
    } 
    
    /**
     * @returns {string}
     */
    get description() {}
    
    /**
     * @returns {Array<Guid>}
     */
    get assetIds() {}
    
    /**
     * @returns {Date}
     */
    get lastModifiedUtc() {}
    
    /**
     * @param {IAsset} asset
     */
    load() {}
    
    release(asset) {
        if (asset) {
            asset.dispose();
        }
    }
    
    /**
     * @returns {Date}
     */
    _getAssetIdsLastModifiedUtc() {
        let modified = new Date(0);
        
        this.assetIds.forEach(assetId => {
            const assetModified = AssetFactory.getAssetLastModifiedUtc(assetId);
            
            if (assetModified < modified) {
                modified = assetModified;
            }
        });
        
        return modified;
    }
}

/**
 * @param {string} resourceName Resource's identifier.
 * @return {Date}
 */
SerialAssetInfo._getResourceLastModifiedUtc = function(resourceName) {
    return AssetFactory.getResourceLastModifiedUtc(resourceName);
};

export {SerialAssetInfo};
