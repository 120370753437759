import React, { useState, useEffect, useContext } from 'react';
import SearchContext from '../SearchContext';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 75px;
`;

const OptionButton = styled.button`
  font-family: Univers;
  font-size: 16px;
  cursor: pointer;
  color: ${(props) => (props.$selected ? 'black' : '#727272')};
  background-color: transparent;
  border: none;
`;

const SearchResultsPagination = () => {
  const { totalPages, paginatedRange, onPaginationRangeChange } =
    useContext(SearchContext);

  const defaultPagesToShow = Math.min(4, totalPages);
  const [currentPage, setCurrentPage] = useState(parseInt(paginatedRange));
  const [pagesToShow, setPagesToShow] = useState(
    Array.from({ length: defaultPagesToShow }, (_, i) => i + 1)
  );

  const handlePageClick = (page) => {
    onPaginationRangeChange(page);
    setCurrentPage(page);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handlePrevClick = () => {
    const newPage = Math.max(currentPage - 1, 1);
    onPaginationRangeChange(newPage);
    setCurrentPage(newPage);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleNextClick = () => {
    const newPage = Math.min(currentPage + 1, totalPages);
    onPaginationRangeChange(newPage);
    setCurrentPage(newPage);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (currentPage <= defaultPagesToShow) {
      setPagesToShow(
        Array.from({ length: defaultPagesToShow }, (_, i) => i + 1)
      );
    } else if (currentPage >= totalPages - defaultPagesToShow + 1) {
      setPagesToShow(
        Array.from(
          { length: defaultPagesToShow },
          (_, i) => totalPages - defaultPagesToShow + i + 1
        )
      );
    } else {
      setPagesToShow(
        Array.from(
          { length: defaultPagesToShow },
          (_, i) => currentPage - 1 + i
        )
      );
    }
  }, [currentPage, totalPages, defaultPagesToShow]);
  return (
    <Container>
      {currentPage > 1 && (
        <OptionButton onClick={handlePrevClick}>&lt;&lt;Prev</OptionButton>
      )}
      {pagesToShow.map((page) => {
        return (
          <OptionButton
            key={page}
            onClick={() => handlePageClick(page)}
            $selected={currentPage === page}
            style={{ color: currentPage === page ? 'black' : 'gray' }}
          >
            {page}
          </OptionButton>
        );
      })}
      {currentPage < totalPages && (
        <OptionButton onClick={handleNextClick}>Next&gt;&gt;</OptionButton>
      )}
    </Container>
  );
};

export default SearchResultsPagination;
