import {AffineTransform} from "./../../../base/math.js";
import {ISpatialBounds} from "./spatialbounds.js";

const ISpatialObject = Base => class ISpatialObject extends Base {
    /**
     * @returns {AffineTransform.Matrix4}
     */
    get wsTransform() {}

    /**
     * @returns {ISpatialBounds}
     */
    get wsBounds() {}

    /**
     * @returns {AffineTransform.Matrix4}
     */
    get renderSpaceTransform() {}

    /**
     * @returns {ISpatialBounds}
     */
    get renderWsBounds() {}

    syncSpatialData() {}
}

export {ISpatialObject};
