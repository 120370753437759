import {BufferData} from "./../bufferresource.js";
import {VertexBuffer as RendererVertexBuffer} from "./../vertexbuffer.js";

class VertexBuffer extends RendererVertexBuffer {
    constructor() {
        super();
        
        /**
         * @type {ArrayBuffer}
         */
        this.data = null;
        
        /**
         * Offset in bytes to the ___.
         * @type {Number}
         */
        this.binaryOffset = 0;
    }
    
    get binaryData() {
        return this.data;
    }

    /**
     * @param {Number} sizeInBytes
     */
    createDynamicBuffer(sizeInBytes) {
        throw new Error("Invalid operation.");
    }

    /**
     * @param {BufferData} buffer
     */
    setStaticData(buffer) {
        this.data = buffer.data;
    }
    
    /**
     * @param {Number} offsetInBytes
     * @param {BufferData} buffer
     */
    setDynamicData(offsetInBytes, buffer) {
        throw new Error("Invalid operatoin.");
    }

    /**
     * @param {Boolean} isReloading
     */
    release(isReloading) {
        this.data = null;
    }
    
    _writeXmlAttributes(xmlDoc) {
        const attributes = super._writeXmlAttributes(xmlDoc);
        
        attributes.set("BinaryOffset", this.binaryOffset);
        attributes.set("BinaryLength", this.data.byteLength);
        
        return attributes;
    }
}

export {VertexBuffer};
