import {SerialNode} from "./../serialization/serialnode.js";
import * as SerialValue from "./../serialization/serialvalueattribute.js";

/**
 * This class provides a base for all animation data objects.
 */
class AnimationNode extends SerialNode {
    constructor() {
        super();

        /**
         * Specifies how many seconds to wait before starting the animation.
         * @type {Number}
         */
        this.startDelay = 0;
    }

    get absoluteStartTime() {
        const time = this.parent ? this.parent.absoluteStartTime + this.startDelay : this.startDelay;
        return time;
    }

    getFiniteDuration() {
        throw new Error("Not currently implemented.");
    }

    reverse() {
        throw new Error("Not currently implemented.");
    }
    
    getSerialValuesAttributes() {
        const attributes = [
            new SerialValue.Attribute("startDelay")
        ];
        
        return attributes;
    }
}

AnimationNode.Instance = class AnimationNodeInstance {
    constructor() {
        /**
         * @type {AnimationNode}
         */
        this.node = null;

        /**
         * @type {Number}
         */
        this._timer = 0;

        /**
         * Specifies whether or not this animation has started yet.
         * @type {Boolean}
         */
        this.isStarted = false;

        /**
         * @type {AnimationNode.Instance}
         */
        this.parentInstance = null;
    }

    get target() {
        const obj = this.parentInstance ? this.parentInstance.target : null;
        return obj;
    }

    /**
     * The time (in seconds) at which this animation will be finished.
     * @return {Number}
     */
    get endTime() {
        const time = this.node.startDelay + this._getDuration();
        return time;
    }

    /**
     * Specifies whether or not this animation is finished.
     * @returns {Boolean} True if finished, false otherwise.
     */
    get isFinished() {
        const result = this._timer >= this.endTime;
        return result;
    }

    get currentTime() {
        return this._timer;
    }

    get finiteDuration() {
        return null;
    }

    /**
     * Returns the instance for the specified node.
     * @param {AnimationNode} node Node to get instance for.
     * @returns {AnimationNode.Instance} Node's instance.
     */
    getInstanceOfNode(node) {
        const instance = node == this.node ? this : null;
        return instance;
    }

    /**
     * 
     * @param {AnimationNode} node Reference node.
     * @param {Object} target Object to use as target.
     * @returns {Boolean} True if successful, false otherwise.
     */
    setup(node, target) {
        this.node = node;

        // call our virtual setup method
        return this._setup(target);
    }

    /**
     * Initializes the node instance.
     * @param {Object} target Object to use as target.
     * @returns {Boolean} True if successful, false otherwise.
     */
    _setup(target) {
        return true;
    }

    _getDuration() {
        return 0;
    }

    /**
     * Update the animation with the given delta time.
     * @param {Number} deltaTime The change in time (in seconds).
     */
    _update(deltaTime) {
        throw new Error("Not currently implemented.");
    }

    // TODO: implement remaining functions if needed (Billy 11-5-2020).
};

export {AnimationNode};
