import { Matrix4 } from "./affinetransform";

class Vector4 {
    /**
     * @param {Vector4} vec 
     * @param {Matrix4} mat 
     * @returns {Vector4}
     */
    static transform(vec, mat) {
        return new Vector4(
            vec.x * mat.m11 + vec.y * mat.m21 + vec.z * mat.m31 + vec.w * mat.m41,
            vec.x * mat.m12 + vec.y * mat.m22 + vec.z * mat.m32 + vec.w * mat.m42,
            vec.x * mat.m13 + vec.y * mat.m23 + vec.z * mat.m33 + vec.w * mat.m43,
            vec.x * mat.m14 + vec.y * mat.m24 + vec.z * mat.m34 + vec.w * mat.m44
        )
    }

    /**
     * Creates a new Vector4
     * @param {Number} x x-Axis value
     * @param {Number} y y-Axis value
     * @param {Number} z z-Axis value
     * @param {Number} w W value
     */
    constructor(x = 0, y = 0, z = 0, w = 0) {
        /**
         * @type {Number}
         */
        this.x = x;

        /**
         * @type {Number}
         */
        this.y = y;

        /**
         * @type {Number}
         */
        this.z = z;

        /**
         * @type {Number}
         */
        this.w = w;
    }
}

export default Vector4;