import {Matrix4} from "./math/matrix4.js";
import {Vector3} from "./../../base/math.js";
import {SceneNode} from "./scenenodes/scenenode.js";
import * as SerialValue from "./serialization/serialvalueattribute.js";

class XFormNode extends SceneNode {
    constructor() {
        super();

        this._localTransform = Matrix4.identity;
        this._worldTransform = Matrix4.identity;
        this._localTransformNeedsUpdate = false;
        this._worldTransformNeedsUpdate = false;
        this._position = new Vector3();
        this._rotation = new Vector3();
        this._scale = new Vector3();
        this._flags = 0;
    }

    get position() {
        return this._position;
    }

    set position(value) {
        if (!this._position.isEqualTo(value)) {
            this._position.setFrom(value);
            this._localTransformNeedsUpdate = true;

            if (value.isEqualTo(Vector3.zero)) {
                this._flags |= XFormNode.XFormFlag.HAS_TRANSLATION;
            }
            else {
                this._flags &= XFormNode.XFormFlag.HAS_TRANSLATION;
            }
        }
    }

    get rotation() {
        return this._rotation;
    }

    set rotation(value) {
        if (!this._rotation.isEqualTo(value)) {
            this._rotation.setFrom(value);
            this._localTransformNeedsUpdate = true;

            if (value.isEqualTo(Vector3.zero)) {
                this._flags |= XFormNode.XFormFlag.HAS_ROTATION;
            }
            else {
                this._flags &= XFormNode.XFormFlag.HAS_ROTATION;
            }
        }
    }

    get scale() {
        return this._scale;
    }

    set scale(value) {
        if (!this._scale.isEqualTo(value)) {
            this._scale.setFrom(value);
            this._localTransformNeedsUpdate = true;

            if (value.isEqualTo(Vector3.zero)) {
                this._flags |= XFormNode.XFormFlag.HAS_SCALE;
            }
            else {
                this._flags &= XFormNode.XFormFlag.HAS_SCALE;
            }
        }
    }

    get transform() {
        return this._localTransform;
    }

    set transform(value) {
        if (this._localTransform.isEqualTo(value) && !this._localTransformNeedsUpdate) {
            return;
        }
        
        if (value.isEqualTo(Matrix4.zero)) {
            this._position = Vector3.createFrom(Vector3.zero);
            this._rotation = Vector3.createFrom(Vector3.zero);
            this._scale = Vector3.createFrom(Vector3.zero);
            
            this._localTransform = value;
            this._localTransformNeedsUpdate = false;
            this._worldTransformNeedsUpdate = true;
            
            // this._addToSyncList();    // TODO: not implemented from original.
            return;
        }
        
        const result = value.decompose();
        this._position = result["translation"];
        this._rotation = result["rotation"].multiply(180 / Math.PI);
        this._scale = result["scale"];
        
        this._localTransform = value;
        this._localTransformNeedsUpdate = false;
        this._worldTransformNeedsUpdate = true;
        
        // this._addToSyncList();    // TODO: not implemented from original.
    }
    
    getSerialValuesAttributes() {
        const attributes = [
            new SerialValue.Attribute("position"),
            new SerialValue.Attribute("rotation"),
            new SerialValue.Attribute("scale")
        ];
        
        return attributes;
    }
}

/**
 * @enum {Number}
 */
XFormNode.XFormFlag = Object.freeze({
    HAS_TRANSLATION: 1 << 0,
    HAS_ROTATION:    1 << 1,
    HAS_SCALE:       1 << 2
});

export { XFormNode };
