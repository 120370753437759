import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { oktaSignIn } from './services/okta-service';

const OktaAuthContext = createContext();

export function OktaAuthProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [redirectPath, setRedirectPath] = useState('/');
  const navigate = useNavigate();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(originalUri || '/', { replace: true });
  };

  const closeModal = () => {
    setIsOpen(false);
    oktaSignIn.remove();
  };
  const openModal = (redirectPath) => {
    if (redirectPath) {
      setRedirectPath(redirectPath);
    } else {
      setRedirectPath('/');
    }

    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const contextValue = {
    restoreOriginalUri,
    isOpen,
    closeModal,
    openModal,
    redirectPath,
    setRedirectPath,
  };

  return (
    <OktaAuthContext.Provider value={contextValue}>
      {children}
    </OktaAuthContext.Provider>
  );
}

export function useOktaAuthContext() {
  return useContext(OktaAuthContext);
}
