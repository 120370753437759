
class BinaryReader {
    /**
     * Creates a new BinaryReader.
     * @param {ArrayBuffer} buffer Data buffer.
     * @param {Number} byteOffset Number of bytes to offset from the start of the buffer.
     * @param {Number} byteLength Length of the data in bytes from the offset.
     * @param {Boolean} isLittleEndian Whether the data is little endian or big endian.
     */
    constructor(buffer, byteOffset, byteLength, isLittleEndian = false) {
        if (!byteOffset || typeof byteOffset === "undefined") {
            byteOffset = 0;
        }

        if (!byteLength || typeof byteLength === "undefined") {
            byteLength = buffer.byteLength;
        }

        this._view = new DataView(buffer, byteOffset, byteLength);
        this._offset = 0;

        this._useLittleEndian = isLittleEndian;
    }

    get useLittleEndian() {
        return this._useLittleEndian;
    }

    set useLittleEndian(use) {
        this._useLittleEndian = use;
    }

    get offset() {
        return this._offset;
    }

    get byteLength() {
        return this._view.byteLength;
    }

    /**
     * Reads a 64-bit signed integer from the current position.
     * @return {BigInteger} 64-bit signed integer.
     */
    readBigInt64() {
        const value = this._view.getBigInt64(this._offset, this._useLittleEndian);
        this._offset += 8;
        return value;
    }

    /**
     * Reads a 64-bit unsigned integer from the current position.
     * @return {BigInteger} 64-bit unsigned integer.
     */
    readBigUInt64() {
        const value = this._view.getBigUInt64(this._offset, this._useLittleEndian);
        this._offset += 8;
        return value; 
    }

    /**
     * Reads a 32-bit signed float from the current position.
     * @return {Number} 32-bit signed float.
     */
    readFloat32() {
        const value = this._view.getFloat32(this._offset, this._useLittleEndian);
        this._offset += 4;
        return value;
    }

    /**
     * Reads a 64-bit signed float from the current position.
     * @return {Number} 64-bit signed float.
     */
    readFloat64() {
        const value = this._view.getFloat64(this._offset, this._useLittleEndian);
        this._offset += 8;
        return value;
    }

    /**
     * Reads a 8-bit signed integer from the current position.
     * @return {Number} 8-bit signed integer.
     */
    readInt8() {
        const value = this._view.getInt8(this._offset, this._useLittleEndian);
        this._offset += 1;
        return value;
    }

    /**
     * Reads a 16-bit signed integer from the current position.
     * @return {Number} 16-bit signed integer.
     */
    readInt16() {
        const value = this._view.getInt16(this._offset, this._useLittleEndian);
        this._offset += 2;
        return value;
    }

    /**
     * Reads a 32-bit signed integer from the current position.
     * @return {Number} 32-bit signed integer.
     */
    readInt32() {
        const value = this._view.getInt32(this._offset, this._useLittleEndian);
        this._offset += 4;
        return value;
    }

    /**
     * Reads a 8-bit unsigned integer from the current position.
     * @return {Number} 8-bit unsigned integer.
     */
    readUint8() {
        const value = this._view.getUInt8(this._offset, this._useLittleEndian);
        this._offset += 1;
        return value;
    }

    /**
     * Reads a 16-bit unsigned integer from the current position.
     * @return {Number} 16-bit unsigned integer.
     */
    readUint16() {
        const value = this._view.getUint16(this._offset, this._useLittleEndian);
        this._offset += 2;
        return value;
    }

    /**
     * Reads a 32-bit unsigned integer from the current position.
     * @return {Number} 32-bit unsigned integer.
     */
    readUint32() {
        const value = this._view.getUint32(this._offset, this._useLittleEndian);
        this._offset += 4;
        return value;
    }

    /**
     * Reads the specified number of bytes from the current position.
     * @param {Number} count Number of bytes to read.
     * @return {DataView} DataView of the read bytes.
     */
    readBytes(count) {
        if (this._offset + count > this._view.byteLength) {
            throw new Error("Cannot read past the end of the buffer.");
        }

        const bytes = new DataView(this._view.buffer, this._offset, count);
        this._offset += count;
        return bytes;
    }

    /**
     * Offsets by the number of bytes from the specified position.
     * @param {Number} offset Number of bytes to offset by.
     * @param {BinaryReader.SeekOrigin} origin Position to offset from.
     */
    seek(offset, origin = BinaryReader.SeekOrigin.CURRENT) {
        let absoluteOffset;

        switch (origin) {
            case BinaryReader.SeekOrigin.BEGIN: {
                // TODO: should probably check if we go lower than the start offset
                absoluteOffset = offset;
                break;
            }

            case BinaryReader.SeekOrigin.END: {
                absoluteOffset = this._view.byteLength + offset;
                break;
            }

            case BinaryReader.SeekOrigin.CURRENT:
            default: {
                absoluteOffset = this._offset + offset;
                break;
            }
        }

        if (absoluteOffset >= 0 && absoluteOffset < this._view.byteLength) {
            this._offset = absoluteOffset;
        }
        else {
            throw new Error("offset is beyond the bounds of the buffer.");
        }
    }
}

/**
 * @enum {Number}
 */
BinaryReader.SeekOrigin = Object.freeze({
    BEGIN: 0,
    CURRENT: 1,
    END: 2
});

export { BinaryReader }
