import * as THREE from 'three';
import ModelViewer from "./modelviewer.js";
// import ModelViewer from './stenciltests/stencilTestViewer.js'
// import ModelViewer from './csg/csgTestViewer.js'
import * as H3D from "./h3d.js";
import { CutToolHelper, CutModelNodeDecorator } from './h3d/cuthelpers.js';

/**
 * Z-Index applied to next session on click.
 * @type {Number}
 */
let NextZIndex = 1;

/**
 * Creates a cube map from files in the specified path.
 * @param {String} path Path to directory containing the images and file type. (ex. "directory/*.png")
 * @return {THREE.CubeTexture} CubeTexture
 */
function load_cube_map(path) {
    const pattern = /(\/)(\*)(\.[^\s]+)$/;
    const urls = ["px", "nx", "py", "ny", "pz", "nz"].map(name => path.replace(pattern, `$1${name}$3`));

    const cube = new THREE.CubeTextureLoader().load(urls);
    return cube;
}

class Session {
    /**
     * Creates a new Session.
     * @param {HTMLElement} container Element containing the preview element.
     */
    constructor(container) {
        /**
         * Function called when the session produces events.
         * @type <Function>
         */
        this.onEvent = (targetSession, eventType) => { };

        /**
         * @type {HTMLElement}
         */
        this.container = container;

        /**
         * Cube map used for the scene.
         * @type {THREE.CubeTexture}
         */
        this._cubeMap = null;

        /**
         * Properties to apply to chrome materials.
         * @type {Map<String,Number|String>}
         */
        this._chromeProperties = new Map([
            ["metalness", 0.9], // dx10/sharpdx eSchematic renderer uses 0.95
            ["roughness", 0.2], // dx10/sharpdx eSchematic renderer uses 0.15
            ["envMap", () => this._cubeMap],
            ["envMapIntensity", 1.6]
        ]);

        /**
         * Properties to apply to rubber material.
         * @type {Map<String,Number|String>}
         */
        this._rubberProperties = new Map([
            ["metalness", 0.0],
            ["roughness", 0.7], // dx10/sharpdx eSchematic renderer uses 1.0
            ["envMap", () => this._cubeMap],
            ["envMapIntensity", 1.2]
        ]);

        /**
         * Model Viewer.
         * @type {ModelViewer}
         */
        this._viewer = null;

        /**
         * Group of meshes currently being displayed.
         * @type {Array<THREE.Group>}
         */
        this._group;

        /**
         * Tool cutting
         */
        this.isToolCutBottomRight = false;
        this.isToolCutBottomLeft = false;
        this.isToolCutTopLeft = false;
        this.isToolCutTopRight = false;
        this.cutModelNodeDecorator = new CutModelNodeDecorator();

        this._model = null;

        this.container.style.zIndex = NextZIndex;
        NextZIndex += 1;

        let preview = container.querySelector(".preview");
        if (!preview) {
            return;
        }

        this._cubeMap = load_cube_map(`${location.origin}/assets/cubemap/*.png`);
        this._cubeMap.colorSpace = THREE.SRGBColorSpace;
        this._cubeMap.mapping = THREE.CubeReflectionMapping;

        const viewer = new ModelViewer(preview);

        this._viewer = viewer;
        this._viewer.onToolRotated = (newRotation) =>
            this.onEvent(this, Session.Event.ROTATED_TOOL, newRotation);
        
        viewer.run();
    }

    dispose() {
        this._viewer?.dispose()
        this.disposed = true
    }

    /**
     * Loads the model from the specified URL and displays it.
     * @param {URL} url URL to load model from.
     */
    loadUrl(url) {
        this.cutModelNodeDecorator.setOriginalModel(null);
        this._loadModel(url)
            .then(result => {
                if (this._group !== undefined) {
                    this._viewer.removeModel();

                    this._group.children.forEach(mesh => {
                        mesh.geometry.dispose();
                        mesh.material.dispose();
                    });

                }
                const { group, model, width: resultWidth, height: resultHeight} = result

                this._model = model;
                this.cutModelNodeDecorator.setOriginalModel(model);

                this._rotateMeshesForViewMode(group, Session.ViewMode.ESCHEMATIC);
                this._group = group;

                let width, height
                let zoomFitBuffer = 1.25

                // in eschematic view mode, tools are rotated, so height becomes width
                width = resultHeight
                height = resultWidth

                this._viewer.addModel(group)
                this._viewer._zoomToFit(width, height, zoomFitBuffer)
              
                // console.log(width, height, zoom)

                this.onEvent(this, Session.Event.READY);
            },
            error => {
                this.onEvent(this, Session.Event.ERROR, error);
            });
    }

    /**
     * Loads the specified model.
     * @param {URL} url URL to file to load model from.
     */
    _loadModel(url) {
        return new Promise((resolve, reject) => {

            let loader = null;
            try {
                loader = new H3D.Loader();
            } catch (e) {
                console.error(e);
            }
            loader.authLoad(
                url,
                (result) => {
                    if(this.disposed) {
                        // Session disposed while loading model
                        return
                    }

                    if (result.model && result.model.scene) {
                        const model = result.model;
                        const nodeData = model.scene.getNode("mesh").value;
                        const mesh = model.getMesh(nodeData.mesh);

                        const meshes = [];

                        mesh.drawCalls.forEach((drawCall, drawCallIndex) => {
                            // console.log(`draw call [${drawCallIndex}]`);
                            const positions = model.getVertexBufferAttribute(drawCall.vertexBuffer, "Position");
                            const normals = model.getVertexBufferAttribute(drawCall.vertexBuffer, "Normal");
                            const tangents = model.getVertexBufferAttribute(drawCall.vertexBuffer, "Tangent");
                            const uvs = model.getVertexBufferAttribute(drawCall.vertexBuffer, "TextureCoordinate");

                            const indices = model.getIndexBuffer(drawCall.indexBuffer);

                            const geometry = new THREE.BufferGeometry();

                            geometry.setAttribute("position", new THREE.BufferAttribute(positions.buffer, positions.count));
                            geometry.setAttribute("normal", new THREE.BufferAttribute(normals.buffer, normals.count));

                            if (tangents) {
                                geometry.setAttribute("tangent", new THREE.BufferAttribute(tangents.buffer, tangents.count));
                            }

                            if (uvs) {
                                geometry.setAttribute("uv", new THREE.BufferAttribute(uvs.buffer, uvs.count));
                            }

                            geometry.setIndex(new THREE.BufferAttribute(indices, 1));
                            geometry.setDrawRange(drawCall.indexOffset, drawCall.indexCount);

                            const materialOptions = {
                                // vertexTangents: tangents != null,
                                // side: THREE.DoubleSide,
                                // wireframe: true
                            };

                            const renderMaterial = nodeData.renderMaterials[drawCallIndex];
                            const material = model.getMaterial(renderMaterial);
                            if (material) {
                                // console.log(renderMaterial)
                                if (renderMaterial.toLowerCase().includes("chrome")) {
                                    this._chromeProperties.forEach((value, key) => {
                                        switch (typeof value) {
                                            case "function": {
                                                materialOptions[key] = value();
                                                break;
                                            }

                                            default: {
                                                materialOptions[key] = value;
                                                break;
                                            }
                                        }
                                    });
                                }
                                else if(renderMaterial.toLowerCase().includes("rubber")) {
                                    this._rubberProperties.forEach((value, key) => {
                                        switch (typeof value) {
                                            case "function": {
                                                materialOptions[key] = value();
                                                break;
                                            }

                                            default: {
                                                materialOptions[key] = value;
                                                break;
                                            }
                                        }
                                    });
                                }
                                else {
                                    console.warn(`Unsupported material with name: '${renderMaterial}'`)
                                }

                                material.uniforms.forEach((uniformValue, uniformName) => {
                                    // console.log(uniformName, uniformValue)
                                    const index = uniformName.lastIndexOf(".");
                                    const uniformType = index != -1 ? uniformName.slice(index + 1) : uniformName;

                                    switch (uniformType.toLowerCase()) {
                                        case "diffuse": {
                                            // NOTE: three.js does not support an alpha component in the color, supposedly due to WebGL not supporting it.
                                            materialOptions["color"] = new THREE.Color(uniformValue[0], uniformValue[1], uniformValue[2]);
                                            break;
                                        }

                                        default: {
                                            console.warn(`Unsupported uniform: '${uniformName}', encountered while building material options.`);
                                            break;
                                        }
                                    }
                                });
                            }
                            else {
                                console.warn(`Could not find material with name '${nodeData.renderMaterials[drawCallIndex]}' in model.`);
                            }

                            const meshMaterial = new THREE.MeshStandardMaterial(materialOptions);
                            const threeMesh = new THREE.Mesh(geometry, meshMaterial);

                            threeMesh.position.copy(nodeData.position);
                            // threeMesh.quaterion.copy(nodeData.rotation);
                            threeMesh.scale.copy(nodeData.scale);

                            meshes.push(threeMesh);
                        });

                        const group = new THREE.Group();
                        group.add(...meshes);
                        const width = mesh.bounds.max.x * nodeData.scale.x * 2
                        const height = mesh.bounds.max.y * nodeData.scale.y * 2

                        resolve({
                            group,
                            model,
                            width, 
                            height
                        });
                    }
                    else if (result.error) {
                        this._viewer.stop();
                        reject(result.error);
                    }
                },
                undefined,
                (error) => {
                    this._viewer.stop();
                    reject(error);
                });
        });
    }

    zoomCameraOut() {
        this._viewer?.zoomCameraOut()
    }

    zoomCameraIn() {
        this._viewer?.zoomCameraIn()
    }

    applyCut(cutQuadrants) {
        this.isToolCutTopLeft = (cutQuadrants & Session.CutQuadrant.TOP_LEFT) !== 0
        this.isToolCutTopRight = (cutQuadrants & Session.CutQuadrant.TOP_RIGHT) !== 0
        this.isToolCutBottomLeft = (cutQuadrants & Session.CutQuadrant.BOTTOM_LEFT) !== 0
        this.isToolCutBottomRight = (cutQuadrants & Session.CutQuadrant.BOTTOM_RIGHT) !== 0
        
        this.onToolCutChanged()
    }

    onToolCutChanged() {
        if(!this._model || !this.cutModelNodeDecorator || !this.cutModelNodeDecorator.node) {
            console.warn("Canceling cut: _model and/or cutModelNodeDecorator are invalid");
            return;
        }

        const { startAngle, cutAngle, isSymmetry } = CutToolHelper.CalculateCutAngle(
            this.isToolCutBottomRight,
            this.isToolCutBottomLeft,
            this.isToolCutTopLeft,
            this.isToolCutTopRight);
        // const offsetAngle = 0; // ModelNode.Rotation.X // current tool rotation

        // console.log(startAngle, cutAngle, isSymmetry);

        this._viewer?.applyCut(startAngle, cutAngle, isSymmetry)

        // implementation used in eSchematic of manually cutting all of the tris
        /*
        // apply the cut to the original model info, and re-init the three.js
        // meshes from the resulting model info geometry

        this.cutModelNodeDecorator.applyCut(isSymmetry, cutAngle, startAngle, offsetAngle)

        console.log(this.cutModelNodeDecorator._axialCut);
        console.log(this.cutModelNodeDecorator.node)

        const cutModelAssetInfo = this.cutModelNodeDecorator.node.getModelInfo();
        if(cutModelAssetInfo === null) {
            console.error('Failed to get cut model asset info');
            return;
        }

        console.log(cutModelAssetInfo);

        // show loading

        cutModelAssetInfo.load(cutModelAssetInfo)
        .then((result) => {
            // update the meshes in the THREE scene...
            console.log('cut result', result);

        }).catch((reason) => {
            console.log('cut fail reason', reason);

        }).finally(() => {
            // hide loading
        });
        */
    }

    resetCamera() {
        this._viewer?.resetCamera()
    }

    /**
     * Rotates the model for the specified view mode.
     * @param {Array<THREE.Mesh>} meshes Meshes to be rotated.
     * @param {Session.ViewMode} viewMode View mode to rotate the meshes for.
     */
    _rotateMeshesForViewMode(group, viewMode) {
        let angle = viewMode == Session.ViewMode.NORMAL ? 0 : -Math.PI / 2;
        // group.rotation.z = angle;
        group.children.forEach((child) => child.rotation.z = angle);
    }
}

/**
 * @enum {Number}
 */
Session.ViewMode = Object.freeze({
    // view the model as it was loaded.
    NORMAL: 1,

    // view the model in the orientation of eSchematic
    ESCHEMATIC: 2
});

/**
 * @enum {String}
 */
Session.Event = Object.freeze({
    READY: "ready",
    FINISHED: "finished",
    ROTATED_TOOL: "rotatedtool",

    ERROR: "error"
});

Session.CutQuadrant = Object.freeze({
    NONE:                0, // 0000
    TOP_LEFT:       1 << 0, // 0001
    TOP_RIGHT:      1 << 1, // 0010
    BOTTOM_LEFT:    1 << 2, // 0100
    BOTTOM_RIGHT:   1 << 3, // 1000
    ALL:            0b1111, // 1111
})

export { Session };