import React, { useContext, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { oktaAuth } from '../../../services/okta-service.js';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { ChevronRight } from 'react-feather';
import { ChevronLeft } from 'react-feather';

import ProductAndServiceCatalogMenu from './ProductAndServiceCatalogMenu';
import QuickQuoteMenu from './QuickQuoteMenu';
import MyAccountMenu from './MyAccountMenu';
import DataFetchErrorPlaceholder from '../../../shared/DataFetchErrorPlaceholder';
import ApplicationContext from '../../../ApplicationContext';

import { useOktaAuthContext } from '../../../OktaAuthContext.js';

const MobileMenuPane = styled.div`
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  z-index: 99;
`;

const MobileInnerMenuContainer = styled.div`
  height: 90%;
  position: absolute;
  left: 0;
  width: 90%;
  height: 100%;
  box-sizing: border-box;
  background-color: #fff;
`;

const CloseButtonContainer = styled.div`
  height: 53px;
  width: 100%;
  background-color: var(--color-gray2);
  display: flex;
  align-items: center;
  justify-content: end;
`;

const CloseButton = styled.button`
  background: var(--color-gray2);
  border: 1px solid white;
  // border-radius: 12px;
  cursor: pointer;
  margin-right: 20px;
  color: white;
  font-family: Univers;
  font-size: 16px;
  height: 40px;
  width: 129px;
`;

const BackButtonContainer = styled.div`
  padding: 12px;
  border-bottom: 1px solid var(--color-gray4);
`;

const BackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-family: Univers;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: var(--color-primary);
`;

const Divider = styled.div`
  height: 22px;
  background-color: var(--color-gray4);
`;

const Footer = styled.div`
  height: 100%;
  background-color: var(--color-gray4);
`;

const MobileNavigationOptionsButton = styled.button`
  font-family: Univers;
  background: none;
  border: none;
  cursor: pointer;
  height: 60px;
  border-bottom: 1px solid var(--color-gray4);
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-right: 12px;
  padding-left: 12px;
  width: 100%;
`;

const MobileNavigationName = styled.div`
  font-family: Univers;
  font-weight: 500;
  font-size: 16px;

  color: var(--color-primary);
`;

const DataFetchErrorPlaceholderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MobilePanelAuthButton = styled.button`
  font-family: Univers;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 42px;
  font-size: 16px;
  font-weight: 500;
  align-self: flex-end;
  background-color: ${(props) =>
    props.$isSignOutButton ? 'white' : 'var(--color-primary)'};
  color: ${(props) =>
    props.$isSignOutButton ? 'var(--color-primary)' : 'white'};
  border: ${(props) =>
    props.$isSignOutButton ? '1px solid var(--color-primary)' : 'unset'};
  border-bottom: ${(props) =>
    props.$isSignOutButton
      ? '1px solid var(--color-primary)'
      : '1px solid var(--color-gray1)'};
  transition: color 0.2s ease-out, background-color 0.2s ease-out,
    border-bottom-color 0.2s ease-out;
  // border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: var(--color-dark-red);
    color: #fff;
  }
`;

const MobileAuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
`;

const MobileSignInTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  padding-top: 25px;
  padding-bottom: 10px;
  color: var(--color-gray1);
`;

const MobileHelperText = styled.div`
  font-size: 14px;
  color: var(--color-gray1);
`;

const MobileMenu = ({ handleShowMobileMenu }) => {
  const { classesIsLoading, userName } = useContext(ApplicationContext);
  const { authState } = useOktaAuth();
  const navigate = useNavigate();

  const [menuType, setMenuType] = useState('default');

  const { openModal } = useOktaAuthContext();

  const handleOnClose = () => {
    handleShowMobileMenu(false);
    setMenuType('default');
  };

  const signOut = async (state) => {
    localStorage.setItem('signingOut', 'true');
    try {
      await oktaAuth.signOut({
        revokeAccessToken: true,
        revokeRefreshToken: true,
        clearTokensBeforeRedirect: true,
        postLogoutRedirectUri: window.location.origin + '/logout',
        state: state,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleUserLoginOrLogout = async () => {
    authState?.accessToken?.accessToken !== undefined
      ? await signOut(`/landing-page`)
      : openModal();
  };

  const handleOnHomeClick = () => {
    handleShowMobileMenu(false);
    navigate('/');
  };

  const cachedClasses = JSON.parse(localStorage.getItem('fetchedClasses'));

  return (
    <MobileMenuPane>
      <MobileInnerMenuContainer>
        <CloseButtonContainer>
          <CloseButton alt="close menu" onClick={() => handleOnClose()}>
            Close
          </CloseButton>
        </CloseButtonContainer>

        {menuType === 'default' ? (
          <>
            {!authState?.accessToken?.accessToken ? (
              <>
                <MobileAuthContainer>
                  <MobileSignInTitle>Sign In</MobileSignInTitle>
                  <MobileHelperText>
                    Sign in to access your account information
                  </MobileHelperText>
                  <MobilePanelAuthButton
                    $isSignIn
                    onClick={handleUserLoginOrLogout}
                    alt="sign out"
                  >
                    SIGN IN
                  </MobilePanelAuthButton>
                </MobileAuthContainer>
              </>
            ) : (
              <MobileAuthContainer>
                <MobileSignInTitle>Hello, {userName}</MobileSignInTitle>
                <MobilePanelAuthButton
                  $isSignOutButton
                  onClick={handleUserLoginOrLogout}
                  alt="sign out"
                >
                  Sign Out
                </MobilePanelAuthButton>
              </MobileAuthContainer>
            )}
            <Divider />
            <MobileNavigationOptionsButton onClick={handleOnHomeClick}>
              <MobileNavigationName>Home </MobileNavigationName>
            </MobileNavigationOptionsButton>
            <MobileNavigationOptionsButton
              onClick={() => setMenuType('product and service catalog')}
            >
              <MobileNavigationName>
                Product and Service Catalog{' '}
              </MobileNavigationName>
              <ChevronRight color={'var(--color-gray2)'} />
            </MobileNavigationOptionsButton>
            <MobileNavigationOptionsButton
              onClick={() => setMenuType('quick quote')}
            >
              <MobileNavigationName>Quick Request</MobileNavigationName>
              <ChevronRight color={'var(--color-gray2)'} />
            </MobileNavigationOptionsButton>
            <MobileNavigationOptionsButton
              onClick={() => setMenuType('my account')}
            >
              <MobileNavigationName>My Account</MobileNavigationName>
              <ChevronRight color={'var(--color-gray2)'} />
            </MobileNavigationOptionsButton>
            <Footer />
          </>
        ) : menuType === 'product and service catalog' ? (
          <>
            <BackButtonContainer>
              <BackButton onClick={() => setMenuType('default')}>
                <ChevronLeft color={'var(--color-primary)'} /> Back
              </BackButton>
            </BackButtonContainer>
            {classesIsLoading || cachedClasses ? (
              <ProductAndServiceCatalogMenu
                mobile
                closeMobileMenu={() => handleShowMobileMenu(false)}
              />
            ) : (
              <DataFetchErrorPlaceholderContainer>
                <DataFetchErrorPlaceholder />
              </DataFetchErrorPlaceholderContainer>
            )}
          </>
        ) : menuType === 'quick quote' ? (
          <>
            <BackButtonContainer>
              <BackButton onClick={() => setMenuType('default')}>
                <ChevronLeft color={'var(--color-primary)'} /> Back
              </BackButton>
            </BackButtonContainer>
            <QuickQuoteMenu mobile />
          </>
        ) : (
          <>
            <BackButtonContainer>
              <BackButton onClick={() => setMenuType('default')}>
                <ChevronLeft color={'var(--color-primary)'} /> Back
              </BackButton>
            </BackButtonContainer>
            <MyAccountMenu
              mobile
              closeMobileMenu={() => handleShowMobileMenu(false)}
            />
          </>
        )}
      </MobileInnerMenuContainer>
    </MobileMenuPane>
  );
};
export default MobileMenu;
