import {IAsset} from "./asset.js";
import {IAssetInfo} from "./assetinfo.js";

const IAssetProxy = Base => class IAssetProxy extends Base {
    /**
     * @returns {IAssetInfo}
     */
    get info() {}

    /**
     * @returns {IAsset}
     */
    get iasset() {}
};

const ITypedAssetProxy = Base => class ITypedAssetProxy extends IAssetProxy(Base) {
    /**
     * @type {any}
     */
    get asset() {}
};

export {IAssetProxy, ITypedAssetProxy};
