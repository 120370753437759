import React from 'react';
import styled from 'styled-components';

const PageTitleContainer = styled.section`
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 70vw;
  }
  @media (max-width: 900px) {
    &::after {
      width: calc(100% - 23px);
    }
  }
`;

const PageTitleText = styled.h1`
  position: relative;
  font-family: Univers;
  font-size: 28px;
  font-weight: 500;
  color: var(--color-gray1);
  padding: 8px 0 0 11px;
  margin-top: 16.4px;
  margin-bottom: 0;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 12px;
    height: 11px;
    border-left: 7px solid var(--color-primary);
    border-top: 7px solid var(--color-primary);
  }

  @media (max-width: 900px) {
    font-size: 20px;
    padding-top: 13px;
    margin: 23px 0 8px;

    &::before {
      left: -2px;
      top: 8px;
      width: 10px;
      height: 9px;
      border-left: 5px solid var(--color-primary);
      border-top: 5px solid var(--color-primary);
    }
  }
`;

const PageTitle = ({ text }) => {
  return (
    <PageTitleContainer>
      <PageTitleText>{text}</PageTitleText>
    </PageTitleContainer>
  );
};

export default PageTitle;
