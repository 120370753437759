import * as THREE from 'three';

export class CutMesh {
  /**
   * @param {THREE.BufferGeometry} geo
   * @param {THREE.Material} mat
   * @param {THREE.Vector3} pos
   * @param {THREE.Quaternion} quat
   * @param {THREE.Vector3} sca
   */
  constructor(geo, mat, pos, quat, sca) {
    this.geometry = geo || new THREE.BufferGeometry();
    this.material = mat || new THREE.MeshBasicMaterial();
    this.position = pos || new THREE.Vector3();
    this.quaternion = quat || new THREE.Quaternion();
    this.scale = sca || new THREE.Vector3(1, 1, 1);
  }
}

/**
 *
 * @param {THREE.BufferGeometry} stencilGeo
 * @param {CutMesh} sceneObj
 * @param {THREE.Scene} scene
 */
export function drawCutFaces(stencilGeo, sceneObj, scene) {
  const face = THREE.BackSide;
  const testFunc = THREE.NotEqualStencilFunc;

  let meshA, meshB;

  // instead of immediately rendering geometries stencilGeo and geoB
  // as in csg.c, we just add them to the scene in order

  var stencilMaterial = new THREE.MeshBasicMaterial();

  stencilMaterial.colorWrite = false; // glColorMask(GL_FALSE, GL_FALSE, GL_FALSE, GL_FALSE);
  stencilMaterial.depthTest = true; // glEnable(GL_DEPTH_TEST);
  stencilMaterial.side = face; // glCullFace(face);

  meshA = new THREE.Mesh(stencilGeo, stencilMaterial);
  scene.add(meshA); // geoA(); /* draw face of a into depth buffer */

  /* use stencil plane to find parts of geoA in geoB */

  stencilMaterial = stencilMaterial.clone();
  stencilMaterial.depthWrite = false; // glDepthMask(GL_FALSE);
  stencilMaterial.stencilWrite = true; // glEnable(GL_STENCIL_TEST);
  stencilMaterial.stencilFunc = THREE.AlwaysStencilFunc; // glStencilFunc(GL_ALWAYS, 0, 0);
  stencilMaterial.stencilZPass = THREE.IncrementStencilOp; // glStencilOp(GL_KEEP, GL_KEEP, GL_INCR); // (GLenum sfail, GLenum dpfail, GLenum dppass);
  stencilMaterial.side = THREE.FrontSide; // glCullFace(GL_BACK);

  meshB = new THREE.Mesh(sceneObj.geometry, stencilMaterial);
  meshB.position.copy(sceneObj.position);
  meshB.quaternion.copy(sceneObj.quaternion);
  meshB.scale.copy(sceneObj.scale);
  scene.add(meshB); // geoB(); /* increment the stencil where the front face of geoB is drawn */

  stencilMaterial = stencilMaterial.clone();
  stencilMaterial.stencilZPass = THREE.DecrementStencilOp; // glStencilOp(GL_KEEP, GL_KEEP, GL_DECR);
  stencilMaterial.side = THREE.BackSide; // glCullFace(GL_FRONT);

  meshB = new THREE.Mesh(sceneObj.geometry, stencilMaterial);
  meshB.position.copy(sceneObj.position);
  meshB.quaternion.copy(sceneObj.quaternion);
  meshB.scale.copy(sceneObj.scale);
  scene.add(meshB); // geoB(); /* decrement the stencil buffer where the back face of geoB is drawn */

  const material = sceneObj.material.clone();
  copyMaterialSettings(stencilMaterial, material);

  material.depthWrite = true; // glDepthMask(GL_TRUE);
  material.colorWrite = true; // glColorMask(GL_TRUE, GL_TRUE, GL_TRUE, GL_TRUE);
  material.stencilFunc = testFunc;
  material.stencilRef = 0;
  material.stencilFuncMask = 1; // glStencilFunc(testFunc, 0, 1); // (GLenum func, GLint ref, GLuint mask)
  material.depthTest = true; // glEnable(GL_DEPTH_TEST);
  material.side = face; // glCullFace(face);

  meshA = new THREE.Mesh(stencilGeo, material);
  scene.add(meshA); // geoA(); /* draw the part of a that's in geoB */
}

/**
 * @param {THREE.Material} from 
 * @param {THREE.Material} to 
 */
function copyMaterialSettings(from, to) {
  var settings = [
    'depthFunc',
    'depthTest',
    'depthWrite',
    'stencilWrite',
    'stencilWriteMask',
    'stencilFunc',
    'stencilRef',
    'stencilFuncMask',
    'stencilFail',
    'stencilZFail',
    'stencilZPass',
  ];
  for (var i = 0; i < settings.length; i++) {
    to[settings[i]] = from[settings[i]];
  }
}
