import * as THREE from 'three';
import Stats from 'three/addons/libs/stats.module.js';
import { ExtendedMaterial } from 'three-extended-material';
import * as StencilGeo from './stencilGeo.js';
import { CutMesh, drawCutFaces } from './endcaprenderer.js';
import {
  vert as postVert,
  frag as postFrag,
} from './shaders/passthrough-with-depth.glsl.js';

export class ModelViewer {
  /**
   * Creates a new ModelViewer.
   * @param {HTMLElement} containerElement HTMLElement that will contain the viewer.
   */
  constructor(containerElement, renderStats = false, renderDepth = false) {
    this.renderStats = renderStats;
    this.renderDepth = renderDepth;

    this.setupDefaultValues(containerElement);
    this.setupRenderer(containerElement);
    this.setupBaseScene();
    this.setupCameras(containerElement);
    this.setupPostProcessingScene();
    this.setupToolCutting();
    this.setupListeners();
  }

  setupDefaultValues(container) {
    this._container = container;
    this.width = container.clientWidth;
    this.height = container.clientHeight;

    const clock = new THREE.Clock();

    this._clock = clock;
    this._isRunning = false;

    this.minZoom = 4.0;
    this.maxZoom = 0.5;
    this.zoomStep = 0.2;

    this.onToolRotated = () => {};

    this._target = new THREE.Vector3(0, 0, 0);

    this.toolGroup = null;
    this.endCapsGroup = null;
  }

  setupRenderer(container) {
    const renderer = new THREE.WebGLRenderer({
      autoClear: true,
      alpha: true,
      premultipliedAlpha: false,
      antialias: true,
    });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(container.clientWidth, container.clientHeight);
    renderer.setClearColor(0x000000, 0);

    // console.log(renderer.capabilities);

    container.appendChild(renderer.domElement);

    if (this.renderStats) {
      this.stats = new Stats();
      container.appendChild(this.stats.dom);
    }

    this._renderer = renderer;
  }

  setupBaseScene() {
    const target = new THREE.WebGLRenderTarget(
      this.width * this._renderer.getPixelRatio(),
      this.height * this._renderer.getPixelRatio(),
      {
        samples: this._renderer.capabilities.maxSamples,
        colorSpace: THREE.SRGBColorSpace,
        stencilBuffer: true,
      }
    );

    target.depthTexture = new THREE.DepthTexture();
    target.depthTexture.format = THREE.DepthStencilFormat;
    target.depthTexture.type = THREE.UnsignedInt248Type;

    const scene = new THREE.Scene();

    this.baseScene = scene;
    this.baseRT = target;
  }

  setupCameras(container) {
    const width = container.clientWidth;
    const height = container.clientHeight;

    const perspectiveCamera = new THREE.PerspectiveCamera(
      50,
      width / height,
      0.01,
      1000
    );

    this._perspectiveCamera = perspectiveCamera;

    const cameraSize = new THREE.Vector2(1, height / width).multiplyScalar(0.5);
    const orthographicCamera = new THREE.OrthographicCamera(
      -cameraSize.x,
      cameraSize.x,
      cameraSize.y,
      -cameraSize.y,
      0.01,
      1000
    );

    orthographicCamera.zoom = 1;

    this._orthographicCamera = orthographicCamera;
    orthographicCamera.position.set(0, 0, 10);

    this._cameraProjection = ModelViewer.CameraProjectionType.PERSPECTIVE;
    this.setCameraType(ModelViewer.CameraType.PERSPECTIVE);
  }

  setupPostProcessingScene() {
    if (!this.renderDepth) {
      return;
    }

    const scene = new THREE.Scene();

    const camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1);

    const material = new THREE.ShaderMaterial({
      vertexShader: postVert,
      fragmentShader: postFrag,
      uniforms: {
        renderDepth: { value: true },
        cameraNear: { value: this.camera.near },
        cameraFar: { value: this.camera.far },
        tSrcSceneTex: { value: null },
        tSrcDepthTex: { value: null },
      },
    });

    const postQuad = new THREE.Mesh(new THREE.PlaneGeometry(2, 2), material);

    scene.add(postQuad);

    this.postScene = scene;
    this.postCamera = camera;
    this.postMaterial = material;
  }

  setupToolCutting() {
    this.cutType = 0;
    this.toolCutPlane1Nrm = new THREE.Vector3(0, 0, 1);
    this.toolCutPlane2Nrm = new THREE.Vector3(1, 0, 0);
    this.toolMaterials = [];
    this.cutStencilWrapper = null;

    this.toolCutMaterialExtension = {
      name: 'model-preview-cut',
      uniforms: {
        cut: 0,
        p1Pos: new THREE.Vector3(0, 0, 0),
        p2Pos: new THREE.Vector3(0, 0, 0),
        p1Nrm: new THREE.Vector3(0, 0, 1),
        p2Nrm: new THREE.Vector3(1, 0, 0),
      },
      vertexShader: (shader) => {
        shader = `
          varying vec3 fragPosition;
          
          ${shader.replace(
            '#include <worldpos_vertex>',
            `
            #include <worldpos_vertex>
            fragPosition = (modelMatrix * vec4(position, 1.0)).xyz;
            `
          )}
        `;
        return shader;
      },
      fragmentShader: (shader) => {
        shader = `
          varying vec3 fragPosition;

          uniform int cut;
          uniform vec3 p1Pos;
          uniform vec3 p1Nrm;
          uniform vec3 p2Pos;
          uniform vec3 p2Nrm;
          
          bool isPointInFrontOfPlane(vec3 point, vec3 planePos, vec3 planeNrm) {
              vec3 v = point - planePos;
              float d = dot(v, planeNrm);
              return d > 0.0;
          }

          ${shader.replace(
            '#include <clipping_planes_fragment>',
            `
            bool clip;
            bool isP1 = isPointInFrontOfPlane(fragPosition, p1Pos, p1Nrm);
            bool isP2 = isPointInFrontOfPlane(fragPosition, p2Pos, p2Nrm);

            // no cut...
            if(cut == 0) {
                clip = false;
            }
            // 90
            else if(cut == 1) {
                clip = isP1 && isP2;
            }
            // 180
            else if(cut == 2) {
                clip = isP1;
            }
            // 270
            else if(cut == 3) {
                clip = isP1 || !isP2;
            }
            // 90, symmetry
            else if(cut == 4) {
                clip = (isP1 && isP2) || (!isP1 && !isP2);
            }

            if(clip) {
                discard;
                return;
            }
            #include <clipping_planes_fragment>
            `
          )}
        `;
        return shader;
      },
    };
  }

  setupListeners() {
    const canvas = this._renderer.domElement;

    // Setup Rotate Controls
    canvas.addEventListener('mousedown', (e) => this.onStartDragging(e));
    canvas.addEventListener(
      'touchstart',
      (e) => this.onStartDragging(e),
      false
    );
    canvas.addEventListener('wheel', this.onUserScroll.bind(this), {
      passive: false,
    });

    window.addEventListener('resize', () => this.onWindowResize());
  }

  get camera() {
    return this._cameraProjection ===
      ModelViewer.CameraProjectionType.PERSPECTIVE
      ? this._perspectiveCamera
      : this._orthographicCamera;
  }

  get cameraProjection() {
    return this._cameraProjection;
  }

  onWindowResize() {
    const dpr = this._renderer.getPixelRatio();
    this.width = this._container.clientWidth;
    this.height = this._container.clientHeight;

    // resize the renderer and render targets

    this._renderer.setSize(this.width, this.height);

    this.baseRT.setSize(this.width * dpr, this.height * dpr);

    // update the active camera

    this.camera.aspect = this.width / this.height;
    this.camera.updateProjectionMatrix();

    this.resetCamera(false);
  }

  onUserDragging(event) {
    if (!this._isDragging) return;

    event.preventDefault();

    const isTouchEvent = event.type.includes('touch');
    const clientX = isTouchEvent ? event.touches[0]?.clientX : event.clientX;
    const clientY = isTouchEvent ? event.touches[0]?.clientY : event.clientY;

    var deltaMove = {
      x: clientX - this._previousMousePosition.x,
      y: clientY - this._previousMousePosition.y,
    };

    this._previousMousePosition = { x: clientX, y: clientY };

    if (!this.toolGroup) {
      return;
    }

    const angleRadians = deltaMove.x * 0.005; // The 0.005 factor determines the rotation speed

    this.toolGroup.rotation.y += angleRadians;
    this.endCapsGroup.rotation.y += angleRadians;

    // now rotate the shader cut plane normals

    const axis = new THREE.Vector3(0, 1, 0);
    this.toolCutPlane1Nrm.applyAxisAngle(axis, angleRadians);
    this.toolCutPlane2Nrm.applyAxisAngle(axis, angleRadians);
    this.updateToolShaderUniforms();

    if (typeof this.onToolRotated === 'function') {
      this.onToolRotated((this.toolGroup.rotation.y * 180) / Math.PI);
    }
  }

  // Zoom based scrolling
  onUserScroll(event) {
    event.preventDefault();

    if (event.deltaY > 0) {
      this.zoomCameraOut();
    } else {
      this.zoomCameraIn();
    }
  }

  onStartDragging(event) {
    event.preventDefault();

    this._isDragging = true;

    const isTouchEvent = event.type.includes('touch');
    this._previousMousePosition = {
      x: isTouchEvent ? event.touches[0]?.clientX : event.clientX,
      y: isTouchEvent ? event.touches[0]?.clientY : event.clientY,
    };

    // Attach move and up listeners to the document to capture movement outside the canvas
    document.addEventListener(
      'mousemove',
      (this.draggingBound = this.onUserDragging.bind(this))
    );
    document.addEventListener(
      'mouseup',
      (this.stopDraggingBound = this.onStopDragging.bind(this))
    );
    document.addEventListener('touchmove', this.draggingBound, {
      passive: false,
    });
    document.addEventListener('touchend', this.stopDraggingBound);
  }

  onStopDragging() {
    // Remove document-wide events when stopping
    document.removeEventListener('mousemove', this.draggingBound);
    document.removeEventListener('mouseup', this.stopDraggingBound);
    document.removeEventListener('touchmove', this.draggingBound);
    document.removeEventListener('touchend', this.stopDraggingBound);

    this._isDragging = false;
  }

  dispose() {
    this.stop();

    if (this._renderer) {
      // this._renderer.clear()
      this._renderer.dispose();
      this._renderer.domElement.remove();
    }
  }

  run(updateHandler) {
    if (this._isRunning) {
      return;
    }

    this._isRunning = true;

    if (window.navigator.userAgent.indexOf('Edg') > -1) {
      const intHandle = setInterval(
        function () {
          if (!this._isRunning) {
            clearInterval(intHandle);
            return;
          }
          this._update();
          if (updateHandler) {
            updateHandler();
          }
          this._render();
        }.bind(this),
        1000 / 60
      );
    } else {
      const animate = () => {
        if (!this._isRunning) {
          return;
        }
        requestAnimationFrame(animate);
        this._update();
        if (updateHandler) {
          updateHandler();
        }
        this._render();
      };
      requestAnimationFrame(animate);
    }
  }

  stop() {
    this._isRunning = false;
  }

  /**
   * Changes from the current camera to the specified one.
   * @param {ModelViewer} cameraType Type of camera to change to.
   */
  setCameraType(cameraType) {
    if (cameraType == ModelViewer.CameraType.PERSPECTIVE) {
      this._cameraProjection = ModelViewer.CameraProjectionType.PERSPECTIVE;
    } else {
      this._cameraProjection = ModelViewer.CameraProjectionType.ORTHOGRAPHIC;
    }

    /**
     * @type {THREE.Vector3}
     */
    let position;

    const offset = 10;

    switch (cameraType) {
      case ModelViewer.CameraType.PERSPECTIVE: {
        position = new THREE.Vector3(0, 0, 0.5);
        break;
      }
      case ModelViewer.CameraType.FRONT: {
        position = new THREE.Vector3(0, 0, offset);
        break;
      }
      case ModelViewer.CameraType.BACK: {
        position = new THREE.Vector3(0, 0, -offset);
        break;
      }
      case ModelViewer.CameraType.LEFT: {
        position = new THREE.Vector3(-offset, 0, 0);
        break;
      }
      case ModelViewer.CameraType.RIGHT: {
        position = new THREE.Vector3(offset, 0, 0);
        break;
      }
      case ModelViewer.CameraType.TOP: {
        position = new THREE.Vector3(0, offset, 0);
        break;
      }
      case ModelViewer.CameraType.BOTTOM: {
        position = new THREE.Vector3(0, -offset, 0);
        break;
      }

      default: {
        console.warn(`setting camera to unknown type: '${cameraType}'.`);
        position = new THREE.Vector3(0, 0, 0);
        break;
      }
    }

    this.camera.position.copy(position);
    this.camera.updateProjectionMatrix();
    requestAnimationFrame(() => this._render());
  }

  zoomCameraOut() {
    if (
      this._cameraProjection == ModelViewer.CameraProjectionType.PERSPECTIVE
    ) {
      const direction = new THREE.Vector3()
        .subVectors(this.camera.position, this._target)
        .normalize();

      // Calculate the current distance to the target.
      const zoomDistance = this.camera.position.distanceTo(this._target);

      // Check if we are closer than 'minZoom'.
      if (zoomDistance < this.minZoom) {
        // Calculate how much too close we are (negative if we are within bounds, positive if too close).
        const excessDistance = this.minZoom - zoomDistance;

        // Proceed if we are indeed too close.
        if (excessDistance > 0) {
          // Calculate the corrective step needed. It needs to be at least the excess distance, but can be up to 'zoomStep'.
          // However, if the excess distance is larger than the step, we limit it to just neutralizing the excess.
          const stepDistance = Math.min(this.zoomStep, excessDistance);

          // Scale the direction vector by the step distance
          direction.multiplyScalar(stepDistance);

          // Move the camera backwards, correcting the excessive closeness.
          this.camera.position.add(direction);
        }
      }
    } else if (
      this._cameraProjection == ModelViewer.CameraProjectionType.ORTHOGRAPHIC
    ) {
      this.camera.zoom = Math.max(
        this.minZoom,
        Math.min(this.maxZoom, this.camera.zoom - this.zoomStep)
      );
      this.camera.updateProjectionMatrix();
    }
  }

  zoomCameraIn() {
    if (
      this._cameraProjection == ModelViewer.CameraProjectionType.PERSPECTIVE
    ) {
      const direction = new THREE.Vector3()
        .subVectors(this.camera.position, this._target)
        .normalize();

      // Calculate the current distance to the target.
      const zoomDistance = this.camera.position.distanceTo(this._target);

      // Calculate how much closer we can get to the target without exceeding 'maxZoom'.
      const allowedDistance = zoomDistance - this.maxZoom;

      // If we are further than 'maxZoom', calculate a correct step.
      if (allowedDistance > 0) {
        // Find the minimum between the remaining allowed distance and the zoom step.
        const stepDistance = Math.min(this.zoomStep, allowedDistance);

        // Apply the scaled direction to move the camera closer without exceeding 'maxZoom'.
        direction.multiplyScalar(stepDistance);
        this.camera.position.sub(direction);
      }
    } else if (
      this._cameraProjection == ModelViewer.CameraProjectionType.ORTHOGRAPHIC
    ) {
      this.camera.zoom = Math.max(
        this.minZoom,
        Math.min(this.maxZoom, this.camera.zoom + this.zoomStep)
      );
      this.camera.updateProjectionMatrix();
    }
  }

  resetCamera(resetRotationAndCuts = true) {
    this._zoomToFit(this._objectWidth, this._objectHeight);

    if (!resetRotationAndCuts) {
      return;
    }

    this.toolGroup.rotation.y = 0;
    this.endCapsGroup.rotation.y = 0;

    this.cutType = 0;
    this.toolCutPlane1Nrm.set(0, 0, 1);
    this.toolCutPlane2Nrm.set(1, 0, 0);

    this.updateToolShaderUniforms();

    this.disposeStencil();
  }

  _update() {
    const timeDelta = this._clock.getDelta();

    this._controls?.update(timeDelta);
    this.stats?.update();
  }

  _render() {
    if (!this.renderDepth) {
      this._renderer.setRenderTarget(null);
      this._renderer.render(this.baseScene, this.camera);
      return;
    }

    // render the scene

    this._renderer.setRenderTarget(this.baseRT);
    this._renderer.render(this.baseScene, this.camera);

    // upload the render textures to the compositing shader

    this.postMaterial.uniforms.tSrcSceneTex.value = this.baseRT.texture;
    this.postMaterial.uniforms.tSrcDepthTex.value = this.baseRT.depthTexture;

    // render to the canvas element

    this._renderer.setRenderTarget(null); // falls back to canvas
    this._renderer.render(this.postScene, this.postCamera);
  }

  /**
   * Returns the zoom to fit an object with the specified width and height.
   */
  _zoomToFit(objectWidth, objectHeight, zoomFitBuffer = 1.25) {
    if (this._objectWidth == undefined || this._objectHeight == undefined) {
      this._objectWidth = objectWidth;
      this._objectHeight = objectHeight;
    }

    objectWidth *= zoomFitBuffer;
    objectHeight *= zoomFitBuffer;

    if (
      this._cameraProjection === ModelViewer.CameraProjectionType.PERSPECTIVE
    ) {
      let camera = this.camera;

      const aspectRatio = camera.aspect;
      const vFOV = (camera.fov * Math.PI) / 180; // convert vertical fov to radians

      // Calculate the distance required to fit the object height in view
      const distanceForHeight = objectHeight / 2 / Math.tan(vFOV / 2);

      // Calculate the width that the camera can view at the distance calculated for height
      const widthAtDistanceForHeight =
        distanceForHeight * Math.tan(vFOV / 2) * 2 * aspectRatio;

      // Calculate the distance required to fit the object width in view
      let distanceForWidth;
      if (objectWidth > widthAtDistanceForHeight) {
        const horizontalFOV = 2 * Math.atan(Math.tan(vFOV / 2) * aspectRatio);
        distanceForWidth = objectWidth / 2 / Math.tan(horizontalFOV / 2);
      } else {
        distanceForWidth = distanceForHeight; // object fits within width at the distance calculated for its height
      }

      // Use the maximum of the two distances to ensure the object will fit both in width and height
      const requiredDistance = Math.max(distanceForHeight, distanceForWidth);

      // set our zoom parameters, relative to our object size
      this.minZoom = requiredDistance * 2;
      this.maxZoom = Math.min(requiredDistance * 0.5, objectWidth);
      this.zoomStep = requiredDistance / 8;

      // update our depth renderer to make depth more apparent
      if (this.renderDepth) {
        this.camera.far = this.minZoom + objectWidth;
        this.postMaterial.uniforms.cameraFar.value = this.camera.far;
      }

      // Update camera position based on the required distance
      // This calculation assumes the camera is looking at the object from directly in front on the Z axis.
      // You may need to adjust this based on your actual camera/object positions and orientations.
      const directionVector = new THREE.Vector3()
        .subVectors(camera.position, new THREE.Vector3(0, 0, 0))
        .normalize(); // assuming you have a 'target' for OrbitControls or similar
      const newPosition = directionVector
        .multiplyScalar(requiredDistance)
        .add(new THREE.Vector3(0, 0, 0));

      camera.position.set(newPosition.x, newPosition.y, newPosition.z);
      camera.updateProjectionMatrix();

      // If using OrbitControls, you might need to update its target or force an update after moving the camera
      // For example, if your controls variable is named 'orbitControls':
      this._controls?.update();
    } else {
      let camera = this.camera;

      const cameraHeight = camera.top - camera.bottom;
      const cameraWidth = camera.right - camera.left;

      const hZoom = cameraWidth / objectWidth;
      const vZoom = cameraHeight / objectHeight;

      this.minZoom = Math.min(hZoom, vZoom);
      this.maxZoom = Math.max(this.minZoom / 4.0, objectWidth);
      this.zoomStep = this.minZoom / 16.0;

      camera.zoom = Math.max(
        this.minZoom,
        Math.min(this.maxZoom, this.minZoom)
      );

      camera.updateProjectionMatrix();

      this._controls?.update();
    }
  }

  /**
   * @param {THREE.Object3D} object3d
   */
  addModel(object3d) {
    if (!object3d || !object3d.children || !object3d.children.length) {
      console.warn('Attempted to add invalid tool', object3d);
      return;
    }

    this.toolGroup = object3d;
    this.endCapsGroup = new THREE.Group();

    let material;
    this.toolMaterials = this.toolGroup.children.map((child) => {
      const { color, metalness, roughness, envMap, envMapIntensity } =
        child.material;

      material = new ExtendedMaterial(
        THREE.MeshStandardMaterial,
        [this.toolCutMaterialExtension],
        {
          color,
          metalness,
          roughness,
          envMap,
          envMapIntensity,
        }
      );

      child.material = material;

      return material;
    });

    this.baseScene.add(this.toolGroup);
    this.baseScene.add(this.endCapsGroup);
  }

  removeModel() {
    throw new Error('Not implemented');
  }

  applyCut(startAngle, cutAngle, isMirrored) {
    if (!this.toolGroup) {
      return;
    }

    // first apply the cut(s) to the base shader

    let cutType = 0;

    // determine our cut angle

    if (cutAngle === 90 && !isMirrored) {
      cutType = 1;
    } else if (cutAngle === 180) {
      cutType = 2;
    } else if (cutAngle === 270) {
      cutType = 3;
    } else if (cutAngle === 90) {
      cutType = 4;
    }

    // convert to radians, and then negate since we initially rotate the tool -z
    startAngle *= Math.PI / 180.0;
    startAngle *= -1;

    // determine the angle in which to rotate the planes – changing the normal

    const p1Normal = new THREE.Vector3(0, 0, 1);
    const p2Normal = new THREE.Vector3(1, 0, 0);
    const axis = new THREE.Vector3(0, 1, 0);
    const angle = startAngle + this.toolGroup.rotation.y;

    p1Normal.applyAxisAngle(axis, angle);
    p2Normal.applyAxisAngle(axis, angle);

    // copy our rotation to our class properties, and update the shader

    this.cutType = cutType;
    this.toolCutPlane1Nrm.copy(p1Normal);
    this.toolCutPlane2Nrm.copy(p2Normal);

    this.updateToolShaderUniforms();

    // next draw the end-caps

    this.drawEndCaps(cutType, axis, startAngle);
  }

  updateToolShaderUniforms(uniforms) {
    if (!this.toolMaterials.length) {
      return;
    }

    uniforms ??= {};

    let { cut, p1Nrm, p2Nrm } = uniforms;

    cut ??= this.cutType;
    p1Nrm ??= this.toolCutPlane1Nrm.clone();
    p2Nrm ??= this.toolCutPlane2Nrm.clone();

    this.toolMaterials.forEach((material) => {
      if (typeof cut === 'number') {
        material.uniforms.cut.value = cut;
      }

      if (typeof p1Nrm === 'object' && p1Nrm instanceof THREE.Vector3) {
        material.uniforms.p1Nrm.value = p1Nrm;
      }

      if (typeof p2Nrm === 'object' && p2Nrm instanceof THREE.Vector3) {
        material.uniforms.p2Nrm.value = p2Nrm;
      }
    });
  }

  drawEndCaps(cutType, axis, startAngle) {
    if (this.cutStencilWrapper) {
      this.disposeStencil();
    }

    const size = new THREE.Vector2(this._objectWidth, this._objectHeight);

    // create the rotator wrapper object

    const rotator = new THREE.Group();
    this.endCapsGroup.add(rotator);

    this.cutStencilWrapper = rotator;

    // create the stencil geometry, and determine the position offset

    let stencilGeo;

    switch (cutType) {
      case 1:
        stencilGeo = new StencilGeo.QuarterCut(size.x, size.y);
        break;

      case 2:
        stencilGeo = new StencilGeo.HalfCut(size.x, size.y);
        break;

      case 3:
        stencilGeo = new StencilGeo.ThreeQuarterCut(size.x, size.y);
        break;

      case 4:
        stencilGeo = new StencilGeo.QuarterSymmetricCut(size.x, size.y);
        break;

      default:
        return;
    }

    // render the end-caps using the stencil buffer

    // calculate inverse of rotator rotation
    const offset = new THREE.Quaternion()
      .setFromAxisAngle(axis, startAngle)
      .invert();

    let toolObj, toolQuat;
    this.toolGroup.children.forEach((toolMesh) => {
      // we pass a quaternion that is inverses the rotator rotation applied
      // below so the tool orientation is preserved; we only need to rotate
      // the cut faces
      toolQuat = new THREE.Quaternion().multiplyQuaternions(
        offset,
        toolMesh.quaternion
      );

      toolObj = new CutMesh(
        toolMesh.geometry,
        toolMesh.material,
        toolMesh.position,
        toolQuat,
        toolMesh.scale
      );

      drawCutFaces(stencilGeo, toolObj, rotator);
    });

    rotator.rotateOnAxis(axis, startAngle);
  }

  disposeStencil() {
    if (!this.cutStencilWrapper) {
      return;
    }

    this.cutStencilWrapper.removeFromParent();

    this.cutStencilWrapper.children.forEach((child) => {
      child.geometry.dispose();
      child.material.dispose();
    });

    this.cutStencilWrapper = null;
  }
}

/**
 * Camera projection types.
 * @enum {Number}
 */
ModelViewer.CameraProjectionType = Object.freeze({
  PERSPECTIVE: 1,
  ORTHOGRAPHIC: 2,
});

/**
 * @enum {Number}
 */
ModelViewer.CameraType = Object.freeze({
  PERSPECTIVE: 1,
  FRONT: 2,
  BACK: 3,
  LEFT: 4,
  RIGHT: 5,
  TOP: 6,
  BOTTOM: 7,
});

export default ModelViewer;
