/**
 * @file Classes and methods for using and manipulation affine transforms.
 */

import {Vector3} from "./vector3.js";

class Matrix4 {
    /**
     * Creates a new Matrix4 with the specified components.
     * @param {Array<Number>|Matrix4} components Initial components.
     */
    constructor(components) {
        let initialComponents;
        
        if (components instanceof Matrix4) {
            initialComponents = components.components;
        }  
        else {
            initialComponents = components;
        }
        
        this.components = initialComponents.slice();
    }

    // --- PROPERTIES ---

    get m11() { return this.components[0]; }
    get m12() { return this.components[1]; }
    get m13() { return this.components[2]; }
    get m14() { return this.components[3]; }

    get m21() { return this.components[4]; }
    get m22() { return this.components[5]; }
    get m23() { return this.components[6]; }
    get m24() { return this.components[7]; }

    get m31() { return this.components[8]; } 
    get m32() { return this.components[9]; }
    get m33() { return this.components[10]; }
    get m34() { return this.components[11]; }

    get m41() { return this.components[12]; }
    get m42() { return this.components[13]; }
    get m43() { return this.components[14]; }
    get m44() { return this.components[15]; }

    set m11(value) { this.components[0] = value; }
    set m12(value) { this.components[1] = value; }
    set m13(value) { this.components[2] = value; }
    set m14(value) { this.components[3] = value; }

    set m21(value) { this.components[4] = value; }
    set m22(value) { this.components[5] = value; }
    set m23(value) { this.components[6] = value; }
    set m24(value) { this.components[7] = value; }

    set m31(value) { this.components[8] = value; } 
    set m32(value) { this.components[9] = value; }
    set m33(value) { this.components[10] = value; }
    set m34(value) { this.components[11] = value; }

    set m41(value) { this.components[12] = value; }
    set m42(value) { this.components[13] = value; }
    set m43(value) { this.components[14] = value; }
    set m44(value) { this.components[15] = value; }
    
    toString() {
        const result = `${this.components[0]}, ${this.components[1]}, ${this.components[2]}, ${this.components[3]}\n${this.components[4]}, ${this.components[5]}, ${this.components[6]}, ${this.components[7]}\n${this.components[8]}, ${this.components[9]}, ${this.components[10]}, ${this.components[11]}\n${this.components[12]}, ${this.components[13]}, ${this.components[14]}, ${this.components[15]}`;
        return result;
     }
     
    /**
     * Returns whether this matrix is equal to another.
     * @param {Matrix4}
     */
    isEqualTo(other) {
        return components_equal(this.components, other.components);
    }

    /**
     * Multiplies against another matrix or a scalar and returns the result.
     * @param {Matrix4|Number} right Matrix or scalar to multiply against.
     * @returns {Matrix4} Multiplication result.
     */
    multiply(right) {
        let resultComponents;
        
        if (typeof right == "number") {
            resultComponents = this.components.map(m => m * right);
        }
        else {
            resultComponents = multiply_components(this.components, right.components, 4, 4);
        }
        
        const result = new Matrix4(resultComponents);
        return result;
     }

    /**
     * Transposes the matrix and returns the result.
     * @returns {Matrix4} Transposed matrix.
     */
    transpose() {
        const resultComponents = transpose_components(this.components, 4);
        const result = new Matrix4(resultComponents);

        return result;
    }
}

/**
* Identity transform.
*/
Matrix4.identity = Object.freeze(new Matrix4([
    1, 0, 0, 0,
    0, 1, 0, 0,
    0, 0, 1, 0,
    0, 0, 0, 1
]));

/**
* Zero'd out transform.
*/
Matrix4.zero = Object.freeze(new Matrix4([
    0, 0, 0, 0,
    0, 0, 0, 0,
    0, 0, 0, 0,
    0, 0, 0, 0
]));

/**
 * Return a translation matrix with specified offsets.
 * @param {Number} sx X-Axis offset.
 * @param {Number} sy Y-Axis offset.
 * @param {Number} sz Z-Axis offset.
 */
Matrix4.translation = function(tx, ty, tz) {
    return new Matrix4([
        1, 0, 0, tx,
        0, 1, 0, ty,
        0, 0, 1, tz,
        0, 0, 0, 1
    ]);
};

/**
 * Return a matrix with the specified scalar values.
 * 
 * Unspecified values will be repeats of their previous values (Ex. scale(5) is the same as  scale(5,5,5)).
 * @param {Number} sx X-Axis scalar value.
 * @param {Number} sy Y-Axis scalar value.
 * @param {Number} sz Z-Axis scalar value.
 */
Matrix4.scale = function(sx, sy, sz) {
    if (typeof sx === "undefined") {
        sx = 1;
    }
    
    if (typeof sy === "undefined") {
        sy = sx;
    }
    
    if (typeof sz === "undefined") {
        sz = sy;
    }
    
    return new Matrix4([
        sx, 0,  0,  0,
        0,  sy, 0,  0,
        0,  0,  sz, 0,
        0,  0,  0,  1
    ]);
};

/**
 * Creates matrix that rotates the specified angle around the specified axis.
 * @param {Vector3} axis Axis of the rotation.
 * @param {Number} angle Rotation angle in radians.
 * @returns {Matrix4} Rotation matrix.
 */
Matrix4.rotation = function(axis, angle) {
    const a = Math.cos(-angle);
    const b = Math.sin(-angle);
    const c = 1 - a;
    
    const axisNormal = axis.unitVector;
    
    const components = [
        c * axisNormal.x * axisNormal.x + a, c * axisNormal.x * axisNormal.y - b * axisNormal.z, c * axisNormal.x * axisNormal.z + b * axisNormal.y, 0,
        c * axisNormal.x * axisNormal.y + b * axisNormal.z, c * axisNormal.y * axisNormal.y + a, c * axisNormal.y * axisNormal.z - b * axisNormal.x, 0,
        c * axisNormal.x * axisNormal.z - b * axisNormal.y, c * axisNormal.y * axisNormal.z + b * axisNormal.x, c * axisNormal.z * axisNormal.z + a, 0,
        0, 0, 0, 1
    ];
    
    const result = new Matrix4(components);
    return result;
};

/**
 * @param {number} angle 
 * @returns {Matrix4}
 */
Matrix4.rotationX = function(angle) {
    const num = Math.cos(angle);
    const num2 = Math.sin(angle);
    return new Matrix4([
        1, 0, 0, 0,
        0, num, num2, 0,
        0, -num2, num, 0,
        0, 0, 0, 1
    ])
}

/**
 * @param {number} angle 
 * @returns {Matrix4}
 */
Matrix4.rotationY = function(angle) {
    const num = Math.cos(angle);
    const num2 = Math.sin(angle);
    return new Matrix4([
        num, 0, -num2, 0,
        0, 1, 0, 0,
        num2, 0, num, 0,
        0, 0, 0, 1
    ])
}

/**
 * @param {number} angle 
 * @returns {Matrix4}
 */
Matrix4.rotationZ = function(angle) {
    const num = Math.cos(angle);
    const num2 = Math.sin(angle);
    return new Matrix4([
        num, num2, 0, 0,
        -num2, num, 0, 0,
        0, 0, 1, 0,
        0, 0, 0, 1
    ])
}

/**
 * @param {Vector3} axis 
 * @param {number} angle 
 * @returns {Matrix4} 
 */
Matrix4.createFromAxisAngle = function(axis, angle) {
    return Matrix4.rotation(axis, angle);
}

/**
 * @param {Vector3} rotation 
 * @returns {Matrix4} 
 */
Matrix4.createEulerXYZRotation = function(rotation) {
    const rotationX = Matrix4.rotationX(rotation.x);
    const rotationY = Matrix4.rotationX(rotation.y);
    const rotationZ = Matrix4.rotationX(rotation.z)
    
    return rotationZ.multiply(rotationX.multiply(rotationY));
}

/**
 * Performs matrix multiplication between the components of two matrices and returns the result.
  * @param {Array<Number>} left Components for the left side matrix.
  * @param {Array<Number>} right Components for the right side matrix.
  * @param {Number} leftRows Number of rows for the left side matrix.
  * @param {Number} rightRows Number of rows for the right side matrix.
  * @returns {Array<Number>} Result components of the multiplication.
  */
function multiply_components(left, right, leftRows, rightRows) {
    const leftColumns = left.length / leftRows;
    const rightColumns = right.length / rightRows;

    const resultRows = leftRows;
    const resultColumns = rightColumns;

    let result = new Array(resultRows * resultColumns);
    let resultIndex = 0;

    let leftRowStartIndex = 0;
    let leftIndex = 0;

    for (let row = 0; row < resultRows; ++row) {
        for (let column = 0; column < resultColumns; ++column) {
            leftIndex = leftRowStartIndex;
            
            let rightIndex = column;
            let value = 0;

            for (let rightRow = 0; rightRow < rightRows; ++rightRow) {
               value += left[leftIndex] * right[rightIndex];
               
               leftIndex += 1;
               rightIndex += rightColumns;
            }

            result[resultIndex] = value;
            resultIndex += 1;
        }

        leftRowStartIndex += leftColumns;
    }

    return result;
}

/**
 * Transposes a matrix's components and returns the result.
 * @param {Array<Number>} components Components of a matrix.
 * @param {Number} rows Number of rows in the components.
 * @result {Array<Number>} Transposed components.
 */
function transpose_components(components, rows) {
    const columns = components.length / rows;

    const resultRows = columns;
    const resultColumns = rows;

    const result = new Array(resultRows * resultColumns);

    let resultIndex = 0;
    let srcColumnStart = 0;

    for (let column = 0; column < columns; ++column) {
       let srcIndex = srcColumnStart;

       for (let row = 0; row < rows; ++row) {
           result[resultIndex] = components[srcIndex];

           resultIndex += 1;
           srcIndex += columns;
       }

       srcColumnStart += 1;
    }

    return result;
}

/**
 * Returns whether the both sets of components are equal.
 * @param {Array<Number>} left One matrix's components.
 * @param {Array<Number>} right Another matrix's components.
 * @returns {Boolean} True if the components are equal or false otherwise.
 */
function components_equal(left, right) {
    let result = false;
    
    if (left.length == right.length) {
        result = true;
        
        for (var i = 0; i < left.length; ++i) {
            if (left[i] != right[i]) {
                result = false;
                break;
            }
        }
    }
    
    return result;
}

export { Matrix4 };
