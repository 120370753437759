import { Plane } from "../../../../base/math.js";
import { Matrix4 } from "../../math/matrix4.js";
import { SerialNode } from "./../serialnode.js";

// Hal3d.Serialization.CutProcessor.cs

class CutProcessor extends SerialNode {

    constructor(tolerance, createCaps) {
        super();
        this.name = 'CutProcessor';
        
        this.tolerance = tolerance;
        this.createCaps = createCaps;
    }

    /**
     * @param {CutProcessor.Modification} mod 
     */
    addModification(mod) {
        this.addChild(mod);
    }

    processModelData() {
        console.log('DO CUT HERE');
    }
}

CutProcessor.Modification = class extends SerialNode {
    /**
     * @abstract
     * @param {Matrix4} matrix 
     */
    transform(matrix) {}

    /**
     * @abstract
     * @param {*} mesh 
     */
    intersectWithMesh(mesh) {}

    /**
     * @abstract
     * @param {*} triangles 
     * @param {*} srcVertexWrapper 
     * @param {*} newVertices 
     */
    cutTriangles(triangles, srcVertexWrapper, newVertices) {}
}

CutProcessor.Cut = class extends CutProcessor.Modification {
    /**
     * @param {Plane} value
     */
    set plane(value) {
        this._plane = value;
        this._plane.normalize();
    }
    
    /**
     * @param {Plane} plane 
     */
    constructor(plane = null) {
        super();
        this.name = 'CutProcessor.Cut';

        this._plane = plane;
    }

    equals(other) { throw new Error('Not implemented') }
}

CutProcessor.Union = class extends CutProcessor.Modification {
    /**
     * @returns {Array<CutProcessor.Modification>} 
     */
    get modifications() {
        return this.getNodes(CutProcessor.Modification);
    }

    constructor() {
        super();
        this.name = 'CutProcessor.Union';
    }
    
    /**
     * @param {CutProcessor.Modification} mod 
     */
    addModification(mod) {
        this.addChild(mod);
    }

    equals(other) { throw new Error('Not implemented') }
}

CutProcessor.CompoundCut = class extends CutProcessor.Modification {
    constructor() {
        super();
        this.name = 'CutProcessor.CompoundCut';
    }
}

export {
    CutProcessor
}
