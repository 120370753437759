import {IAsset} from "./asset.js";

const IAssetInfo = Base => class IAssetInfo extends Base {
    /**
     * @returns {ObjectConstructor|string}
     */
    get assetType() {}

    /**
     * @returns {string}
     */
    get description() {}

    /**
     * @returns {Array<string>}
     */
    get assetIds() {}

    /**
     * @returns {Date}
     */
    get lastModifiedUtc() {}

    /**
     * Attempts to load the asset.
     * @returns {IAsset}
     */
    load() {}

    /**
     * Releases the specified asset.
     * @param {IAsset} asset Asset to be released.
     */
    release(asset) {}
};

export {IAssetInfo};
