import {SerialObject} from "./../serialization/serialobject.js";

/**
 * The base class for renderer resources that can be serialized.
 */
class Resource extends SerialObject {
    constructor() {
        super();
        
        this.name = "";
        
        this.description = "";
    }
    
    /**
     * A virtual override used to upload this resource.
     */
    upload() {
        throw new Error("Not implemented.");
    }
    
    _writeXmlAttributes(xmlDoc) {
        const attributes = super._writeXmlAttributes(xmlDoc);
        attributes.set("Name", this.name);
        
        return attributes;
    }
    
    _readXmlAttributes(data, element, parent) {
        throw new Error("Not implemented from original.");
    }
}

export {Resource};
