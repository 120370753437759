import {AABB} from "../aabb.js"
import {Material} from "./../renderer/material.js";
import {IMesh} from "./../renderer/mesh.js";
import {RenderCall} from "./../renderer/rendercall.js";
import {UniformContainer} from "./../renderer/uniform.js"
import {MeshNodeRoot} from "./../scenenodes.js";
import {NamedReference} from "./../serialization.js";
import {XFormNode} from "../xformnode.js";

/**
 * A scene node that references a mesh to be rendered using a transform and certain materials.
 */
class MeshNode extends XFormNode {
    constructor() {
        super();

        /**
         * The mesh that is rendered by this mesh node.
         * @type {NamedReference}
         */
        this._mesh = new NamedReference(null, IMesh);

        this.wsBounds = new AABB();

        this.allowTrace = true;

        /**
         * The render calls generated by this mesh node. These combine the draw calls of the mesh 
         * with a transform matrix and a set of materials.
         * @type {Array<RenderCall>}
         */
        this.renderCalls = [];

        /**
         * 
         * @type {UniformContainer}
         */
        this.uniforms = null;
    }

    /**
     * The name of the mesh this node should render.
     */
    get meshName() {
        return this._mesh.name;
    }

    /**
     * The name of the mesh this node should render.
     * @param {string} value Name of the mesh.
     */
    set meshName(value) {
        this._mesh.name = value;
    }

    get mesh() {
        const result = this._mesh ? this._mesh.object : null;
        return result;
    }

    set mesh(value) {
        if (this._mesh && this._mesh.object != value) {
            const oldMesh = this._mesh.object;
            this._mesh.object = value;
            this._onNewMesh(oldMesh);
        }
    }

    /**
     * 
     * @param {Uniform} uniform Uniform to add as child.
     */
    addUniform(uniform) {
        this.addChild(uniform);
    }

    /**
     * 
     * @param {Uniform} uniform Child uniform to remove.
     */
    removeUniform(uniform) {
        if (uniform.parent == this) {
            uniform.removeFromParent();
        }
    }

    /**
     * 
     * @param {RenderCall} renderCall Render call to add.
     */
    addRenderCall(renderCall) {
        // renderCall.owner = this; // TODO: not implemented

        // add ourselves as a uniform source for this call.
        // renderCall.uniforms.push(this);  // TODO: not implemented

        this.renderCalls.push(renderCall);

        // TODO: updater functionality not implemented.
    }

    /**
     * 
     * @param {RenderCall} renderCall Render call to be removed.
     */
    removeRenderCall(renderCall) {
        // make sure this call belongs to the is mesh node.
        if (renderCall.owner == this) {
            // make sure the call doesn't reference us anymore.
            renderCall.owner = null;

            const index = this.renderCalls.indexOf(renderCall);
            if (index != -1) {
                this.renderCalls.splice(index, 1);
            }

            if (this._updater) {
                this._removeRenderCallFromUpdater(renderCall);
            }
        }
        else {
            throw new Error("Render calld oes not belong to this MeshNode.");
        }
    }

    /**
     * Setting the named object owner will allow any named objects referenced by this
     * mesh node to be resolved from their names after being deserialized.
     * @param {Any} owner 
     */
    setNamedObjectOwner(owner) {
        super.setNamedObjectOwner(owner);

        const oldMesh = this._mesh;
        this._mesh.owner = owner;

        this.renderCalls.forEach(call => {
            call.uniforms = [];
            call.inlineMaterial = owner.getNamedObject(call.materialName, Material);

            call.uniforms.push(this);
        });

        this._onNewMesh(oldMesh);
    }

    /**
     * 
     * @param {IMesh} mesh 
     */
    _onNewMesh(mesh) {
        // TODO: remove callback
        // TODO: add callback

        this._mapRenderCallsToDrawCalls();
        this._updateWorldSpaceBounds();
    }

    _mapRenderCallsToDrawCalls() {
        if (this._mesh.object) {
            if (this.renderCalls.length > 0) {
                /**
                 * @type {MeshNodeRoot}
                 */
                let root = null;
                
                let addCalls = false;

                if (this._updater) {
                    root = this.getAncestor(MeshNodeRoot);
                    addCalls = true;
                }

                let renderCallIndex = 0;

                this._mesh.object.drawCalls.forEach(drawCall => {
                    if (renderCallIndex < this.renderCalls.length) {
                        const renderCall = this.renderCalls[renderCallIndex];
                        renderCallIndex += 1;

                        if (this._mesh.object) {
                            renderCall.drawCall = drawCall;

                            if (addCalls) {
                                this._addRenderCallToUpdater(renderCall, root);
                            }
                        }
                    }
                });
            }

            // ensure our bounds get updated
            this._worldTransformNeedsUpdate = true;
            // this._addToSyncList();   // TODO: not implemented, implement if needed.
        }
    }

    _updateWorldSpaceBounds() {

    }
    
    _writeXmlAttributes(xmlDoc) {
        const attributes = super._writeXmlAttributes(xmlDoc);
        
        attributes.set("Mesh", this._mesh.name);
        
        return attributes;
    }
    
    _writeXmlElements(xmlDoc) {
        const elements = super._writeXmlElements(xmlDoc);
        
        this.renderCalls.forEach(renderCall => {
            const element = xmlDoc.createElement("RenderCall");
            element.setAttribute("Material", renderCall.materialName);
            
            elements.push(element);
        });
        
        return elements;
    }
}

export { MeshNode };
