import * as siteConfig from '../siteconfig';
import { oktaAuth } from '../services/okta-service';
// Tool details page calls

export const fetchChatBotToken = async () => {
    const accessToken = (await oktaAuth.tokenManager.get('accessToken'))
        ?.accessToken;
    const response = await fetch(`${siteConfig.searchApiUrl}/chatbot/session`, {
        headers: {
            accept: 'text/plain',
            Authorization: `Bearer ${accessToken}`,
        },
    });
    if (!response.ok) {
        throw new Error('Failed to get access token');
    }
    return response.json();
};