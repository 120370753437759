import React, { useContext } from 'react';
import styled from 'styled-components';
import ShoppingCartViewContext from './ShoppingCartViewContext.js';

import LineItem from './LineItem.jsx';

import ApplicationContext from '../../ApplicationContext.js';

const MyCartContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 500px;
  font-family: Univers;
  margin-left: 112px;
  margin-right: 112px;
  background-color: white;
  // border-radius: 10px;
  pointer-events: ${(props) => (props.$isDisabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.$isDisabled ? '0.4' : '1')};
  z-index: 3;
  @media (max-width: 1024px) {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MyCartTitle = styled.div`
  color: var(--color-gray1);
  font-weight: 500;
  font-size: 28px;
  margin: 20px 0px 18px 20px;
  @media (max-width: 1024px) {
    font-size: 22px;
    margin-bottom: 12px;
    margin-top: 14px;
    margin-left: 5%;
  }
`;

const ClearCartButton = styled.button`
  border: none;
  background: none;
  width: 142px;
  height: 40px;
  font-family: Univers;
  font-weight: 500;
  font-size: 16px;
  color: #6d6d6d;
  border: solid 1px #6d6d6d;
  // border-radius: 10px;
  cursor: pointer;
  margin-right: 16px;

  @media (max-width: 1024px) {
    width: 100px;
    height: 34px;
    font-size: 14px;
    margin-right: 5%;
  }
`;

const RequestQuoteButton = styled.button`
  align-self: end;
  font-family: Univers;
  font-weight: 500;
  font-size: 16px;
  border: none;
  background: none;
  width: 208px;
  height: 40px;
  // border-radius: 10px;
  background-color: ${(props) =>
    props.disabled ? 'var(--color-gray4)' : 'var(--color-primary)'};
  cursor: pointer;
  color: white;
  margin-top: ${(props) => (props.disabled ? 'auto' : '14px')};
  margin-right: 14px;
  margin-bottom: 14px;
  pointer-events: ${(props) =>
    props.disabled || props.$isRequestingQuote ? 'none' : 'auto'};
  ${(props) => props.$isRequestingQuote && 'font-style: italic;'}
  @media (max-width: 1024px) {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
`;

const EmptyCart = styled.div`
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-gray3);
  font-size: 28px;
  font-weight: 500;
`;

const MyCart = () => {
  const {
    shoppingCartItemsData,
    itemBeingDeleted,
    isRequestingQuote,
    removeAllItemsFromCart,
    requestQuote,
  } = useContext(ShoppingCartViewContext);

  const { classType } = useContext(ApplicationContext);

  return (
    <MyCartContainer $isDisabled={itemBeingDeleted || isRequestingQuote}>
      <HeaderContainer>
        <MyCartTitle>My Cart</MyCartTitle>
        {shoppingCartItemsData?.length !== 0 && (
          <ClearCartButton onClick={removeAllItemsFromCart}>
            Clear Cart
          </ClearCartButton>
        )}
      </HeaderContainer>
      {shoppingCartItemsData?.length > 0 ? (
        shoppingCartItemsData?.map((product) => (
          <LineItem
            key={product.SapNumber}
            productName={product.Name}
            myCart
            quantity={product.Quantity}
            isShoppingCartView
            sapNumber={product.SapNumber}
            productPropertyData={product.DescriptionLite}
          />
        ))
      ) : (
        <EmptyCart>Cart Empty</EmptyCart>
      )}

      <RequestQuoteButton
        onClick={requestQuote}
        disabled={!shoppingCartItemsData?.length}
        $isRequestingQuote={isRequestingQuote}
      >
        {isRequestingQuote ? 'Submitting Request...' : 'Place Request'}
      </RequestQuoteButton>
    </MyCartContainer>
  );
};

export default MyCart;
