import { AABB } from './aabb'

export class Material {
    constructor() {
        /**
         * @type {Map<String, Any>}
         */
        this.uniforms = new Map();
    }
}

export class Mesh {
    constructor() {
        /**
         * Bounds of the Mesh
         */
        this.bounds = new AABB();

        /**
         * @type {Array<Mesh.DrawCall>}
         */
        this.drawCalls = [];
    }
}

Mesh.DrawCall = class {
    constructor() {
        /**
         * Name of the vertex buffer to use.
         * @type {String}
         */
        this.vertexBuffer;

        /**
         * Name of the index buffer to use.
         * @type {String}
         */
        this.indexBuffer;

        /**
         * The type of primitive the vertex data represents.
         * @type {Mesh.DrawCall.Primitive}
         */
        this.primitive;

        /**
         * Start offset in the index buffer.
         * @type {Number}
         */
        this.indexOffset;

        /**
         * Number of indices to use.
         * @type {Number}
         */
        this.indexCount;
    }
}

/**
 * @enum {Number}
 */
Mesh.DrawCall.Primitive = Object.freeze({
    TRIANGLES: 0
});

export class VertexBufferAttribute {
    /**
     * Creates a new VertexBufferAttribute
     * @param {Float32Array | Uint32Array} buffer Buffer of values.
     * @param {Number} count Number of values per element.
     */
    constructor(buffer, count) {
        this.buffer = buffer;
        this.count = count;
    }
}

export class Model {
    /**
     * Creates a new Model.
     */
    constructor() {
        /**
         * @type {TreeNode}
         */
        this.scene = null;

        /**
         * @type {Map<String, Map<String, VertexBufferAttribute>>}
         */
        this._vertexBuffers = new Map();

        /**
         * @type {Map<String, Uint32Array>}
         */
        this._indexBuffers = new Map();

        /**
         * @type {Map<String, Material>}
         */
        this._materials = new Map();

        /**
         * @type {Map<String,Mesh>}
         */
        this._meshes = new Map();
    }

    /**
     * Returns the specified vertex buffer attribute.
     * @param {String} name Name of the vertex buffer.
     * @param {String} attributeName Name of the attribute.
     * @return {VertexBufferAttribute} Specified vertex buffer attribute.
     */
    getVertexBufferAttribute(name, attributeName) {
        let attribute = null;

        const vertexBuffer = this._vertexBuffers.get(name);
        if (vertexBuffer) {
            attribute = vertexBuffer.get(attributeName);
        }

        return attribute;
    }

    /**
     * Sets the specified buffer as an attribute for a vertex buffer.
     * @param {String} name Name of the vertex buffer.
     * @param {String} attributeName Name of the attribute.
     * @param {Float32Array | Uint32Array} buffer Buffer to store in the attribute
     * @param {Number} count Number of values per element in the buffer.
     */
    setVertexBufferAttribute(name, attributeName, buffer, count) {
        let attributes = this._vertexBuffers.get(name);
        if (!attributes) {
            attributes = new Map();
            this._vertexBuffers.set(name, attributes);
        }

        attributes.set(attributeName, new VertexBufferAttribute(buffer, count));
    }

    /**
     * Returns the specified index buffer.
     * @param {String} name Name of the index buffer.
     * @return {Uint32Array} Index buffer;
     */
    getIndexBuffer(name) {
        const buffer = this._indexBuffers.get(name);
        return buffer;
    }

    /**
     * Sets the specified indices as an index buffer.
     * @param {String} name Name of the index buffer.
     * @param {Uint32Array} buffer Buffer containing index values.
     */
    setIndexBuffer(name, buffer) {
        this._indexBuffers.set(name, buffer);
    }

    /**
     * Returns the material for the specified name.
     * @param {String} name Name of the material.
     */
    getMaterial(name) {
        const material = this._materials.get(name);
        return material;
    }

    /**
     * Sets the material for the specified name.
     * @param {String} name Name for the material.
     * @param {String} material Material to be set.
     */
    setMaterial(name, material) {
        this._materials.set(name, material);
    }

    /**
     * Sets the specified mesh.
     * @param {String} name Name of the mash.
     * @param {Mesh} mesh Mesh to be set.
     */
    setMesh(name, mesh) {
        this._meshes.set(name, mesh);        
    }

    /**
     * Returns the specified mesh.
     * @param {String} name Name of the mesh.
     * @return {Mesh} Mesh
     */
    getMesh(name) {
        const mesh = this._meshes.get(name);
        return mesh;
    }
}

export class MeshNodeData {
    /**
     * Creates a new MeshNodeData.
     */
    constructor() {
        /**
         * Node's position.
         * @type{THREE.Vector3}
         */
        this.position;

        /**
         * Node's rotation.
         * @type{THREE.Vector3}
         */
        this.rotation;

        /**
         * Node's scale.
         * @type{THREE.Vector3}
         */
        this.scale;

        /**
         * Name of this node.
         * @type{String}
         */
        this.name;

        /**
         * Name of the mesh to use.
         * @type{String}
         */
        this.mesh;

        // TODO: finish this.
        this.animations = [];

        /**
         * Name of material to use at render time.
         * 
         * To be used in same sequence as the draw calls in the mesh.
         * @type {Array<String>}
         */
        this.renderMaterials = [];
    }
}
