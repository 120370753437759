
import {Blending, LightingModel, Material} from "./../material.js";
import {SerialAssetInfo} from "./../../assets/serialassetinfo.js";
import * as SerialValue from "./../../serialization/serialvalueattribute.js";
import {ShaderCodeBase} from "./shadercodebase.js";
import {Uniform, UniformContainer} from "./../uniform.js";

class MaterialDefinition extends ShaderCodeBase {
    constructor() {
        super();
        
        this.lightingModel = LightingModel.DEFAULT;
        this.blendMode = Blending.OPAQUE;
        this.isTwoSided = false;
        this.useInlineMaterialProperties = false;
        this.fileExtension = ".hmd";
        
        this._uniforms = new UniformContainer();
    }
    
    /**
     * @returns {Array<Uniform>}
     */
    get uniforms() {
        return this._uniforms.uniforms;
    }
    
    /**
     * @returns {Array<Uniform>}
     */
    get renderUniforms() {
        return this._uniforms.renderUniforms;
    }
    
    get samplers() {
        console.warn("The sampler class has not been implemented, returning empty collection (Billy 11-24-2020).");
        return [];
    }
    
    get matrialShaderCode() {
        return this;
    }
    
    sync() {
        this._uniforms.sync();
    }
    
    validateDateFormat(data) {
        throw new Error("Not implemented.");
    }
    
    dispose() {
        //super.dispose()    // TODO: uncomment after subclassing ShaderBase
        
        this.samplers.forEach(sampler => sampler.releaseTexture());
    }
    
    getMaterialInfo(renderCall, materialMapInfo, assetInfo) {
        // we are our own material map.
        return assetInfo;
    }
    
    _onNewChild(child, after) {
        throw new Error("Not implemented from original.");
    }
    
    _onRemoveChild(child) {
        throw new Error("Not implemented from original.");
    }
}

MaterialDefinition.MaterialDefinitionAssetInfo = class MaterialDefinitionAssetInfo extends SerialAssetInfo {
    constructor() {
        super(MaterialDefinition);
        
        /**
         * @type {Guid}
         */
        this._assetId = null;
    }
    
    get assetId() {
        return this._assetId;
    }
    
    set assetId(value) {
        this._assetId = value;
    }
    
    get assetIds() {
        return [this._assetId];
    }
    
    get lastModifiedUtc() {
        return this._getAssetIdsLastModifiedUtc();
    }
    
    getSerialValuesAttributes() {
        const attributes = [
            new SerialValue.Attribute("assetId")
        ];
        
        return attributes;
    }
    
    load() {
        let materialDefinition = null;
        
        // const stream = AssetFactory.getAssetStream(this.assetId);
        // if (stream) {
        //     materialDefinition = ShaderCodeBase.load(stream);
        //     if (materialDefinition) {
        //         materialDefinition.assetInfo = this;
        //     }
        // }
        console.warn("skipping AssetFactory loading because it doesn't exist and returning a work around. TODO: replace with orignial if requried (Billy 11-24-2020).");
        
        materialDefinition = new MaterialDefinition();
        MaterialDefinition.assetInfo = this;
        
        return materialDefinition;
    }
    
    equals(other) {
        const result = other ? this._assetId == other.assetId : false;
        return result;
    }
};

MaterialDefinition.MaterialDefinitionResourceInfo = class MaterialDefinitionResourceInfo extends SerialAssetInfo {
    constructor() {
        super(MaterialDefinition);
        
        this.resourceName = "";
    }
    
    get description() {
        return this.resourceName;
    }
    
    get assetIds() {
        return [];
    }
    
    get lastModifiedUrc() {
        return SerialAssetInfo._getResourceLastModifiedUtc(this.resourceName);
    }
    
    getSerialValuesAttributes() {
        const attributes = [
            new SerialValue.Attribute("resourceName")
        ];
        
        return attributes;
    }
    
    load() {
        const materialDefinition = null;
        
        // const stream = AssetFactory.getResourceStream( this.resourceName );
        // if (stream) {
        //     materialDefinition = ShaderCodeBase.load(stream);
        //     if (materialDefinition) {
        //         MaterialDefinition.assetInfo = this;
        //     }
        // }
        
        console.warn("skipping AssetFactory loading because it doesn't exist and returning a work around. TODO: replace with orignial if requried (Billy 11-24-2020).");
        
        materialDefinition = new MaterialDefinition();
        MaterialDefinition.assetInfo = this;
        
        return materialDefinition;
    }
    
    equals(other) {
        const result = other ? this.resourceName == other.resourceName : false;
        return result;
    }
};

/**
 * @param {Guid} assetId
 */
MaterialDefinition.createAssetInfoForId = function(assetId) {
    const info = new MaterialDefinition.MaterialDefinitionAssetInfo();
    info.assetId = assetId;
    
    return info;
};

/**
 * @param {string} resourceName
 */
MaterialDefinition.createAssetInfoForResource = function(resourceName) {
    const info = new MaterialDefinition.MaterialDefinitionResourceInfo();
    info.resourceName = resourceName;
    
    return info;
};

MaterialDefinition.inlineDiffuseColorName = "Hal3d_Material.diffuse";

MaterialDefinition.inline = MaterialDefinition.createAssetInfoForResource("<Hal3d>/InlineMaterial.hmd");

export {MaterialDefinition};
